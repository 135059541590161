<div class='page'>
    <div class='content'>
        <div class='row'>
            <h1 class='col page__title'>Source of Funds</h1>
            <mat-divider class='mb-3'></mat-divider>
        </div>
    </div>

    <div class='d-flex align-items-center justify-content-between flex-row-reverse'>
        <button mat-flat-button color='primary' (click)='onAdd()' [disabled]='disabled'>
            <mat-icon>add</mat-icon>
            Add
        </button>
    </div>

    <form action=''>
        <div class='col-12 lbl-stacked' *ngFor='let doc of sourceOfFundsDocuments; let i = index'>
            <div class='row'>
                <div class='col-6 lbl-stacked'>
                    <mat-label>Document</mat-label>
                    <div class='file-input-wrapper d-flex align-items-center justify-content-between'>
                        <mat-form-field appearance='outline'>
                            <input matInput
                                   placeholder='Choose file'
                                   [value]='doc.filename'
                                   readonly>
                            <input type='file'
                                   class='uploadFile'
                                   [disabled]='disabled'
                                   multiple='multiple'
                                   (change)='fileChange($event.target, i)'
                                   accept='image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document' />
                            <mat-icon class='file-icon'>
                                {{doc.type ? 'swap_horiz' : 'upload'}}
                            </mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                <div class='col-6 lbl-stacked'>
                    <mat-label>Source of funds</mat-label>
                    <mat-form-field appearance='outline'>
                        <mat-select (selectionChange)='typeChange($event.value, i)' [value]='doc.type'
                                    [disabled]='disabled'>
                            <mat-option *ngFor='let option of sourceOfFunds'
                                        [value]='option.code'>{{option.description}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </form>

    <div class='spacer'></div>

    <div class='btn-bar'>
        <button mat-flat-button class='bcb-btn primary' (click)='onNextClick()'>Next</button>
        <button mat-stroked-button class='bcb-btn primary' (click)='onPreviousClick()'>Previous</button>
    </div>
</div>
