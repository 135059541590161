<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Entity Registered Address</h1>
        </div>
        <mat-divider class="mb-3"></mat-divider>
    </div>

    <form action="" [formGroup]="formGroup">
        <div class="row">
            <div class="col lbl-stacked">
                <mat-label>Address Line 1</mat-label>
                <mat-form-field  appearance="outline">
                    <input matInput formControlName="addressLine1">
                    <mat-error
                            *ngIf="formGroup.get('addressLine1')?.invalid">{{getErrorMessage('addressLine1')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col lbl-stacked">
                <mat-label>Address Line 2</mat-label>
                <mat-form-field  appearance="outline">
                    <input matInput formControlName="addressLine2">
                    <mat-error
                            *ngIf="formGroup.get('addressLine2')?.invalid">{{getErrorMessage('addressLine2')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col lbl-stacked">
                <mat-label>City</mat-label>
                <mat-form-field  appearance="outline">
                    <input matInput formControlName="city">
                    <mat-error
                            *ngIf="formGroup.get('city')?.invalid">{{getErrorMessage('city')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col lbl-stacked">
                <mat-label>Postal code</mat-label>
                <mat-form-field  appearance="outline">
                    <input matInput formControlName="postCode">
                    <mat-error
                            *ngIf="formGroup.get('postCode')?.invalid">{{getErrorMessage('postCode')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col lbl-stacked">
                <mat-label>Country</mat-label>
                <mat-form-field  appearance="outline">
                    <mat-select formControlName="country" (selectionChange)="onCountrySelect($event)">
                        <mat-option *ngFor="let country of allCountries"
                                    [value]="country.code">{{country.name}}</mat-option>
                    </mat-select>
                    <mat-error
                            *ngIf="formGroup.get('country')?.invalid">{{getErrorMessage('country')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row" *ngIf="formGroup.get('state')">
            <div class="col lbl-stacked">
                <mat-label>State</mat-label>
                <mat-form-field  appearance="outline">
                    <mat-select formControlName="state">
                        <mat-option *ngFor="let state of allUSStates" [value]="state.code">{{state.name}}</mat-option>
                    </mat-select>
                    <mat-error
                            *ngIf="formGroup.get('state')?.invalid">{{getErrorMessage('state')}}</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>

    <div class="spacer"></div>

    <div class="btn-bar">
        <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()" [disabled]="processing">{{processing ? 'Processing...' : 'Next'}}</button>
        <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()" [disabled]="processing">{{processing ? 'Processing...' : 'Previous'}}</button>
    </div>
</div>
