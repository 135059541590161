import { Component, Input } from '@angular/core';
import { OrgChartNode } from '../models/org-chart-node.interface';
import { ShareholdersQueries } from '../../../core/store/shareholders/shareholders.queries';
import { HttpService } from '../../../core/services/http/http.service';
import { EntityQueries } from '../../../core/store/entity/entity.queries';

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: '[orgChartNode]',
    templateUrl: './org-chart-node.component.html',
    styleUrls: ['./org-chart-node.component.scss'],
})
export class OrgChartNodeComponent {
    @Input() node: any;
    @Input() isRoot: boolean = false;

    constructor(private readonly shareholdersQueries: ShareholdersQueries,
                private readonly entityQueries: EntityQueries,
                private readonly http: HttpService) {
    }

    onNodeEdit(node: OrgChartNode): void {
        this.shareholdersQueries.setActiveShareholder(node.id);
    }

    onNodeDelete(node: OrgChartNode): void {
        this.http.removeCorporateSubEntity(node.id, node.parent_id ?? '').subscribe(() => this.entityQueries.loadSubEntities())
        this.shareholdersQueries.removeShareholder(node.id);
    }

    getNodeStatus(node: OrgChartNode): boolean {
        if(node.type === 'sub-entity-individual') {
            return node.complete
        } else {
            return node.status === 'completed'
        }
    }
}
