import { Component } from "@angular/core";
import { StepperQueries } from "../../../../core/store/stepper/stepper.queries";
import { EntityQueries } from "../../../../core/store/entity/entity.queries";
import { HttpService } from "../../../../core/services/http/http.service";
import { OnboardingQueries } from "../../../../core/store/onboarding/onboarding.queries";
import { ConfigQueries } from "../../../../core/store/config/config.queries";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { Observable } from "rxjs";

@Component({
	selector: 'bcb-legal-entity-details',
	templateUrl: './legal-entity-details.component.html',
	styleUrls: ['./legal-entity-details.component.scss']
})
export class LegalEntityDetailsComponent extends BasePageComponent {
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	initialStepAnswers(): Record<string, any> {
		return this.entityUtils.mapEntityToQuestions(this.entity);
	}

	onStepComplete(): Observable<any> {
		return this.http
			.updateCorporateInfo({
				...this.questionsConfig.getFormValuesMap(),
				entity_id: this.entity.id,
			})
	}
}
