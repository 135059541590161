import { Component } from '@angular/core';

@Component({
  selector: 'bcb-complete-later',
  templateUrl: './complete-later.component.html',
  styleUrls: ['./complete-later.component.scss']
})
export class CompleteLaterComponent {

  constructor() { }

  home(): void {
    window.location.href = 'https://bcbgroup.com'
  }
}
