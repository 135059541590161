import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomeComponent } from './welcome/welcome.component';
import { PersonalDetailsComponent } from './personal-details/personal-details.component';
import { LegalEntityDetailsComponent } from './legal-entity-details/legal-entity-details.component';
import { EntityRegisteredAddressComponent } from './entity-registered-address/entity-registered-address.component';
import { DirectorsInformationComponent } from './directors-information/directors-information.component';
import { ShareholdersComponent } from './shareholders/shareholders.component';
import { AccountDetailsComponent } from './account-details/account-details.component';
import { DueDiligenceComponent } from './due-diligence/due-diligence.component';
import { ScamClaimComponent } from './scam-claim/scam-claim.component';
import { UploadDocumentsComponent } from './upload-documents/upload-documents.component';
import { ReviewSubmitComponent } from './review-submit/review-submit.component';
import { RegulatoryInfoComponent } from './due-diligence/children/regulatory-info/regulatory-info.component';
import { AmlPoliciesProceduresComponent } from './due-diligence/children/aml-policies-procedures/aml-policies-procedures.component';
import { BoardSeniorManagementComponent } from './due-diligence/children/board-senior-management/board-senior-management.component';
import { AmlOfficerComponent } from './due-diligence/children/aml-officer/aml-officer.component';
import { ComplianceProceduresComponent } from './due-diligence/children/compliance-procedures/compliance-procedures.component';
import { KycProceduresComponent } from './due-diligence/children/kyc-procedures/kyc-procedures.component';
import { PepSanctionsCheckComponent } from './due-diligence/children/pep-sanctions-check/pep-sanctions-check.component';
import { DirectorInformationModalComponent } from './directors-information/director-information-modal/director-information-modal.component';
import { IndividualShareholderModalComponent } from './shareholders/individual-shareholder-modal/individual-shareholder-modal.component';
import { CorporateShareholderModalComponent } from './shareholders/corporate-shareholder-modal/corporate-shareholder-modal.component';
import { LegalEntityDetailsStepComponent } from './shareholders/corporate-shareholder-modal/steps/legal-entity-details-step/legal-entity-details-step.component';
import { DirectorInformationStepComponent } from './shareholders/corporate-shareholder-modal/steps/director-information-step/director-information-step.component';
import { ShareholderInformationStepComponent } from './shareholders/corporate-shareholder-modal/steps/shareholder-information-step/shareholder-information-step.component';
import { UploadDocumentsStepComponent } from './shareholders/corporate-shareholder-modal/steps/upload-documents-step/upload-documents-step.component';
import { ReviewSubmitStepComponent } from './shareholders/corporate-shareholder-modal/steps/review-submit-step/review-submit-step.component';
import { CryptoExchangeComponent } from './due-diligence/children/crypto-exchange/crypto-exchange.component';
import { LastlyComponent } from './due-diligence/children/lastly/lastly.component';
import { AmlLiteComponent } from './due-diligence/children/aml-lite/aml-lite.component';
import { AccountDetailsModalComponent } from './account-details/account-details-modal/account-details-modal.component';
import { SourceOfFundsComponent } from './source-of-funds/source-of-funds.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { OrgChartModule } from '../../org-chart/org-chart.module';
import { MatNativeDateModule } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { TransactionMonitoringCryptoComponent } from './due-diligence/children/transaction-monitoring-crypto/transaction-monitoring-crypto.component';
import { TransactionMonitoringFiatComponent } from './due-diligence/children/transaction-monitoring-fiat/transaction-monitoring-fiat.component';
import { BlockchainCryptoComponent } from './due-diligence/children/blockchain-crypto/blockchain-crypto.component';
import { ComponentsModule } from '../../../core/components/components.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { DirectorsInformationContentComponent } from './directors-information/directors-information-content/directors-information-content.component';
import { BusinessModelComponent } from './due-diligence/children/business-model/business-model.component';
import { YieldComponent } from './yield/yield.component';
import { TaxFormsComponent } from './tax-forms/tax-forms.component';
import { LegalEntityDetailsContentComponent } from './shareholders/corporate-shareholder-modal/steps/legal-entity-details-step/legal-entity-details-content/legal-entity-details-content.component';
import { TransactionMonitoringComponent } from './due-diligence/children/transaction-monitoring/transaction-monitoring.component';
import { Ng2TelInputModule } from '../../../core/directives/ng2-tel-input/ng2-tel-input.module';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { BaseDueDiligenceComponent } from "./due-diligence/base-due-diligence.component";

@NgModule({
	declarations: [
		WelcomeComponent,
		PersonalDetailsComponent,
		LegalEntityDetailsComponent,
		EntityRegisteredAddressComponent,
		DirectorsInformationComponent,
		ShareholdersComponent,
		AccountDetailsComponent,
		BaseDueDiligenceComponent,
		DueDiligenceComponent,
		ScamClaimComponent,
		UploadDocumentsComponent,
		ReviewSubmitComponent,
		RegulatoryInfoComponent,
		AmlPoliciesProceduresComponent,
		BoardSeniorManagementComponent,
		AmlOfficerComponent,
		ComplianceProceduresComponent,
		KycProceduresComponent,
		PepSanctionsCheckComponent,
		TransactionMonitoringCryptoComponent,
		TransactionMonitoringFiatComponent,
		BlockchainCryptoComponent,
		DirectorInformationModalComponent,
		IndividualShareholderModalComponent,
		CorporateShareholderModalComponent,
		LegalEntityDetailsStepComponent,
		DirectorInformationStepComponent,
		ShareholderInformationStepComponent,
		UploadDocumentsStepComponent,
		ReviewSubmitStepComponent,
		CryptoExchangeComponent,
		LastlyComponent,
		AmlLiteComponent,
		AccountDetailsModalComponent,
		SourceOfFundsComponent,
		DirectorsInformationContentComponent,
		BusinessModelComponent,
		YieldComponent,
		TaxFormsComponent,
		LegalEntityDetailsContentComponent,
		TransactionMonitoringComponent,
  ContactDetailsComponent,
	],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		RouterModule,
		OrgChartModule,
		Ng2TelInputModule,
		ComponentsModule,
		PipesModule,

		// Angular Material
		MatNativeDateModule,
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatInputModule,
		MatDividerModule,
		MatRadioModule,
		MatDatepickerModule,
		MatSelectModule,
		MatSnackBarModule,
		MatTableModule,
		MatDialogModule,
		MatCheckboxModule,
		MatBadgeModule,
		MatTabsModule,
		MatTooltipModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSliderModule,
		MatCardModule,
	],
})
export class CorporatePagesModule {}
