import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArrayUtils } from '../../../../../core/utils/array.util';
import { StaticData } from '../../../../../core/constants/static.const';
import { ConfigQueries } from '../../../../../core/store/config/config.queries';
import { HttpService } from "../../../../../core/services/http/http.service";
import { BasePageComponent } from "../../../../../core/components/component-base-page/base-page.component";
import { EntityQueries } from "../../../../../core/store/entity/entity.queries";
import { OnboardingQueries } from "../../../../../core/store/onboarding/onboarding.queries";
import { StepperQueries } from "../../../../../core/store/stepper/stepper.queries";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigQuestion } from "../../../../../core/models/config-question.interface";
import { Observable } from "rxjs";

@Component({
	selector: 'bcb-account-details-modal',
	templateUrl: './account-details-modal.component.html',
	styleUrls: ['./account-details-modal.component.scss'],
})
export class AccountDetailsModalComponent extends BasePageComponent implements OnDestroy {
	readonly allCountries = ArrayUtils.sortObject(StaticData.countries, 'name');
	currencyKey: string = '7d230933-bc89-40a9-b531-ec5cfeb38940';
	labelKey: string = '5618e55d-98c5-4a04-9b8f-2a30bebfee04';

	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: { [key: string]: any },
		private readonly dialogRef: MatDialogRef<AccountDetailsModalComponent>,
		configQueries: ConfigQueries,
		entityQueries: EntityQueries,
		http: HttpService,
		onboardingQueries: OnboardingQueries,
		stepperQueries: StepperQueries,
		_snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	initialStepAnswers(): Record<string, any> | undefined {
		return {
			[this.currencyKey]: this.data.details.currency,
			[this.labelKey]: this.data.details.label
		}
	}

	getStepAnswers = (value: Record<string, any>): Record<string, any> => {
		return value[this.currentStep.key]?.business_accounts?.[this.data.accountIndex]?.questions ?? {};
	}

	submit(): void {
		this.formGroup.markAllAsTouched();
		if (this.formGroup.valid || this.systemUser$.value) {
			this.dialogRef.close(this.mapData());
		}
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	onAnswerChange(question: ConfigQuestion, value: any): Observable<any> {
		const _answer = {[question.key]: value}
		return this.http.saveAnswers(
			this.entity.id,
			this.currentStep.key,
			_answer,
			`business_accounts.${this.data.accountIndex}.questions`
		)
	}

	private mapData(): {
		details: { [key: string]: any };
		questions: { [key: string]: any };
	} {
		const _values = this.questionsConfig.getFormValuesMap();
		const response = { details: { ...this.data.details }, questions: {} };

		response.details['label'] = _values[this.labelKey];
		response.details['status'] = 'complete';

		delete _values[this.labelKey];
		delete _values[this.currencyKey];

		response.questions = _values;

		return response;
	}
}
