import {Component} from '@angular/core';
import {StepperQueries} from "../../../../core/store/stepper/stepper.queries";

@Component({
    selector: 'bcb-welcome',
    templateUrl: './welcome.component.html',
    styleUrls: ['./welcome.component.scss']
})
export class IndividualWelcomeComponent {

    constructor(private readonly stepperQueries: StepperQueries) {
    }

    onNextClick(): void {
        this.stepperQueries.navigateStep('next');
    }

    onPreviousClick(): void {
        this.stepperQueries.navigateStep('prev');
    }

}
