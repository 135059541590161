<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Welcome to BCB Group Onboarding</h1>
            <mat-divider></mat-divider>
        </div>
        <p>Thank you for your interest in BCB Group and for choosing us to be your business banking partner. Here at
            BCB, we consider regulatory compliance to be an important prerequisite in any business
            relationship. We hope that our portal will enable you to provide us with all the information required to
            launch a successful business relationship with us.</p>
        <p>Upon receipt of your application, your files will undergo internal review by our onboarding team.</p>
        <p>Please feel free to reach out to our team with any questions during the process at:<br/><a
                href="mailto:onboarding@bcbgroup.io">onboarding@bcbgroup.io</a></p>
        <p><strong>You will need to provide the following:</strong></p>
        <ol class="bullet-primary">
            <li>Proof of identity*</li>
            <li>Proof of address</li>
            <li>Source of funds</li>
        </ol>

        <p>*For proof of identity we require certified copy passport scans. For proof of address we can accept a
            certified copy high resolution utility bill, local tax document or other government issued document
            confirming residential address, provided that the document is no more than 90 days old. Please note we
            cannot accept bank statements or mobile phone bills. </p>
    </div>

    <div class="spacer"></div>

    <div class="btn-bar">
        <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()">Next</button>
    </div>
</div>
