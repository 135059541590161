<h3>Shareholder information for {{shareholder?.entity_name}}</h3>
<h4 *ngIf="!addEditShareholder">Please list all parent companies owning 25% or more of the company.</h4>

<section>
    <table mat-table [dataSource]='linkedShareholders' *ngIf="!addEditShareholder">
        <ng-container matColumnDef='shares'>
            <th mat-header-cell *matHeaderCellDef> Shares</th>
            <td mat-cell *matCellDef='let element'>
                <div class='shares'>{{element.profile?.shares + '%'}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef='name'>
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef='let element'>
                <div class='d-flex align-items-center'>
                    <mat-icon class='me-2'>{{element.type?.includes('corporate') ? 'business' : 'person'}}</mat-icon>
                    {{element.entity_name || (element.first_name + ' ' + element.last_name)}}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef='actions'>
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef='let element' [style.text-align]='disabled ? "end" : "inherit"'>
                <ng-container *ngIf="element.type !== entityType.sub_individual">
                    <button mat-icon-button
                            *ngIf="disabled"
                            (click)='onEditShareholder(element)'>
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <ng-container *ngIf="!disabled">
                        <button mat-icon-button
                                (click)='onEditShareholder(element)'>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button
                                (click)='onRemoveShareholder(element.id)'>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
        <tr mat-row *matRowDef='let row; columns: displayedColumns;'></tr>
        <tr class='mat-row' *matNoDataRow>
            <td class='mat-cell' colspan='4'>No shareholders have been added</td>
        </tr>
    </table>
</section>

<section *ngIf="addEditShareholder" class='form-section mt-2'>
    <bcb-legal-entity-details-step [isNestedContent]="true"
                                   [disabled]="disabled"
                                   [parent]="shareholder"
                                   [shareholder]="editingShareholder | async"
                                   (entitySaved)="onSave()"
                                   (cancel)="reset()"></bcb-legal-entity-details-step>
</section>

<div class='d-flex align-items-center justify-content-end mt-2' *ngIf='!addEditShareholder'>
    <button *ngIf='!disabled' mat-flat-button color='primary' (click)="onAddNewClick()">Add
        Parent Company
    </button>
</div>
