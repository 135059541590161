<div class="page">
    <ng-container *ngIf="status || message then specific; else general"></ng-container>
</div>

<ng-template #general>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <img class="mb-4" src="../../../../../assets/svg/error.svg" width="200" alt="">
        <h1>Oops...</h1>
        <h3>{{message || 'Looks like something went wrong. We\'re working on it'}}</h3>
    </div>
</ng-template>


<ng-template #specific>
    <div class="d-flex flex-column align-items-center justify-content-center">
        <div class="large-badge mb-4">{{status || '!'}}</div>
        <h1>{{title}}</h1>
        <h3>{{message}}</h3>
    </div>
</ng-template>


<div class="navbar base-class"></div>
