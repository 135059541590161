import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigQuestion } from '../../models/config-question.interface';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ERROR_MESSAGES } from '../../constants/enum.const';
import { MatRadioChange } from "@angular/material/radio";

@Component({
	selector: 'bcb-generic-radio-group',
	templateUrl: './generic-radio-group.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: GenericRadioGroupComponent,
		},
	],
	styleUrls: ['./generic-radio-group.component.scss'],
})
export class GenericRadioGroupComponent
	implements OnInit, ControlValueAccessor
{
	@Input() question!: ConfigQuestion;
	@Input() formGroup?: FormGroup;
	@Input() formControl?: FormControl;
	@Input() formControlName?: string;
	@Input() errorMessage?: ERROR_MESSAGES;
	@Output() change: EventEmitter<any> = new EventEmitter<any>();

	value: any;
	isDisabled: boolean = false;

	constructor() {}

	ngOnInit(): void {
		if (this.formControl) {
			this.formGroup = this.formControl.parent as FormGroup;
		} else if (this.formGroup && this.formControlName) {
			this.formControl = this.formGroup.get(
				this.formControlName
			) as FormControl;
		} else {
			throw new Error(
				'One of the following is required: [formControl] or [formControlName] + [FormGroup]'
			);
		}
	}

	changed: (value: any) => void = () => {};

	touched: () => void = () => {};

	writeValue(obj: any): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.changed = fn;
	}

	registerOnTouched(fn: any): void {
		this.touched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	onChange(event: MatRadioChange): void {
		this.changed(event.value)
		this.change.emit(event.value)
	}
}
