import { OrgChartNode } from '../models/org-chart-node.interface';
import { Shareholder } from '../../../core/models/shareholder.interface';

export class OrgChartUtil {
	static shareholdersToOrgChartNode(data: Array<Shareholder>, disabled: boolean = false): OrgChartNode {
		const hashTable: any = Object.create({});
		let orgChartData: OrgChartNode = Object.create({});

		// Populate hash table
		data.forEach((item: Shareholder) => {
			hashTable[item.id] = { ...item, children: [], disabled };
		});

		// Add children to relevant parent
		data.forEach((item: Shareholder) => {
			if (item.parent_id) {
				hashTable[item.parent_id].children.push(hashTable[item.id]);
			} else {
				orgChartData = hashTable[item.id];
			}
		});

		return orgChartData;
	}
}
