import { StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	AddUpdateDirector,
	ClearState,
	LoadDirectors,
	RemoveDirector,
} from './directors.actions';
import { Entity } from '../../models/entity.model';
import { map } from 'rxjs/operators';
import { ArrayUtils } from '../../utils/array.util';
import { ENTITY_TYPE } from '../../constants/enum.const';

@Injectable()
export class DirectorsQueries {
	constructor(private readonly store: Store) {}

	loadState(directors: Array<Entity>): void {
		this.store.dispatch(
			new LoadDirectors(
				ArrayUtils.sortObject(
					directors.filter(
						(director: Entity) =>
							director.type === ENTITY_TYPE.sub_individual
					),
					'created_at',
					'asc'
				)
			)
		);
	}

	getState(): Observable<Array<Entity>> {
		return this.store.select(new StateToken<Array<Entity>>('directors'));
	}

	getDirector(id: string): Observable<Entity | undefined> {
		return this.getState().pipe(
			map((data: Array<Entity>) => {
				return data.find(
					(director: Entity) => director.parent_id === id
				);
			})
		);
	}

	getDirectorsByParent(parentId?: string): Observable<Array<Entity>> {
		return this.getState().pipe(
			map((data: Array<Entity>) => {
				return data.filter(
					(director: Entity) => director.parent_id === parentId
				);
			})
		);
	}

	removeDirector(id: string): void {
		this.store.dispatch(new RemoveDirector(id));
	}

	addUpdateDirector(director: Entity): void {
		this.store.dispatch(new AddUpdateDirector(director));
	}

	clearState(): void {
		this.store.dispatch(new ClearState());
	}
}
