<mat-nav-list class="stepper">
  <ng-container *ngFor="let step of config.steps; let index = index">
    <div class="step" (click)="onStepClick(step)"
         [class.active]="config.currentStep === step.key"
         *ngIf="!step.subSteps">
      <div class="details">
        <div class="count">{{index + 1}}</div>
        <div class="title">{{step.title}}</div>
      </div>
      <ng-container *ngIf="step.complete && !step.subSteps && !['ERROR', 'INDETERMINATE', 'IN_PROGRESS'].includes(step.metadata?.status)">
        <img *ngIf="!step.optional; else optionalCompleteIcon" src="assets/svg/success.svg" width="35" height="35"
           alt="complete step icon">
        <ng-template #optionalCompleteIcon>
          <img  src="assets/svg/updated.svg" matTooltip='Information in this step can be provided at a later date' width="35" height="35" alt="Optionally complete step icon">
        </ng-template>
      </ng-container>
      <img *ngIf="step.metadata?.status === 'INDETERMINATE'" matTooltip='We are unable to determine this status at this time' src="assets/svg/indeterminate.svg" width="35" height="35" alt="indeterminate step icon">
      <img *ngIf="step.metadata?.status === 'IN_PROGRESS'" matTooltip='Please submit this section once all required answers have been captured' src="assets/svg/in_progress.svg" width="35" height="35" alt="in progress step icon">
      <img *ngIf="step.metadata?.status === 'ERROR'" src="assets/svg/error.svg" width="35" height="35" alt="error step icon">
      <svg *ngIf="config.currentStep === step.key" xmlns="http://www.w3.org/2000/svg" height="37" viewBox="0 0 17 37"
           class="active-indicator">
        <path d="M18.5,0,37,17H0Z" transform="translate(17) rotate(90)"/>
      </svg>
    </div>
    <mat-expansion-panel class="section-stepper" [expanded]="config.parentStepKey === step.key" [hideToggle]="true" *ngIf="step.subSteps">
      <mat-expansion-panel-header>
        <mat-panel-description>
          <ng-container>
            <div class="step">
              <div class="details">
                <div class="count">{{index + 1}}</div>
                <div class="title">{{step.title}}</div>
              </div>
              <img *ngIf="step.metadata?.status === 'ERROR'" src="assets/svg/error.svg" width="35" height="35" alt="error step icon">
              <mat-icon *ngIf="step.subSteps"
                        class="sub-step-indicator">{{expandedSubSection === step.key ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
              <svg *ngIf="config.currentStep === step.key" xmlns="http://www.w3.org/2000/svg" height="37"
                   viewBox="0 0 17 37"
                   class="active-indicator">
                <path d="M18.5,0,37,17H0Z" transform="translate(17) rotate(90)"/>
              </svg>
            </div>
          </ng-container>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-nav-list *ngFor="let subStep of step.subSteps; let subIndex = index">
        <div class="step sub-step" (click)="onStepClick(subStep, step.key)"
             [class.active]="config.currentStep === subStep.key">
          <div class="details">
            <div class="count">{{(index + 1) + '.' + (subIndex + 1)}}</div>
            <div class="title">{{subStep.title}}</div>
          </div>
          <img *ngIf="subStep.complete && !['ERROR', 'INDETERMINATE', 'IN_PROGRESS'].includes(subStep.metadata?.status)" src="assets/svg/success.svg" width="25" height="25"
               alt="complete sub step icon">
          <img *ngIf="subStep.metadata?.status === 'ERROR'" matTooltip='This section has outstanding requirements' src="assets/svg/error.svg" width="25" height="25" alt="error sub step icon">
          <img *ngIf="subStep.metadata?.status === 'IN_PROGRESS'" matTooltip='Please submit this section once all required answers have been captured' src="assets/svg/in_progress.svg" width="35" height="35" alt="in progress step icon">
          <img *ngIf="subStep.metadata?.status === 'INDETERMINATE'" matTooltip='We are unable to determine this status' src="assets/svg/indeterminate.svg" width="25" height="25" alt="indeterminate sub step icon">
          <svg *ngIf="config.currentStep === subStep.key" xmlns="http://www.w3.org/2000/svg" height="37"
               viewBox="0 0 17 37"
               class="active-indicator">
            <path d="M18.5,0,37,17H0Z" transform="translate(17) rotate(90)"/>
          </svg>
        </div>
      </mat-nav-list>
    </mat-expansion-panel>
  </ng-container>
</mat-nav-list>
