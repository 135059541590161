import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {AddUpdateDirector, ClearState, LoadDirectors, RemoveDirector,} from './directors.actions';
import {Entity} from '../../models/entity.model';

const initialState: Array<Entity> = [];

@State<Array<Entity>>({
    name: 'directors',
    defaults: initialState,
})
@Injectable()
export class DirectorsState {
    constructor() {
    }

    // -------------------------- Actions --------------------------
    @Action(LoadDirectors)
    LoadState(
        {setState}: StateContext<Array<Entity>>,
        {payload}: LoadDirectors
    ): void {
        setState(payload);
    }

    @Action(RemoveDirector)
    RemoveDirector(
        {getState, setState}: StateContext<Array<Entity>>,
        {payload}: RemoveDirector
    ): void {
        const state = getState();
        const directors = state.filter(
            (director: Entity) => director.id !== payload
        );

        setState(directors);
    }

    @Action(AddUpdateDirector)
    AddUpdateDirector(
        {getState, setState}: StateContext<Array<Entity>>,
        {payload}: AddUpdateDirector
    ): void {
        const directors = [...getState()];

        const index: number = directors.findIndex(
            (director: Entity) => director.id === payload.id
        );

        if (index > -1) {
            directors[index] = payload;
        } else {
            directors.push(payload);
        }

        setState(directors);
    }

    @Action(ClearState)
    clearState({setState}: StateContext<Array<Entity>>): void {
        setState(initialState);
    }

    // -------------------------- Selectors --------------------------
    @Selector()
    getState(state: Array<Entity>): Array<Entity> {
        return state;
    }
}
