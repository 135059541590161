import { Component, OnDestroy } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { CONTACT_TYPES } from "../../../../core/constants/enum.const";
import { ConfigUtil } from "../../../../core/utils/config.util";
import { ConfigQuestion } from "../../../../core/models/config-question.interface";
import { Observable } from "rxjs";
import { StepperQueries } from "../../../../core/store/stepper/stepper.queries";
import { EntityQueries } from "../../../../core/store/entity/entity.queries";
import { HttpService } from "../../../../core/services/http/http.service";
import { ConfigQueries } from "../../../../core/store/config/config.queries";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { OnboardingQueries } from "../../../../core/store/onboarding/onboarding.queries";
import { OnboardingStateModel } from "../../../../core/store/onboarding/onboarding.state";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";

@Component({
	selector: 'bcb-contact-details',
	templateUrl: './contact-details.component.html',
	styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent extends BasePageComponent implements OnDestroy{
	componentLayoutClassList: string[] = ['col-md-6'];
	contactGroups: Array<ContactGroup> = [];
	constructor(
		configQueries: ConfigQueries,
		entityQueries: EntityQueries,
		http: HttpService,
		onboardingQueries: OnboardingQueries,
		stepperQueries: StepperQueries,
		_snackBar: MatSnackBar
	) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	ngOnDestroy(): void {
		this.questionsConfig.unsubscribe$.next(true)

		this.contactGroups.forEach((group) => {
			group.questionsConfig.unsubscribe$.next(true)
		})
	}

	handleOnboardingResponse(data: OnboardingStateModel) {
		super.handleOnboardingResponse(data);
		this.createContactGroups();
	}

	formatOnboardingAnswers(): { justification: string; [p: string]: any } {
		return {
			...this.generatePayload().raw,
			justification: this.justificationFormControl.value
		};
	}

	onAnswerChange(question: ConfigQuestion, value: any): Observable<any> {
		return this.http.saveAnswers(this.entity.id, this.currentStep.key, {[question.key]: value}, question.attributes?.noop_extras?.groupId)
	}

	onStepComplete(): Observable<any> {
		return this.http.createContactDetails(this.generatePayload())
	}

	validateAndSave() {
		this.contactGroups.forEach((group) => group.formGroup.markAllAsTouched())
		if (this.contactGroups.every((group) => group.formGroup.valid)) {
			this.processing$.next(true);
			this.onStepComplete()
				.pipe(
					switchMap(() => this.saveOnboardingAnswers()),
					tap(() => this.entityQueries.loadState()),
					tap(() => this.contactGroups.forEach((group) => group.formGroup.markAsPristine())),
					finalize(() => this.processing$.next(false)),
					catchError((err) => {
						this.processing$.next(false);
						return err;
					})
				)
				.subscribe(
					() => this.afterStepSaveComplete()
				);
		}
	}

	onNextClick() {
		if (!this.canSubmit()) {
			this.stepperQueries.navigateStep('next');
		} else {
			this.validateAndSave();
		}
	}

	canSubmit(): boolean {
		if(this.isDisabled) {
			return false;
		} else if(this.contactGroups.every((group) => group.formGroup?.pristine) && this.justificationFormControl.pristine) {
			return false
		} else if (this.contactGroups.every((group) => group.formGroup?.valid) && (!this.systemUser$.value && !!this.primaryContact)) {
			return true
		} else if (this.justificationFormControl.value && !this.systemUser$.value) {
			return true
		}

		return false;
	}

	onPreviousClick(): void {
		this.stepperQueries.navigateStep('prev');
	}

	private createContactGroups(): void {
		this.contactGroups = Object.values(CONTACT_TYPES).map(
			(type: CONTACT_TYPES) => {
				const _questionsConfig: ConfigUtil = new ConfigUtil(
					this.questions.map((question: ConfigQuestion) => {
						return {
							...question,
							attributes: {
								...(question.attributes ?? {}),
								noop_extras: {
									groupId: type
								}
							}
						};
					})
				);
				const _formGroup: FormGroup = _questionsConfig.formGroup;
				const _visibleControls: Set<string> =
					_questionsConfig.visibleControls;
				_questionsConfig.setDefaultValues(this.stepAnswers$.value?.[type] ?? {});

				this.monitorAnswerChanges(_questionsConfig);
				this.initFormGroupState(_formGroup);

				return {
					type,
					questionsConfig: _questionsConfig,
					formGroup: _formGroup,
					visibleControls: _visibleControls,
					questions: _questionsConfig.questions.value
				};
			}
		);
	}

	private generatePayload(): {
		contactDetails: Array<Record<string, any>>;
		raw: Record<string, any>;
		entityId: string;
	} {
		const payload: {
			contactDetails: Array<Record<string, any>>;
			raw: Record<string, any>;
			entityId: string;
		} = {
			contactDetails: [],
			raw: {},
			entityId: this.entity.id,
		};

		this.contactGroups
			.filter((group: { formGroup: FormGroup; [key: string]: any }) => group.formGroup.valid)
			.forEach((group: { formGroup: FormGroup; [key: string]: any }) => {
				payload.contactDetails.push(
					this.entityUtils.mapQuestionsToEntityContact({
						...group.formGroup.value,
						type: group.type,
						entityId: this.entity.id,
					})
				);

				payload.raw = {
					...payload.raw,
					[group.type]: group.formGroup.value
				};
			});

		return payload;
	}
}

interface ContactGroup {
	formGroup: FormGroup;
	questions: Array<ConfigQuestion>;
	questionsConfig: ConfigUtil;
	type: CONTACT_TYPES;
	visibleControls: Set<string>;
}
