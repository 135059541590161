import {StateToken, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    AddUpdateShareholder,
    ClearState,
    LoadShareholders,
    RemoveShareholder,
    SetActiveShareholder,
    UpdateShareholders,
} from './shareholders.actions';
import {ShareholdersStateModel} from './shareholders.state';
import {map} from 'rxjs/operators';
import {Shareholder} from '../../models/shareholder.interface';
import {ArrayUtils} from "../../utils/array.util";

@Injectable()
export class ShareholdersQueries {
    constructor(private readonly store: Store) {
    }

    loadState(shareholders: Array<Shareholder>): void {
        this.store.dispatch(
            new LoadShareholders(
                ArrayUtils.sortObject(shareholders, 'created_at', 'asc')
            )
        );
    }

    updateShareholder(shareholders: Array<Shareholder>): void {
        this.store.dispatch(
            new UpdateShareholders(
                ArrayUtils.sortObject(shareholders, 'created_at', 'asc')
            )
        );
    }

    getState(): Observable<ShareholdersStateModel> {
        return this.store.select(
            new StateToken<ShareholdersStateModel>('shareholders')
        );
    }

    getShareholdersByParent(parentId?: string): Observable<Array<Shareholder>> {
        return this.getState().pipe(
            map((data: ShareholdersStateModel) =>
                data.shareholders.filter(
                    (shareholder: Shareholder) =>
                        shareholder.parent_id === parentId
                )
            )
        );
    }

    removeShareholder(id: string): void {
        this.store.dispatch(new RemoveShareholder(id));
    }

    addUpdateShareholder(shareholder: Shareholder): void {
        this.store.dispatch(new AddUpdateShareholder(shareholder));
    }

    setActiveShareholder(id: string): void {
        this.store.dispatch(new SetActiveShareholder(id));
    }

    removeActiveShareholder(): void {
        this.store.dispatch(new SetActiveShareholder(undefined));
    }

    clearState(): void {
        this.store.dispatch(new ClearState());
    }
}
