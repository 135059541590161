<div class='page'>
    <div class='content'>
        <div class='row'>
            <h1 class='col page__title'>Legal Person Information</h1>
            <mat-divider class='mb-3'></mat-divider>
        </div>
    </div>

    <p class='text__table-header'>Please list all company legal persons here.</p>

    <section style='margin-top: 38px'>
        <h3>Legal Persons</h3>
        <table mat-table [dataSource]='dataSource | async' multiTemplateDataRows>

            <ng-container matColumnDef='shares'>
                <th mat-header-cell *matHeaderCellDef> Shares</th>
                <td mat-cell *matCellDef='let element'>
                    <div class='shares'>{{element.profile?.shareholder ? element.profile?.shares + '%' : 'N/A'}}</div>
                </td>
            </ng-container>

            <ng-container matColumnDef='name'>
                <th mat-header-cell *matHeaderCellDef> Name</th>
                <td mat-cell *matCellDef='let element'> {{element.first_name}} {{element.last_name}}</td>
            </ng-container>

            <ng-container matColumnDef='actions'>
                <th mat-header-cell *matHeaderCellDef> Actions</th>
                <td mat-cell *matCellDef='let element' [class.markedForDelete]="markedForDelete.has(element)">
                    <ng-container *ngIf="markedForDelete.has(element)">
                        <span>You are about to delete <b>{{element.first_name}} {{element.last_name}}</b>. Please confirm this is correct.</span>
                        <span>
                            <button *ngIf='!isDisabled' mat-icon-button title="Confirm" (click)='onRemoveDirector(element)'>
                                <mat-icon>done</mat-icon>
                            </button>
                            <button *ngIf='!isDisabled' mat-icon-button title="Cancel" (click)='handleDeleteClick(element, true)'>
                                <mat-icon>close</mat-icon>
                            </button>
                        </span>
                    </ng-container>
                    <ng-container *ngIf="!markedForDelete.has(element)">
                        <button *ngIf='!isDisabled' title="Edit" mat-icon-button (click)='onOpenDialog(element)'>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf='!isDisabled' title="Delete" mat-icon-button (click)='handleDeleteClick(element)'>
                            <mat-icon>delete</mat-icon>
                        </button>
                    </ng-container>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef='displayedColumns'></tr>
            <tr mat-row *matRowDef='let row; columns: displayedColumns'></tr>
            <tr class='mat-row' *matNoDataRow>
                <td class='mat-cell' colspan='4'>No legal persons have been added</td>
            </tr>
        </table>
    </section>
    <button *ngIf='!isDisabled' mat-flat-button color='primary' style='margin-top: 25px; width: 200px'
            (click)='onOpenDialog()'>Add
        Legal Person
    </button>

    <form *ngIf='showJustification'>
        <mat-divider style='margin: 25px 0'></mat-divider>
        <div class='col-12 lbl-stacked'>
            <mat-label>Justification</mat-label>
            <mat-form-field appearance='outline'>
                <textarea matInput [formControl]='justificationFormControl' rows='4'></textarea>
            </mat-form-field>
        </div>
    </form>

    <div class='spacer'></div>

    <div class='btn-bar' *ngIf='!systemUser$.value && primaryContact'>
        <button mat-flat-button class='bcb-btn primary' (click)='onNextClick()'
                [disabled]='processing'>{{processing ? 'Processing...' : 'Next'}}</button>
        <button mat-stroked-button class='bcb-btn primary' (click)='onPreviousClick()'
                [disabled]='processing'>{{processing ? 'Processing...' : 'Previous'}}</button>
    </div>
</div>

<ng-template #deleteRowConfirmation>
    <div>This is custom content</div>
</ng-template>