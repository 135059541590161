<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col-lg-6 page__title">Personal Details</h1>
        </div>
        <mat-divider class="mb-3"></mat-divider>

        <form action="" [formGroup]="formGroup">
            <div class="row">
                <div class="col-lg-6 lbl-stacked">
                    <mat-label>First name</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="firstName">
                        <mat-error
                                *ngIf="formGroup.get('firstName')?.invalid">{{getErrorMessage('firstName')}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 lbl-stacked">
                    <mat-label>Last name</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="lastName">
                        <mat-error
                                *ngIf="formGroup.get('lastName')?.invalid">{{getErrorMessage('lastName')}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 lbl-stacked">
                    <mat-label>Email</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput formControlName="email">
                        <mat-error
                                *ngIf="formGroup.get('email')?.invalid">{{getErrorMessage('email')}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 lbl-stacked">
                    <mat-label>Mobile</mat-label>
                    <mat-form-field appearance="outline">
                        <input matInput type="tel" ng2TelInput formControlName="mobile"
                               [ng2TelInputOptions]="{initialCountry: 'gb', customContainer: 'iti__no-flag', preferredCountries: ['gb', 'ch', 'us'], separateDialCode: true}"
                               (change)="onMobileNumberChange()"
                               (intlTelInputObject)="objMobileInput = $event">
                        <mat-error
                                *ngIf="formGroup.get('mobile')?.invalid">{{getErrorMessage('mobile')}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 lbl-stacked">
                    <mat-label>Date of birth</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                        <input matInput [matDatepicker]="picker" formControlName="dob">
                        <mat-error
                                *ngIf="formGroup.get('dob')?.invalid">{{getErrorMessage('dob')}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-lg-6 lbl-stacked">
                    <mat-label>Gender</mat-label>
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="gender">
                            <mat-option [value]="gender.code" *ngFor="let gender of genders">
                                {{gender.description}}
                            </mat-option>
                        </mat-select>
                        <mat-error
                                *ngIf="formGroup.get('gender')?.invalid">{{getErrorMessage('gender')}}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>

        <div class="spacer"></div>

        <div class="btn-bar">
            <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()" [disabled]="processing">{{processing ? 'Processing...' : 'Next'}}</button>
            <!--            <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()" [disabled]="processing">{{processing ? 'Processing...' : 'Previous'}}</button>-->
        </div>
    </div>
</div>
