import { Component, OnDestroy, OnInit } from '@angular/core';
import { ShareholdersQueries } from '../../../../../core/store/shareholders/shareholders.queries';
import { Shareholder } from '../../../../../core/models/shareholder.interface';
import { Subscription } from 'rxjs';
import { Entity } from '../../../../../core/models/entity.model';
import { DirectorsQueries } from '../../../../../core/store/directors/directors.queries';
import { MatDialogRef } from '@angular/material/dialog';
import {
	ENTITY_TYPE,
} from '../../../../../core/constants/enum.const';
import { StepModel } from '../../../../stepper/models/step.model';
import { first } from 'rxjs/operators';
import { StepperQueries } from '../../../../../core/store/stepper/stepper.queries';
import { EntityQueries } from '../../../../../core/store/entity/entity.queries';

@Component({
	selector: 'bcb-corporate-shareholder-modal',
	templateUrl: './corporate-shareholder-modal.component.html',
	styleUrls: ['./corporate-shareholder-modal.component.scss'],
})
export class CorporateShareholderModalComponent implements OnInit, OnDestroy {
	activeTabIndex: number = 0;
	totalTabs: number = 5;
	disableTabs: boolean = true;
	disableForms: boolean = false;

	shareholder!: Shareholder;
	parent!: Entity | Shareholder;

	linkedDirectors: Array<Entity> = [];
	linkedShareholders: Array<Shareholder> = [];

	subscriptions: Subscription = new Subscription();

	constructor(
		private readonly dialogRef: MatDialogRef<CorporateShareholderModalComponent>,
		private readonly directorsQueries: DirectorsQueries,
		private readonly shareholdersQueries: ShareholdersQueries,
		private readonly stepperQueries: StepperQueries,
		private readonly entityQueries: EntityQueries
	) {}

	ngOnInit(): void {
		this.subscriptions.add(
			this.shareholdersQueries.getState().subscribe((data) => {
				let _parent: Entity | Shareholder | undefined =
					data.shareholders.find(
						(item: Shareholder) => !item.parent_id
					);
				if (data.activeShareholder) {
					const _shareholder = data.shareholders.find(
						(shareholder: Shareholder) =>
							shareholder.id === data.activeShareholder
					);
					if (!_shareholder) {
						throw new Error(
							`Can not find shareholder with id: ${data.activeShareholder}`
						);
					}

					this.shareholder = _shareholder;
					_parent = data.shareholders.find(
						(item: Shareholder) =>
							item.id === _shareholder.parent_id
					);
				} else {
					this.shareholder = {
						id: '',
						parent_id: _parent?.id,
						type: ENTITY_TYPE.sub_corporate,
						complete: false,
					};
				}

				if (!_parent) {
					throw new Error(
						`Can not find parent with id: ${data.activeShareholder}`
					);
				}
				this.parent = _parent;

				this.disableTabs = !this.shareholder?.entity_name;
			})
		);
		this.subscriptions.add(
			this.shareholdersQueries
				.getShareholdersByParent(this.shareholder?.id)
				.subscribe(
					(shareholders: Array<Shareholder>) =>
						(this.linkedShareholders = shareholders)
				)
		);

		this.subscriptions.add(
			this.directorsQueries
				.getDirectorsByParent(this.shareholder?.id)
				.subscribe(
					(directors: Array<Entity>) =>
						(this.linkedDirectors = directors)
				)
		);

		this.stepperQueries
			.getStep()
			.pipe(first())
			.subscribe((currentStepData: StepModel | undefined) => {
				if (currentStepData?.metadata?.disable ?? false) {
					this.disableForms = true;
				}
			});
	}

	navigate(direction: 'next' | 'prev'): void {
		switch (direction) {
			case 'next':
				if (this.activeTabIndex < this.totalTabs - 1) {
					this.activeTabIndex++;
				}
				break;
			case 'prev':
				if (this.activeTabIndex > 0) {
					this.activeTabIndex--;
				}
				break;
		}
	}

	onUpdateShareholder(): void {
		this.activeTabIndex = 1;
	}

	onCreateShareholder(): void {
		this.dialogRef.close(undefined);
	}

	onActiveTabChange(index: number): void {
		this.activeTabIndex = index;
		this.entityQueries.reloadState();
	}

	submit(): void {
		this.dialogRef.close(this.shareholder);
	}

	cancel(): void {
		this.dialogRef.close(undefined);
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
}
