import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from '@angular/core';
import { Shareholder } from '../../../../../../../core/models/shareholder.interface';
import { FormGroup } from '@angular/forms';
import { ConfigQuestion } from '../../../../../../../core/models/config-question.interface';
import { ConfigUtil } from '../../../../../../../core/utils/config.util';
import { ConfigQueries } from '../../../../../../../core/store/config/config.queries';
import { map, takeWhile } from 'rxjs/operators';

@Component({
	selector: 'bcb-upload-documents-step',
	templateUrl: './upload-documents-step.component.html',
	styleUrls: ['./upload-documents-step.component.scss'],
})
export class UploadDocumentsStepComponent implements OnInit, OnChanges {
	@Input() shareholder!: Shareholder;
	@Input() disabled: boolean = false;
	@Input() questions: Array<ConfigQuestion> = [];

	questionsConfig!: ConfigUtil;
	formGroup!: FormGroup;
	visibleControls!: Set<string>;
	remainingShares: number = 100;
	componentClassList: Array<string> = ['col-md-6'];

	currentStep: string = 'shareholders_documents';

	constructor(private readonly configQueries: ConfigQueries) {
		this.questionsConfig = new ConfigUtil(this.questions);
		this.formGroup = this.questionsConfig.formGroup;
		this.visibleControls = this.questionsConfig.visibleControls;
	}

	ngOnInit(): void {
		this.configQueries
			.getState()
			.pipe(
				takeWhile(() => !this.questionsConfig.unsubscribe.value),
				map((config) => config[this.currentStep])
			)
			.subscribe((questions: Array<ConfigQuestion>) => {
				if (!questions) return;this.questionsConfig = new ConfigUtil(questions);
				this.questions = this.questionsConfig.questions.getValue();
				this.formGroup = this.questionsConfig.formGroup;
				this.visibleControls = this.questionsConfig.visibleControls;
			});

		if (this.shareholder?.profile?.onboarding?.documents) {
			const documentsMap: Map<string, Array<any>> = new Map<
				string,
				Array<any>
			>(Object.entries(this.shareholder?.profile?.onboarding?.documents));
			const documents: Record<string, Array<any>> = {};
			documentsMap.forEach((value: Array<any>, key: string) => {
				documents[key.substring(this.currentStep.length + 1)] =
					value;
			});
			this.questionsConfig.setDefaultValues(documents);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['disabled']?.currentValue === true) {
			this.formGroup?.disable();
		}
	}
}
