import { Component, OnDestroy, OnInit } from '@angular/core';
import { EntityQueries } from '../../../../../core/store/entity/entity.queries';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactType, EntityContact } from '../../../../../core/models/entity-contact.model';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AdditionalContactModalComponent } from './additional-contact-modal/additional-contact-modal.component';
import { HttpService } from '../../../../../core/services/http/http.service';

@Component({
  selector: 'bcb-additional-contacts',
  templateUrl: './additional-contacts.component.html',
  styleUrls: ['./additional-contacts.component.scss']
})
export class AdditionalContactsComponent implements OnInit, OnDestroy {
  entityContacts: Observable<Array<EntityContact>>;
  contactType = ContactType;
  displayedColumns = ['name', 'email', 'mobile', 'actions'];
  markedForDelete: Set<string> = new Set<string>();
  deleting: Record<string, boolean> = {};
  activeContact: Subject<EntityContact> = new Subject<EntityContact>();
  isDisabled = false;
  unsubscribe: Subject<void> = new Subject<void>();
  protected readonly open = open;

  constructor(
    private entityQueries: EntityQueries,
    _snackBar: MatSnackBar,
    private readonly dialog: MatDialog,
    private readonly httpService: HttpService
  ) {
    this.entityContacts = entityQueries
      .getState()
      .pipe(
        map((state) => state.entityContacts ?? []),
        map((contacts) => contacts.filter((contact) => contact.type !== ContactType.PRIMARY))
      );
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  ngOnInit(): void {
    this.activeContact
      .pipe(
        takeUntil(this.unsubscribe),
        tap((data) => {
          if (data) this.openModal(data);
        })
      )
      .subscribe();
  }

  removeContact(contact: EntityContact): void {
    if (!contact.id) {
      return;
    }

    if (this.deleting[contact.id]) {
      return;
    }
    this.markedForDelete.delete(contact.id);
    this.deleting[contact.id] = true;
    this.httpService.deleteAdditionalContact(contact.id)
      .pipe(
        tap(() => this.entityQueries.reloadState())
      )
      .subscribe();
  }

  openModal(data?: EntityContact): void {
    const dialogRef = this.dialog.open(AdditionalContactModalComponent, {
      width: '50%',
      disableClose: true,
      role: 'dialog',
      data
    });

    dialogRef.afterClosed().subscribe((response?: EntityContact) => {
      this.activeContact.next();

      if (!response) {
        return;
      }

      this.httpService.saveAdditionalContact(response)
        .pipe(
          tap(() => {
          }),
          tap(() => this.entityQueries.reloadState())
        )
        .subscribe();
    });
  }
}
