<ng-container *ngIf="!isNestedContent">
<h3>Legal Entity Details</h3>
</ng-container>
<bcb-legal-entity-details-content *ngIf="data; else loadingTemplate" [data]="data" (submit)="onSubmit($event)" (formValid)="canSubmit = $event"></bcb-legal-entity-details-content>
<div class='d-flex align-items-center justify-content-end' *ngIf='!disabled'>
    <button *ngIf="isNestedContent"
            mat-stroked-button class='bcb-btn btn-small danger me-2'
            (click)='onCancelClick()'> cancel
    </button>
    <button mat-flat-button
            color='primary'
            [disabled]='!$systemUser.value && (!canSubmit || loading)'
            (click)='triggerSubmit()'> {{ loading ? 'Loading...' : 'Save'}}
    </button>
</div>

<ng-template #loadingTemplate>
    <p>Loading...</p>
</ng-template>