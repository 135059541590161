import { ErrorHandler, NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToolbarModule } from './modules/toolbar/toolbar.module';
import { PagesModule } from './modules/pages/pages.module';
import { HttpService } from './core/services/http/http.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import * as Sentry from '@sentry/angular-ivy'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,

    ToolbarModule,
		PagesModule,
  ],
  providers: [
    HttpService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
			multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
			multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
      	logErrors: true
      }),
		},
  ],
	bootstrap: [AppComponent],
})
export class AppModule {}
