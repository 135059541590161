import { Component } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { Observable } from "rxjs";

@Component({
	selector: 'bcb-upload-documents',
	templateUrl: './upload-documents.component.html',
	styleUrls: ['./upload-documents.component.scss'],
})
export class UploadDocumentsComponent extends BasePageComponent {
	componentLayoutClassList: string[] = ['col-12'];
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onStepComplete(): Observable<any> {
		return this.http
			.completeApplication({
				entity_id: this.entity?.id,
				uploaded: this.questionsConfig.getFormValuesMap(),
			})
	}

	onPreviousClick(): void {
			this.stepperQueries.navigateStep('prev');
	}

}