<h3>Review and Submit</h3>
<mat-divider class="mb-2"></mat-divider>
<section>
    <div class="title mb-1">1. Legal Entity Details</div>
    <div class="info d-flex align-items-center ms-3">
        <div class="shares me-1">{{shareholder?.profile?.shares + '%'}}</div>
        <mat-icon class="me-1">{{shareholder?.type?.includes('corporate') ? 'business' : 'person'}}</mat-icon>
        {{shareholder?.type?.includes('corporate') ? shareholder?.entity_name : shareholder?.first_name + ' ' + shareholder?.last_name}}
    </div>
    <mat-divider class="my-2"></mat-divider>
</section>
<section>
    <div class="title mb-1">2. Legal Persons Information</div>
    <div class="d-flex align-items-center justify-content-between">
        <div class="info d-flex align-items-center ms-3" *ngFor="let director of linkedDirectors">
            <mat-icon class="me-1">person</mat-icon>
            {{director?.first_name + ' ' + director?.last_name}}
        </div>
        <div class="info ms-3" *ngIf="!linkedDirectors?.length">
            No directors were added.
        </div>
        <img *ngIf="linkedDirectors?.length" src="../../../../../../../../assets/svg/success.svg" width="25" height="25"
             alt="Directors status">
    </div>
    <mat-divider class="my-2"></mat-divider>
</section>
<section>
    <div class="title mb-1">3. Shareholders Information</div>
    <div class="d-flex align-items-center justify-content-between">
        <div class="info d-flex align-items-center ms-3" *ngFor="let linkedShareholder of linkedShareholders">
            <div class="shares me-1">{{linkedShareholder?.profile?.shares + '%'}}</div>
            <mat-icon class="me-1">{{linkedShareholder?.type?.includes('corporate') ? 'business' : 'person'}}</mat-icon>
            {{linkedShareholder?.type?.includes('corporate') ? linkedShareholder?.entity_name : linkedShareholder?.first_name + ' ' + linkedShareholder?.last_name}}
        </div>
        <div class="info" *ngIf="!linkedShareholders?.length">
            No shareholders were added
        </div>
        <img src="../../../../../../../../assets/svg/success.svg" width="25" height="25" alt="Shareholders status">
    </div>
    <mat-divider class="my-2"></mat-divider>
</section>
<section>
    <div class="title mb-1">4. Uploaded Documents</div>
    <div class="d-flex flex-column justify-content-center">
        <div class="info ms-3" *ngFor="let question of uploadDocumentQuestions">
            <span class="d-flex align-items-center justify-content-between">
                <span>{{question.label}}</span>
                <ng-container *ngIf="documentsMap.get(companyDocumentsKey + '_' + question.key) then successIcon; else documentNotProvided"></ng-container>
                <ng-template #documentNotProvided>
                    <ng-container *ngIf="question.validators.required then errorIcon; else optionalSuccessIcon"></ng-container>
                </ng-template>
            </span>
            <ul *ngIf="documentsMap.get(companyDocumentsKey + '_' + question.key) as documents">
                <ng-container *ngFor="let document of documents">
                    <li>{{document.filename}}</li>
                </ng-container>
            </ul>
            <mat-divider class="my-1"></mat-divider>
        </div>
    </div>
</section>

<ng-template #successIcon>
    <img src="../../../../../../../../assets/svg/success.svg" width="25" height="25" title="Document provided" alt="Document provided icon">
</ng-template>
<ng-template #errorIcon>
    <img src="../../../../../../../../assets/svg/error.svg" width="25" height="25" title="Required document not provided" alt="Required document not provided icon">
</ng-template>
<ng-template #optionalSuccessIcon>
    <img src="../../../../../../../../assets/svg/indeterminate.svg" width="25" height="25" title="Optional document" alt="Optional document icon">
</ng-template>
