import { StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from "rxjs";
import { StepperConfigModel } from '../../../modules/stepper/models/stepper-config.model';
import { GenerateConfig, Navigate, NavigateToStep, UpdateConfig, UpdateStepMetadata } from './stepper.actions';
import { OnboardingStateModel } from '../onboarding/onboarding.state';
import { StepModel, StepModelMetadata } from '../../../modules/stepper/models/step.model';
import { map, switchMap } from 'rxjs/operators';
import { EntityQueries } from '../entity/entity.queries';

@Injectable()
export class StepperQueries {
	previousState: StepperConfigModel;

	constructor(private readonly store: Store, private readonly entityQueries: EntityQueries) {
		this.previousState = store.snapshot();
	}

	generateConfig(onboarding: OnboardingStateModel, withNavigation = true): void {
		this.store.dispatch(new GenerateConfig(onboarding, withNavigation));
	}

	getState(): Observable<StepperConfigModel> {
		return this.store.select(new StateToken<StepperConfigModel>('stepper'));
	}

	getStep(): Observable<StepModel> {
		return this.getState()
			.pipe(
				map((data) => {
					const stepHashMap = this._toHashMap(data.steps);

					if (data.parentStepKey ) {
					const _parentStep = stepHashMap[data.parentStepKey];
						if(_parentStep.subSteps) {
							const subStep = this._toMap(_parentStep.subSteps).get(data.currentStep);
							if(subStep) {
								return { ...subStep, parentStep: _parentStep}
							}
						}
					}

					return stepHashMap[data.currentStep];
				})
			);
	}

	setState(config: StepperConfigModel): Observable<StepperConfigModel> {
		this.previousState = this.store.snapshot();
		return this.store.dispatch(new UpdateConfig(config));
	}

	rollback(): void {
		this.store.reset(this.previousState);
	}

	// --- navigation ---
	navigateToStep(stepKey: string, parentStepKey?: string): void {
		this.previousState = this.store.snapshot();
		this.entityQueries.reloadState();
		this.store.dispatch(new NavigateToStep({ stepKey, parentStepKey }));
	}

	navigateStep(direction: 'next' | 'prev'): void {
		this.previousState = this.store.snapshot();
		this.entityQueries.reloadState()
		this.store.dispatch(new Navigate({ direction }));
	}

	// updates
	updateStepMetadata(
		metadata: StepModelMetadata,
		stepKey: string,
		parentStepKey?: string
	): void {
		this.previousState = this.store.snapshot();
		this.store.dispatch(
			new UpdateStepMetadata({ metadata, stepKey, parentStepKey })
		);
	}

	private readonly _toHashMap = (arr: Array<StepModel>) => arr.reduce((acc: Record<string, StepModel>, step) => (acc[step.key] = step, acc), {});
	private readonly _toMap = (arr: Array<StepModel>) => new Map(arr.map(step => [step.key, step]))
}
