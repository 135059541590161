<mat-progress-bar
        *ngIf="uploading"
        color="primary"
        mode="indeterminate">
</mat-progress-bar>
<bcb-generic-label [question]="question"></bcb-generic-label>
<div class="row" *ngIf="!formControl?.value?.skipped; else skipReason">
    <mat-form-field
            appearance="outline"
            class="col"
            [id]="identifier"
            (click)="fileUpload.click()">
        <div class="file-input-wrapper d-flex align-items-center justify-content-between">
            <input matInput [formControl]="formControl" placeholder="Choose file"
                   readonly/>
            <input #fileUpload type="file" class="uploadFile"
                   [multiple]="question.attributes?.multiple || false"
                   [accept]="question.attributes?.accept?.join(', ') ?? defaultAcceptedFileTypes"
                   (change)="fileChange($event.target?.files)"/>
            <mat-icon class="file-icon"> upload </mat-icon>
        </div>
        <mat-error
                *ngIf="formControl?.invalid">{{errorMessage}}</mat-error>
        <mat-hint
          *ngIf="_displayFiles?.length > 1"
          align="end"
                  style="cursor: pointer"
                  (click)="toggleFileView($event)">
            <button mat-stroked-button class="bcb-btn btn-tiny">{{filesViewExpanded ? 'Hide files' : 'Show files'}}</button>
        </mat-hint>
    </mat-form-field>
    <div *ngIf="question.attributes?.canSkip" class="col-2 mat-form-field-wrapper d-flex align-self-center">
        <button class="bcb-btn btn-thin primary" mat-stroked-button (click)="onSkip()">Skip</button>
    </div>
</div>
<div *ngIf="filesViewExpanded" class="uploaded-files">
    <table mat-table [dataSource]='_displayFiles' multiTemplateDataRows>
        <ng-container matColumnDef='filename'>
            <td mat-cell *matCellDef='let file'> {{file?.filename}}</td>
        </ng-container>

        <ng-container matColumnDef='actions'>
            <td mat-cell *matCellDef='let file' [class.markedForDelete]="markedForDelete.has(file.document)">
                <ng-container *ngIf="markedForDelete.has(file.document)">
                    <span>You are about to delete <b>{{file?.filename}}</b>. Please confirm this is correct.</span>
                    <span>
                            <button *ngIf='!isDisabled' mat-icon-button title="Confirm" (click)='removeFile(file)'>
                                <mat-icon>done</mat-icon>
                            </button>
                            <button *ngIf='!isDisabled' mat-icon-button title="Cancel" (click)='markedForDelete.delete(file.document)'>
                                <mat-icon>close</mat-icon>
                            </button>
                        </span>
                </ng-container>
                <ng-container *ngIf="!markedForDelete.has(file.document)">
                    <ng-container *ngIf="!isDisabled">
                    <button *ngIf="!deleting[file.document]; else deletingSpinner" title="Delete" mat-icon-button (click)='markedForDelete.add(file.document)'>
                        <mat-icon>delete</mat-icon>
                    </button>
                    <ng-template #deletingSpinner>
                        <mat-spinner diameter="24"></mat-spinner>
                    </ng-template>
                    </ng-container>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-row *matRowDef='let row; columns: ["filename", "actions"]'></tr>
    </table>
</div>
<ng-template #skipReason>
    <mat-form-field appearance='outline'>
            <textarea matInput
                      [value]="formControl?.value?.reason"
                      [rows]='question.attributes?.rows || 4'
                      (change)="onReasonChange($event)"
                      (blur)="touched()"></textarea>
        <mat-error *ngIf="formControl?.invalid">{{errorMessage}}</mat-error>
    </mat-form-field>
</ng-template>
