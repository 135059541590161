<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Onboarding was successfully saved!</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
        <div class="row">
            <p class="col">To continue later an email has been sent with a link to login.</p>
            <div class="col">
                <button mat-flat-button class="bcb-btn primary" (click)="home()">Home page</button>
            </div>
        </div>
    </div>
</div>
