<bcb-generic-label [question]="question"></bcb-generic-label>
<mat-form-field appearance='outline' (click)='picker.open()'>
    <mat-datepicker-toggle matSuffix [for]='picker'></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <input matInput
           readonly
           [formControl]="formControl"
           [matDatepicker]='picker'
           [min]="question.attributes?.min"
           [max]="question.attributes?.max"
           (dateChange)="onChange($event)"
           (blur)="touched()"
    >
    <mat-error
            *ngIf="formControl?.invalid">{{errorMessage}}</mat-error>
</mat-form-field>