<div class="page">
    <div class="content">
        <div class="row mb-4">
            <h1 class="col-lg-6 page__title">Contact Details</h1>
            <i>Authorised person’s details are required to ensure the correct person is contacted with any questions</i>
        </div>
        <mat-divider class='mb-3'></mat-divider>
    </div>

    <div class="row" *ngIf="!formGroup; else form">
        <div class="d-flex flex-column align-items-center">
            <mat-spinner>{{runInit() | async}}</mat-spinner>
        </div>
    </div>


    <div class='spacer'></div>

    <div class='btn-bar' *ngIf='!systemUser$.value && primaryContact'>
        <button mat-flat-button class='bcb-btn primary' [class.btn-full-width]="canSubmit()" (click)='onNextClick()'
                [disabled]='processing$.value'>{{nextButtonText()}}</button>
        <!--            <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()" [disabled]="processing$.value">{{previousButtonText()}}</button>-->
    </div>
</div>

<ng-template #form>
    <form [formGroup]='formGroup'>
        <div class="row">
            <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                                   [currentStep]="currentStep.key"
                                   [entity]="entity"
                                   [formGroup]="formGroup"
                                   [questions]="questions"
                                   [questionsConfig]="questionsConfig"
                                   [visibleControls]="visibleControls"
            ></bcb-component-wrapper>

            <ng-container *ngIf='showJustification'>
                <mat-divider style='margin: 25px 0'></mat-divider>
                <div class='col-12 lbl-stacked'>
                    <mat-label>Justification</mat-label>
                    <mat-form-field appearance='outline'>
                            <textarea matInput [formControl]='justificationFormControl'
                                      (input)='formGroup.markAsDirty()' rows='4'></textarea>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
    </form>
</ng-template>