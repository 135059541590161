import { Shareholder } from '../models/shareholder.interface';
import { Contacts, Entity } from '../models/entity.model';
import { ShareholdersQueries } from '../store/shareholders/shareholders.queries';
import { first, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ENTITY_TYPE } from '../constants/enum.const';
import { Country } from '../models/country.interface';
import { ArrayUtils } from './array.util';
import { StaticData } from '../constants/static.const';

@Injectable()
export class EntityUtils {
	private allCountries: Array<Country> = ArrayUtils.sortObject(
		StaticData.countries,
		'name'
	);

	constructor(private readonly shareholdersQueries?: ShareholdersQueries) {}

	private _questionKeys: Record<string, string> = {
		// sub-individual
		first_name: 'fc603e30-de3b-4e5d-9f58-78b45c82d2ca',
		last_name: '1a10b4bd-7ac9-4d4e-8119-be299f8e77c4',
		email: '8d593f91-780e-401c-a055-bfc0a6e9e22e',
		mobile: 'a7018dcc-0cc4-4f8e-8bbd-46f13d4e8ce7',
		dob: '9ae32e57-321c-4e1d-9bdf-19f2d27d117e',
		dobConfirmation: '0ae45474-9b43-4f52-acb8-0b52f38b9cd9',
		nationality: '0bc38ce4-49a7-45b9-a5ac-57aba9465954',
		job_title: 'a99e4c6c-89fe-4dea-a5dc-d814601d517f',
		legal_person_type: '578fe3dd-f433-4622-a324-68b4e0182261',

		// sub-corporate
		entity_name: 'fa6c5d84-276f-4a5f-b9a6-a2e44d26b48b',
		company_registration_number: '21fe315f-2e3d-403f-8a2b-76065aeef4b5',
		date_of_incorporation: '1126f63c-f1a7-477a-a2b8-58582ae37494',
		date_of_incorporation_reason: 'ea885c5f-5d26-43cc-be27-7d95d1d9a54b',
		country_of_incorporation: '193b4d5f-7ad8-4c68-8db1-37094ca85e29',
		company_is_holding: 'aed5ab6c-2a83-44bd-8a51-23ba53215ea2',
		company_is_fund: '37c3805a-ee5e-46e1-a10d-095b238a4cdc',
		company_is_trust: '128e3240-2bfe-40ac-8461-017b50b8eae1',

		// address
		street: '84b91e75-ffc5-48b7-a30a-c815ab7a1add',
		sub_street: 'b72642d8-2e9d-4a99-b035-66502da09e03',
		city: '8b1e2efc-420f-41cb-a4a4-bcf5117f71e8',
		state: '1c833f6a-a076-4de0-9093-d36367f3014c',
		postcode: '52f8618b-f20d-46b0-9365-a9800b189d8a',
		country: 'b00749cb-c817-4065-a5c1-351d1f5de45a',

		// corporate only
		authorisation: 'cea098bd-6131-466c-8db2-707e686ae770',
		position: 'dacf60b6-c9a7-4e34-baa5-7e6f361f15ec',
		invoice_email: 'c0d5439c-fe4d-4e6a-88bb-4dacf427d270',

		// both
		shares: '04cf954f-aeb7-419f-99a0-71bc511a7d24',
		tax_number: 'cbd23cc7-17cd-408a-b2d7-cd8fbcd1e1ef',

		// entityContacts
		fullName: '997de497-a6c4-4bf4-a4fe-ce1e396084d7',
	};

	get questionKeys(): Record<string, string> {
		return this._questionKeys;
	}

	async getRemainingSharesForParent(
		shareholder: Shareholder | Entity,
		field: 'id' | 'parent_id'
	): Promise<number> {
		if (!this.shareholdersQueries) {
			throw new Error(
				'Provide a [ShareholdersQueries] to the constructor'
			);
		}
		return await this.shareholdersQueries
			.getShareholdersByParent(shareholder[field] ?? undefined)
			.pipe(
				first(),
				map((data: Array<Shareholder>) => {
					return data.reduce((acc: number, item: Shareholder) => {
						return acc + Number(item.profile?.shares ?? 0);
					}, 0);
				}),
				map((data: number) => {
					switch (field) {
						case 'id':
							return 100 - data;
						case 'parent_id':
							return (
								100 -
								(data -
									Number(shareholder.profile?.shares ?? 0))
							);
					}
				})
			)
			.toPromise();
	}

	getRemainingSharesForShareholders(
		entity: Shareholder | Entity,
		shareholders: Array<Shareholder | Entity>,
		field: 'id' | 'parent_id',
		excessShares: number = 0
	): number {
		const totalShares: number = shareholders.reduce(
			(acc: number, item: Shareholder | Entity) => {
				return acc + Number(item.profile?.shares ?? 0);
			},
			-excessShares
		);

		switch (field) {
			case 'id':
				return 100 - totalShares;
			case 'parent_id':
				return (
					100 - (totalShares - Number(entity.profile?.shares ?? 0))
				);
		}
	}

	mapEntityToQuestions(entity: Entity): Record<string, any> {
		return {
			entityId: entity.id,
			[this._questionKeys.entity_name]: entity.entity_name,
			[this._questionKeys.first_name]: entity.first_name,
			[this._questionKeys.last_name]: entity.last_name,
			[this._questionKeys.email]: entity.email,
			[this._questionKeys.mobile]: entity.mobile,
			[this._questionKeys.dob]: entity.profile?.dob,
			[this._questionKeys.dobConfirmation]: entity.profile?.dob,
			[this._questionKeys.nationality]: entity.profile?.nationality,
			[this._questionKeys.job_title]: entity.profile?.job_title,
			[this._questionKeys.tax_number]: entity.profile?.tax_number,
			[this._questionKeys.legal_person_type]:
				entity.profile?.legal_person_type,
			[this._questionKeys.shares]: entity.profile?.shares,
			[this._questionKeys.street]: entity.address?.street,
			[this._questionKeys.sub_street]: entity.address?.sub_street,
			[this._questionKeys.city]: entity.address?.city,
			[this._questionKeys.state]: entity.address?.state,
			[this._questionKeys.postcode]: entity.address?.postcode,
			[this._questionKeys.country]: this.allCountries.find(
				(country: Country) => country.code === entity.address?.country
			),
		};
	}

	mapQuestionsToEntity(
		values: Record<string, any>,
		entityType: ENTITY_TYPE = ENTITY_TYPE.sub_individual
	): Entity {
		const entity = new Entity();
		entity.profile = {};
		entity.address = {};

		entity.first_name =
			values[this._questionKeys.first_name] ?? '--no-value--';
		entity.last_name =
			values[this._questionKeys.last_name] ?? '--no-value--';
		entity.email = values[this._questionKeys.email] ?? '--no-value--';
		entity.mobile = values[this._questionKeys.mobile];
		entity.entity_name = values[this._questionKeys.entity_name];
		entity.type = entityType;

		// Entity Profile
		entity.profile.dob = values[this._questionKeys.dob];
		entity.profile.nationality =
			values[this._questionKeys.nationality]?.nationality;
		entity.profile.job_title = values[this._questionKeys.job_title];
		entity.profile.tax_number = values[this._questionKeys.tax_number];
		entity.profile.legal_person_type = values[
			this._questionKeys.legal_person_type
		]?.map((item: any) => item.value);
		entity.profile.shares = values[this._questionKeys.shares];
		entity.profile.shareholder =
			values[this._questionKeys.shares] > 0 ||
			entity.profile.legal_person_type?.includes('shareholder');
		entity.profile.director =
			entity.profile.legal_person_type?.includes('director');
		entity.profile.company_is_fund =
			values[this._questionKeys.company_is_fund];
		entity.profile.company_registration_number =
			values[this._questionKeys.company_registration_number];
		entity.profile.company_is_trust =
			values[this._questionKeys.company_is_trust];
		entity.profile.company_is_holding =
			values[this._questionKeys.company_is_holding];

		// Entity Address
		entity.address.street = values[this._questionKeys.street];
		entity.address.sub_street = values[this._questionKeys.sub_street];
		entity.address.city = values[this._questionKeys.city];
		entity.address.state = values[this._questionKeys.state];
		entity.address.postcode = values[this._questionKeys.postcode];
		entity.address.country = values[this._questionKeys.country]?.code;

		if (entity.profile.shareholder) {
			if (values[this._questionKeys.shares] > 25) {
				entity.profile.legal_person_type?.push('ubo');
			}
		}
		return entity;
	}

	mapEntityContactToQuestions(contactDetails: Contacts): Record<string, any> {
		return {
			entityId: contactDetails.entityId,
			type: contactDetails.type,
			[this._questionKeys.fullName]: contactDetails?.name,
			[this._questionKeys.position]: contactDetails?.position,
			[this._questionKeys.email]: contactDetails?.email,
			[this._questionKeys.mobile]: contactDetails?.mobile,
		};
	}

	mapQuestionsToEntityContact(values: Record<string, any>): Contacts {
		const contact = new Contacts();

		contact.type = values.type;
		contact.entityId = values.entityId;
		contact.name = values[this._questionKeys.fullName];
		contact.position = values[this._questionKeys.position];
		contact.email = values[this._questionKeys.email];
		contact.mobile = values[this._questionKeys.mobile];

		return contact;
	}
}
