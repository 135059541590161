import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, Self } from "@angular/core";
import { ConfigQuestion } from '../../models/config-question.interface';
import {
	ControlValueAccessor,
	FormArray,
	FormControl,
	FormGroup,
	NgControl,
	Validators,
} from '@angular/forms';
import { ERROR_MESSAGES } from '../../constants/enum.const';
import { PercentPipe } from '@angular/common';
import { takeWhile } from 'rxjs/operators';

@Component({
	selector: 'bcb-generic-percentage',
	templateUrl: './generic-percentage.component.html',
	styleUrls: ['./generic-percentage.component.scss'],
})
export class GenericPercentageComponent
	implements ControlValueAccessor, OnInit, OnDestroy
{
	@Input() question!: ConfigQuestion;
	@Input() remainingPercentage!: number;
	@Input() errorMessage?: ERROR_MESSAGES;
	@Input() shouldCalculate?: boolean = true;
	control!: FormControl;
	parent!: FormGroup | FormArray | null;
	disabled!: boolean;
	unsubscribe: boolean = false;

	constructor(
		@Self() private readonly controlDirective: NgControl,
		private readonly percentPipe: PercentPipe
	) {
		controlDirective.valueAccessor = this;
	}

	onChange: (value: any) => void = () => {};

	onTouched: () => void = () => {};

	ngOnInit(): void {
		this.control = this.controlDirective.control as FormControl;
		this.parent = this.control.parent;

		if (this.shouldCalculate) {
			this.parent?.valueChanges
				.pipe(takeWhile(() => !this.unsubscribe))
				.subscribe(() => this.calculateRemainingPercentage());
		}
		this.control.setValidators(Validators.max(this.remainingPercentage));
	}

	ngOnDestroy(): void {
		this.unsubscribe = true;
	}

	writeValue(value: any): void {
		value &&
			this.controlDirective.control?.setValue(
				this.percentPipe.transform(value),
				{
					emitEvent: false,
				}
			);
	}

	registerOnChange(onChange: (value: any) => void): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void): void {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}

	private calculateRemainingPercentage(): void {
		let totalPercentage = 0;
		const groupKeys = Object.keys(this.parent?.value);
		if (this.parent?.parent) {
			for (let key of groupKeys) {
				totalPercentage += Number(this.parent.value[key] || 0);
			}
			this.remainingPercentage =
				100 - totalPercentage + Number(this.control.value || 0);
		}

		if (this.question.attributes?.sharesPercentageWith) {
			for (let key of groupKeys.filter((_key) =>
				this.question?.attributes?.sharesPercentageWith.includes(_key)
			)) {
				totalPercentage += Number(this.parent?.value[key] || 0);
			}
			this.remainingPercentage =
				100 - totalPercentage + Number(this.control.value || 0);
		}

		if (this.remainingPercentage < 0) {
			this.remainingPercentage = 0;
		}

		this.control.setValidators([
			Validators.required,
			Validators.max(this.remainingPercentage),
		]);
	}
}
