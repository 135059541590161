import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OrgChartComponent} from "./org-chart.component";
import {OrgChartNodeComponent} from "./org-chart-node/org-chart-node.component";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";


@NgModule({
    declarations: [OrgChartComponent, OrgChartNodeComponent],
    exports: [
        OrgChartComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule
    ]
})
export class OrgChartModule {
}

