import { Shareholder } from '../../models/shareholder.interface';

export class LoadShareholders {
	static readonly type = '[shareholders] load shareholders';

	constructor(public payload: Array<Shareholder>) {}
}

export class UpdateShareholders {
	static readonly type = '[shareholders] update shareholders';

	constructor(public payload: Array<Shareholder>) {}
}

export class GetShareholdersByParent {
	static readonly type = '[shareholders] get shareholder by parentId';

	constructor(public payload?: string) {}
}

export class RemoveShareholder {
	static readonly type = '[shareholders] remove shareholder';

	constructor(public payload: string) {}
}

export class AddUpdateShareholder {
	static readonly type = '[shareholders] add shareholder';

	constructor(public payload: Shareholder) {}
}

export class SetActiveShareholder {
	static readonly type = '[shareholders] set active shareholder';

	constructor(public payload?: string) {}
}

export class ClearState {
	static readonly type = '[shareholders] clear shareholders state';

	constructor() {}
}
