import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ConfigQuestion } from '../../models/config-question.interface';
import { ERROR_MESSAGES } from '../../constants/enum.const';
import { HttpService } from '../../services/http/http.service';
import { catchError, finalize, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Document, Entity } from "../../models/entity.model";
import { ArrayUtils } from "../../utils/array.util";

@Component({
	selector: 'bcb-generic-file',
	templateUrl: './generic-file.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: GenericFileComponent,
		},
	],
	styleUrls: ['./generic-file.component.scss'],
})
export class GenericFileComponent implements ControlValueAccessor, OnInit {
	@Input() entityId!: string;
	@Input() fileTypePrefix!: string;
	@Input() question!: ConfigQuestion;
	@Input() formGroup?: FormGroup;
	@Input() formControl?: FormControl;
	@Input() formControlName?: string;
	@Input() errorMessage?: ERROR_MESSAGES;
	@Output() change: EventEmitter<any> = new EventEmitter<any>();
	@Output() uploadedFiles: EventEmitter<Array<Document>> = new EventEmitter<Array<Document>>();
	@Output() uploadSkipped: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

	value: any;
	isDisabled: boolean = false;
	uploading: boolean = false;
	deleting: Record<string, boolean> = {};
	filesViewExpanded: boolean = false;
	_uploadedFiles: Array<any> = [];
	_displayFiles: Array<any> = [];
	identifier!: string
	markedForDelete: Set<string> = new Set<string>();
	defaultAcceptedFileTypes = 'application/pdf, image/*, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document'

	constructor(
		private readonly http: HttpService,
		private readonly _snackBar: MatSnackBar
	) {
	}

	ngOnInit(): void {
		if (this.formControl) {
			this.formGroup = this.formControl.parent as FormGroup;
		} else if (this.formGroup && this.formControlName) {
			this.formControl = this.formGroup.get(
				this.formControlName
			) as FormControl;
		} else {
			throw new Error(
				'[formControl] or [formControlName] + [FormGroup] is required for <bcb-generic-file>'
			);
		}
		if (!this.entityId) {
			throw new Error('[entityId] is required for <bcb-generic-file>');
		}
		if (!this.fileTypePrefix && !this.question.attributes?.identifiedBy) {
			throw new Error(
				'[fileTypePrefix] is required for <bcb-generic-file>'
			);
		}

		this._uploadedFiles = this.formControl.value || []
		this.identifier = `${this.question.attributes?.identifiedBy ?? this.fileTypePrefix}_${this.question.key}`;

		this.updateFileControl();
	}

	changed: (value: any) => void = () => {};

	touched: () => void = () => {};

	writeValue(obj: any): void {
		this.value = obj;
	}

	onSkip(): void {
		this.formControl?.setValue({ skipped: true, reason: null });
	}

	toggleFileView(event: MouseEvent): void {
		event.preventDefault()
		event.stopPropagation()
		this.filesViewExpanded = !this.filesViewExpanded;
	}

	onReasonChange(event: Event): void {
		this.formControl?.setValue({
			skipped: true,
			reason: (<HTMLInputElement>event.target).value,
		});

		this.changed(this.formControl?.value)
		this.uploadSkipped.emit(this.formControl?.value)
	}

	onChange(event: Event): void {
		this.changed((<HTMLInputElement>event.target)?.value);
	}

	registerOnChange(fn: any): void {
		this.changed = fn;
	}

	registerOnTouched(fn: any): void {
		this.touched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	fileChange(files: FileList): void {
		if (files && files.length > 0) {
			const _documents: Array<{
				meta_data: any;
				document: any;
				entity_id: string;
			}> = [];
			if(!this.question.attributes?.multiple) {
				this.formControl?.setValue(undefined);
				if(this._uploadedFiles.length) {
					this.removeFile(this._uploadedFiles[0])
				}
				this._uploadedFiles = [];
			}

			for (let i = 0; i < files.length; i++) {
				const reader = new FileReader();
				const file = files[i];
				reader.readAsDataURL(file);

				console.debug({
					fileSize: file.size,
					limit: environment.maxFileUploadSizeBytes,
				});

				reader.onload = () => {
					_documents.push({
						meta_data: {},
						document: {
							filename: this.renameFileWithCount(file, this._displayFiles).name,
							content_type: file.type,
							type: this.identifier,
							document: reader.result,
						},
						entity_id: this.entityId,
					});
				};

				reader.onloadend = () => {
					if (_documents.length === files.length) {
						this.uploadFiles(_documents, this.identifier);
					}
				};
			}
		}
	}

	removeFile(file: any): void {
		if (this.deleting[file.document]) {
			return;
		}
		this.markedForDelete.delete(file.document);
		this.deleting[file.document] = true;
		this.http.deleteDocument(this.entityId, this.identifier, file.id)
			.pipe(
				tap((documents) => {
					this._uploadedFiles = documents;
					this.uploadedFiles.emit(this._uploadedFiles);
					this.changed(this._uploadedFiles);
				}),
				catchError((e) => {
					console.error(e);
					const message = ERROR_MESSAGES.DELETE_FAILED;
					this._snackBar.open(message, undefined, {
						duration: 5000,
						panelClass: "bg-danger"
					});
					return message;
				}),
				finalize(() => {
					delete this.deleting[file.document];
					this.updateFileControl();
				}))
			.subscribe();
	}

	private updateFileControl(): void {
		this._displayFiles = ArrayUtils.sortObject(this._uploadedFiles.filter((file: Document) => !file.meta_data?.archived) || [], 'filename')

		if (this._displayFiles?.length > 1) {
			this.formControl?.setValue(`${this._displayFiles?.length} files uploaded`, {
				emitEvent: false,
			});
		} else if (this._uploadedFiles?.length) {
			this.formControl?.setValue(this._displayFiles[0]?.filename, {
				emitEvent: false
			});
			this.filesViewExpanded = false;
		} else {
			this.formControl?.setValue(undefined, {
				emitEvent: false,
			});
			this.filesViewExpanded = false;
		}

		this.changed(this.formControl?.value)
	}

	private uploadFiles(
		documents: Array<{
			meta_data: any;
			document: any;
			entity_id: string;
		}>,
		identifier: string
	): void {
		this.uploading = true;
		this.http
			.uploadDocumentMulti_v2(documents, this.entityId, identifier)
			.pipe(
				tap((documents: Array<any>) => {
					try {
						this._uploadedFiles = this._uploadedFiles.concat(documents);
					} catch (e) {
						this._uploadedFiles = documents;
					}
					this.uploadedFiles.emit(this._uploadedFiles);
					this.changed(this._uploadedFiles);
				}),
				tap(() => {
					this.uploading = false;
					this.updateFileControl();
				}),
				catchError((e) => {
					console.error(e);
					const message = ERROR_MESSAGES.UPLOAD_FAILED;
					this.uploading = false;
					this._snackBar.open(message, undefined, {
						duration: 5000,
						panelClass: 'bg-danger',
					});
					return message;
				})
			)
			.subscribe();
	}

	private renameFileWithCount(file: File, uploadedFiles: Array<Document>): File {
		const baseFilename = file.name.substring(0, file.name.lastIndexOf('.'));
		const fileExtension = file.name.substring(file.name.lastIndexOf('.'));
		const baseFilenameCountMap: Record<string, number> = {};

		// Populate baseFilenameCountMap with counts from uploadedFiles
		uploadedFiles.forEach(existingFile => {
			const regex = new RegExp(`^${baseFilename}[\\s\\-]*(?<count>\\d*)${fileExtension}$`);
			const match = existingFile.filename?.match(regex);

			if (match) {
				const countOnFile = Number(match.groups?.count);
				baseFilenameCountMap[baseFilename] = Math.max(countOnFile, (baseFilenameCountMap[baseFilename] ?? 0));
			}
		});

		if (typeof baseFilenameCountMap[baseFilename] === 'number') {
			baseFilenameCountMap[baseFilename] = baseFilenameCountMap[baseFilename] + 1;
			const newFilename = `${baseFilename} - ${baseFilenameCountMap[baseFilename]}${fileExtension}`;

			return { ...file, name: newFilename };
		}

		return file;
	}
}
