<bcb-generic-label [question]="question"></bcb-generic-label>
<div [formGroup]="formGroup" class="currency-row">
    <mat-form-field id="currencySelect" appearance='outline' [formGroupName]="question.key">
        <mat-select formControlName='currency'
                    (valueChange)="onChange()"
                    (blur)="touched()">
            <mat-option *ngFor="let option of question.options"
                        [value]="option.value">{{option.label}}</mat-option>
        </mat-select>
    </mat-form-field>
    <mat-form-field id="amountInput" appearance='outline' [formGroupName]="question.key">
        <input
                matInput
                formControlName='amount'
                (change)="onChange()"
                (input)="onChange()"
                (blur)="touched()">
        <mat-error
                *ngIf="formGroup.get(formGroupName)?.invalid">{{errorMessage}}</mat-error>
    </mat-form-field>
</div>