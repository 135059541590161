import { Component, OnInit } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from '../../../../core/services/http/http.service';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { Entity } from '../../../../core/models/entity.model';
import { first, switchMap } from 'rxjs/operators';
import { APP_ROUTES } from '../../../../core/constants/enum.const';
import { Router } from '@angular/router';
import { StepModel } from '../../../stepper/models/step.model';

@Component({
    selector: 'bcb-review-legal',
    templateUrl: './review-legal.component.html',
    styleUrls: ['./review-legal.component.scss']
})
export class ReviewLegalComponent implements OnInit {
    processing: boolean = false;
    entity?: Entity;

    formGroup: FormGroup;

    constructor(
        private readonly stepperQueries: StepperQueries,
        private readonly entityQueries: EntityQueries,
        private readonly http: HttpService,
        private readonly router: Router
    ) {
        this.formGroup = new FormGroup({
            understands_bcb_group_does_not_hold_cash_or_assets: new FormControl(
                null,
                [Validators.required]
            ),
            understands_cryptocurrencie_are_highly_volatile_assets:
                new FormControl(null, [Validators.required])
        });

        this.entityQueries
            .getState()
            .subscribe((entity) => (this.entity = entity as Entity));
    }

    onNextClick(): void {
        if (this.formGroup.dirty) {
            this.processing = true;
            this.http
                .individualTerms({
                    ...this.formGroup.value,
                    entity_id: this.entity?.id
                })
                .pipe(
                    switchMap(() =>
                        this.http.completeOnboarding({
                            entity_id: this.entity?.id
                        })
                    )
                )
                .subscribe(
                    () => {
                        this.processing = false;
                        this.entityQueries.loadState();

                        this.http.logout().then(() => {
                            this.entityQueries.clearState();

                            window.location.href = `/${APP_ROUTES.completed}`;
                        });
                    },
                    (e) => (this.processing = false)
                );
        }
    }

    onPreviousClick(): void {
        if (this.formGroup.dirty) {
            this.processing = true;
            this.http
                .individualTerms({
                    ...this.formGroup.value,
                    entity_id: this.entity?.id
                })
                .pipe(
                    switchMap(() =>
                        this.http.completeOnboarding({
                            entity_id: this.entity?.id
                        })
                    )
                )
                .subscribe(
                    () => {
                        this.processing = false;
                        this.entityQueries.loadState();
                        this.stepperQueries.navigateStep('prev');
                    },
                    (e) => (this.processing = false)
                );
        } else {
            this.stepperQueries.navigateStep('prev');
        }
    }

    ngOnInit(): void {
        this.stepperQueries
            .getStep()
            .pipe(first())
            .subscribe((currentStepData: StepModel | undefined) => {
                if (currentStepData?.metadata?.disable ?? false) {
                    this.formGroup?.disable();
                }
            });
    }
}
