<div class="page">
  <div class="content">
    <div class="row">
      <h1 class="col page__title">Additional Contacts</h1>
    </div>
    <mat-divider class="mb-3"></mat-divider>
  </div>

  <table mat-table [dataSource]="entityContacts | async">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name</th>
      <td mat-cell *matCellDef="let element">
        <div class="shares">{{ element.firstName }} {{ element.lastName }}</div>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> Email</th>
      <td mat-cell *matCellDef="let element"> {{ element.email || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef> Mobile</th>
      <td mat-cell *matCellDef="let element"> {{ element.mobile || '-' }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> Type</th>
      <td mat-cell *matCellDef="let element"> {{ element.type | uppercase }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> Actions</th>
      <td mat-cell *matCellDef="let element" [class.markedForDelete]="markedForDelete.has(element.id)">
        <ng-container *ngIf="markedForDelete.has(element.id)">
          <span>You are about to delete <b>{{ element.firstName }} {{ element.lastName }}</b>. Please confirm this is correct.</span>
          <span>
                            <button *ngIf="!isDisabled" mat-icon-button title="Confirm"
                                    (click)="removeContact(element)">
                                <mat-icon>done</mat-icon>
                            </button>
                            <button *ngIf="!isDisabled" mat-icon-button title="Cancel"
                                    (click)="markedForDelete.delete(element.id)">
                                <mat-icon>close</mat-icon>
                            </button>
                        </span>
        </ng-container>
        <ng-container *ngIf="!markedForDelete.has(element.id) && element.type !== contactType.PRIMARY">
          <ng-container *ngIf="!isDisabled">
            <button *ngIf="!deleting[element.id]" title="Edit" mat-icon-button (click)="activeContact.next(element)">
              <mat-icon>edit</mat-icon>
            </button>
            <button *ngIf="!deleting[element.id]; else deletingSpinner" title="Delete" mat-icon-button
                    (click)="markedForDelete.add(element.id)">
              <mat-icon>delete</mat-icon>
            </button>
            <ng-template #deletingSpinner>
              <mat-spinner diameter="24"></mat-spinner>
            </ng-template>
          </ng-container>
        </ng-container>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No contacts have been added</td>
    </tr>
  </table>
  <div class="d-flex flex-row-reverse mt-3">
    <button mat-flat-button class="bcb-btn primary" (click)="openModal()">New Contact</button>
  </div>
</div>