import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'bcb-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {

    confirmButton: string;
    rejectButton: string;
    title?: string;
    bodyTitle?: string;
    bodyContent: Array<{ content: string, type?: 'heading' | 'paragraph' | 'bold' }>

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {
            confirmButton?: string;
            rejectButton?: string;
            title?: string;
            bodyTitle?: string;
            bodyContent: Array<{ content: string, type?: 'heading' | 'paragraph' | 'bold' }>
        }) {
        this.confirmButton = data.confirmButton ?? 'Proceed';
        this.rejectButton = data.rejectButton ?? 'Cancel';
        this.title = data.title;
        this.bodyTitle = data.bodyTitle;
        this.bodyContent = data.bodyContent;
    }
}
