import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { first, switchMap, takeUntil, tap } from "rxjs/operators";
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { Observable, Subscription } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { AccountDetailsModalComponent } from './account-details-modal/account-details-modal.component';
import { HttpService } from '../../../../core/services/http/http.service';
import { Entity } from '../../../../core/models/entity.model';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { StepModel } from '../../../stepper/models/step.model';
import { REVIEW_STATUS } from '../../../../core/constants/enum.const';
import { FormControl } from '@angular/forms';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigQueries } from "../../../../core/store/config/config.queries";
import { OnboardingStateModel } from "../../../../core/store/onboarding/onboarding.state";

@Component({
	selector: 'bcb-account-details',
	templateUrl: './account-details.component.html',
	styleUrls: ['./account-details.component.scss'],
})
export class AccountDetailsComponent extends BasePageComponent implements OnInit {
	displayedColumns: Array<string> = ['currency', 'accountName', 'actions'];
	accounts: Array<{ questions: Array<any>; details: any }> = [];
	productQuestions!: {
		products: Array<string>;
		industry_type: string;
		business_accounts: Array<{ questions: Array<any>; details: any }>;
	};
	editingAccount!: number;
	errorTooltip: string =
		'Please supply the required information for these accounts.';
	isDirty: boolean = false;

	constructor(configQueries: ConfigQueries,
							private readonly dialog: MatDialog,
							entityQueries: EntityQueries,
							http: HttpService,
							onboardingQueries: OnboardingQueries,
							stepperQueries: StepperQueries,
							_snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	ngOnInit(): void {
		this.runInit()
			.pipe(
				switchMap(() => this.stepAnswers$),
			).subscribe();
		this.stepAnswers$
			.pipe(
				first(data => !!data),
				tap((answers) => {
					if (this.productQuestions?.business_accounts) {
						this.accounts = this.productQuestions?.business_accounts.map(
							(account) => {
								return {
									details: account.details,
									questions: account.questions
								};
							}
						) ?? [];
					}
				})
			)
			.subscribe()
	}

	handleOnboardingResponse(data: OnboardingStateModel): void {
			this.productQuestions = data.questions
			? data.questions.find(
				(question) => question.step === this.currentStep.key
			)?.questions
			: undefined;
		if (this.productQuestions?.business_accounts) {
			this.accounts =
				this.productQuestions?.business_accounts.map(
					(account) => {
						return {
							details: account.details,
							questions: account.questions
						};
					}
				) ?? [];
		}
	}

	// TODO: map to Account object
	onEditAccount(data: { [key: string]: any }, index: number): void {
		this.editingAccount = index;
		const dialogRef = this.dialog.open(AccountDetailsModalComponent, {
			width: '50%',
			disableClose: true,
			role: 'dialog',
			data: {...data, entity: this.entity, accountIndex: index},
		});

		dialogRef.afterClosed().subscribe((data: { questions: any[]; details: any; }) => {
			if (data) {
				this.isDirty = true;
				this.accounts[this.editingAccount] = { ...data };
				this.http.saveAnswers(this.entity.id, this.currentStep.key, { business_accounts: this.accounts })
					.pipe(
						switchMap(() => this.http.updateAccounts({
							entityId: this.entity.id,
							businessAccounts: this.accounts
						}))
					).subscribe();
			}
		});
	}

	formatOnboardingAnswers(): { justification: string; [key: string]: any } {
		return {
			sections: this.accounts.map((account) => {
				return {
					id: `${account.details.currency}_${account.details.label}`,
					title: `${account.details.label}`,
					status: REVIEW_STATUS.ENTITY_UPDATED,
					answers: {
						...account.questions
					}
				};
			}),
			justification: this.justificationFormControl.value
		};
	}

	onStepComplete(): Observable<any> {
		return this.http
			.addCorporateProduct({
				...this.productQuestions,
				business_accounts: this.accounts,
				entity_id: this.entity.id,
			})
	}

	onNextClick() {
		if(this.canSubmit()) {
			super.validateAndSave()
		}
	}

	canSubmit() {
		return this.isDirty && !this.systemUser$.value;
	}

	onPreviousClick(): void {
		this.stepperQueries.navigateStep('prev');
	}

}
