import { Component } from '@angular/core';

@Component({
  selector: 'bcb-complete',
  templateUrl: './complete.component.html',
  styleUrls: ['./complete.component.scss']
})
export class CompleteComponent {

}
