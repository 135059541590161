import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, interval, of, Subject } from 'rxjs';
import { ActivatedRoute, Params } from '@angular/router';
import { catchError, map, switchMap, take, takeUntil, takeWhile, tap } from 'rxjs/operators';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpService } from 'src/app/core/services/http/http.service';
import { ContactType, EntityContact } from '../../../../core/models/entity-contact.model';
import { SystemUser } from '../../../../core/models/system-user.interface';

@Component({
  selector: 'bcb-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  token!: string;
  entityId!: string;
  contactId!: string;
  systemUser?: SystemUser;
  formGroup: FormGroup = new FormGroup({
    destination: new FormControl('', [Validators.required]),
    otp: new FormControl('', [Validators.required])
  });
  resendCount: number = 0;
  otp!: { id: string, expiresAt: Date };
  otpLength: number = 4;
  maxResend: number = 5;
  validatingPipeline: boolean = false;
  readonly loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  readonly sendOtp: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  readonly loginStage: BehaviorSubject<string> = new BehaviorSubject<string>('init');
  readonly requestedVerification: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>('email');
  readonly entityContact: BehaviorSubject<EntityContact | undefined> = new BehaviorSubject<EntityContact | undefined>(undefined);
  readonly otpTimeout: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(undefined);
  private readonly unsubscribe: Subject<void> = new Subject<void>();

  constructor(private readonly activatedRoute: ActivatedRoute, private readonly httpService: HttpService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        tap(() => this.loading.next(true)),
        takeUntil(this.unsubscribe),
        tap((params: Params) => {
          this.entityId = params.entity_id;
        }),
        switchMap(() => this.activatedRoute.queryParams),
        switchMap((params: Params) => {
          if (params.systemUser) {
            return this.httpService.fetchSystemUser(params.systemUser);
          }
          return of(undefined);
        }),
        tap((systemUser: SystemUser | undefined) => {
          if (systemUser) {
            this.systemUser = systemUser;
            this.formGroup.get('destination')?.setValue(systemUser?.email);
          }
        }),
        tap(() => this.loading.next(false)),
        catchError((e) => {
          this.loading.next(false);
          return of(e);
        })
      )
      .subscribe();

    this.entityContact
      .pipe(
        tap(() => this.loading.next(true)),
        takeUntil(this.unsubscribe),
        tap((contact) => this.httpService.setEntityContact(contact)),
        tap((contact) => this.contactId = String(contact?.id)),
        tap((contact) => {
          if (!contact)
            return;
          if (contact?.type === ContactType.PRIMARY) {
            if (!contact.emailVerified) {
              this.requestVerification('email', contact);
            } else if (!contact.mobileVerified) {
              this.requestVerification('mobile', contact);
            } else {
              this.requestedVerification.next(undefined);
            }
          } else {
            if (!contact?.emailVerified) {
              this.requestVerification('email', contact);
            } else {
              this.requestedVerification.next(undefined);
            }
          }
        }),
        tap(() => {
          if(this.requestedVerification.value === undefined) {
            this.initiateLogin();
          } else {
            this.loading.next(false);
          }
        }),
        catchError((e) => {
          this.loading.next(false);
          return of(e);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }

  onEmailSubmit(): void {
    if (this.systemUser) {
      this.requestedVerification.next(undefined);
      this.initiateLogin()
    } else {
      this.loading.next(true)
      this.httpService.getAdditionalContact(this.entityId, this.entityContact.value?.email || this.formGroup.get('destination')?.value)
        .pipe(
          tap(() => this.entityContact.next(undefined)),
          tap((contact) => this.entityContact.next(contact)),
          tap(() => this.loading.next(false)),
          catchError((e) => {
            this.loading.next(false);
            return of(e);
          })
        )
        .subscribe();
    }
  }

  validateOtp(): void {
    const method = this.requestedVerification.value as 'email' | 'mobile';
    const code = this.formGroup.get('otp')?.value;
    this.loading.next(true)
      this.httpService.validateOtp({ id: this.otp.id, method, code })
        .pipe(
          tap(() => this.resendCount = 0),
          tap(() => this.onEmailSubmit()),
          catchError((e) => {
            this.loading.next(false);
            return of(e);
          })
        )
        .subscribe();
  }

  requestOtp(): void {
    if(this.otpTimeout.value && this.resendCount > this.maxResend) {
      return;
    }

    const method = this.requestedVerification.value as 'email' | 'mobile';
    const destination = this.formGroup.get('destination')?.value;

    this.loading.next(true)
    this.httpService.requestOtp({ entityId: this.entityId, method, destination, contactId: this.contactId })
      .pipe(
        tap((otp) => this.otp = otp),
        tap(() => this.startCountdown()),
        tap(() => this.loading.next(false)),
        catchError((e) => {
          this.loading.next(false);
          return of(e);
        })
      )
      .subscribe();
  }

  onOtpChange(otp: string): void {
    this.formGroup.get('otp')?.setValue(otp);

    if (!this.loading.value && this.formGroup.get('otp')?.value?.length === this.otpLength) {
      this.sendOtp.next(true);
    } else {
      this.sendOtp.next(false);
    }
  }

  requestVerification(method: 'email' | 'mobile', contact: EntityContact): void {
    if (method === 'email') {
      this.requestedVerification.next('email');
      this.loginStage.next('email otp');
      this.formGroup.get('destination')?.setValue(contact.email);
      this.requestOtp();
    } else if (method === 'mobile') {
      this.requestedVerification.next('mobile');
      this.loginStage.next('mobile otp');
      this.formGroup.get('destination')?.setValue(contact.mobile);
      this.requestOtp();
    }
    this.validatingPipeline = true;
    this.formGroup.get('destination')?.disable();
    this.formGroup.get('otp')?.setValue(undefined);
    this.formGroup.get('otp')?.updateValueAndValidity();
    this.formGroup.get('otp')?.markAsPristine();
  }

  private initiateLogin(): void {
    if(!this.systemUser) {
      this.formGroup.get('destination')?.setValue(this.entityContact.value?.email);
    }
    this.requestedVerification.next(undefined);
    this.loading.next(true)
    this.httpService.fetchToken(this.entityId)
      .pipe(
        tap((token: string) => this.token = token),
        tap(() => this.loginStage.next('login')),
        tap(() => this.requestedVerification.next('email')),
        tap(() => this.loading.next(false)),
      )
      .subscribe();
  }

  private startCountdown() {
    let duration: number = 1;
    let elapsed: number = 0
    if(this.resendCount > 0) {
      duration = duration * (this.resendCount * 2)
    }

    interval(1000)
      .pipe(
        take(duration),
        takeWhile(() => elapsed < duration)
      )
      .subscribe(() => {
        elapsed++;
        this.otpTimeout.next((duration - elapsed) * 1000);
        if (elapsed >= duration) {
          this.resendCount++;
          this.otpTimeout.next(undefined);
        }
      });
  }

}
