import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ClearOnboarding } from '../../store/onboarding/onboarding.actions';
import { first, map } from "rxjs/operators";
import { Document, Entity } from '../../models/entity.model';
import { OnboardingSection } from '../../models/onboarding.interface';
import { OnboardingReview } from '../../models/onboarding-review.interface';
import { ConfigQuestion } from '../../models/config-question.interface';
import { ContactType, EntityContact } from '../../models/entity-contact.model';
import { SystemUser } from '../../models/system-user.interface';

@Injectable({
	providedIn: 'root',
})
export class HttpService {
	private headers = new HttpHeaders().set('Content-Type', 'application/json');
	systemUser = new BehaviorSubject<string | undefined>(undefined);
	entityContact = new BehaviorSubject<EntityContact | undefined>(undefined);

	constructor(
		private readonly http: HttpClient,
		private readonly router: Router,
		private readonly store: Store
	) {}

	getEntityDetails(): Observable<any> {
		return this.http.get<any>(`${environment.onboardingApiUrl}/onboarding/entity`, {
			headers: this.headers,
		});
	}

	fetchSystemUser(id: string): Observable<SystemUser> {
		return this.http.get<SystemUser>(`${environment.onboardingApiUrl}/onboarding/system-user/${id}`);
	}

	setSystemUser(value: string | undefined): void {
		if(value) {
			sessionStorage.setItem('onboarding_system_user', value)
		} else {
			sessionStorage.removeItem('onboarding_system_user')
		}

		this.systemUser.next(value)
	}

	getSystemUser(): string | undefined {
		const _systemUser = sessionStorage.getItem('onboarding_system_user') ?? undefined
		this.setSystemUser(_systemUser)

		return _systemUser
	}

	setEntityContact(value: EntityContact | undefined): void {
		if(value) {
			sessionStorage.setItem('onboarding_contact', JSON.stringify(value))
		} else {
			sessionStorage.removeItem('onboarding_contact')
		}

		this.entityContact.next(value)
	}

	getEntityContact(): EntityContact | undefined {
		const _entityContact = sessionStorage.getItem('onboarding_contact') ?? undefined
		const _parsedValue = _entityContact ? JSON.parse(_entityContact) : undefined
		this.setEntityContact(_parsedValue)

		return _parsedValue
	}

	getPrimaryContact(): string | undefined {
		if (this.entityContact.value?.type === ContactType.PRIMARY)
			return this.entityContact.value?.id;

		return undefined;
	}

	authLogin(data: {
		entity_id: string;
		token: string;
		systemUser?: string;
	}): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/auth/login`,
			data
		);
	}

	authLoginV2(data: {
		entityId: string;
		token: string;
		contactId: string;
		systemUser?: string;
	}): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/auth/login`,
			data
		);
	}

	authVerify(data: {
		entity_id: string;
		token: string;
		otp: string;
		systemUserId?: string;
	}): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/auth/verify`,
			data
		);
	}

	authVerifyV2(data: {
		entity_id: string;
		token: string;
		otp: string;
		contactId: string;
		systemUserId?: string;
	}): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/auth/verify`,
			data
		);
	}

	individualUpdateProfile(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/individual/profile`,
			data
		);
	}

	updateAddress(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/address`,
			data
		);
	}

	createContactDetails(data: Record<string, any>): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/contacts`,
			data
		);
	}

	uploadDocuments(documents: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/individual/documents`,
			documents
		);
	}

	uploadCorporateDocument(document: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/documents`,
			document,
			{ reportProgress: true }
		);
	}

	individualTerms(terms: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/individual/terms`,
			terms
		);
	}

	getCorporateSubEntity(corporateId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/sub-entities/${corporateId}`,
			{
				headers: this.headers,
			}
		);
	}

	getCorporateEntityDirectors(corporateId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/directors/${corporateId}`,
			{
				headers: this.headers,
			}
		);
	}

	getCorporateEntityShareholders(corporateId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/shareholders/${corporateId}`,
			{
				headers: this.headers,
			}
		);
	}

	getSubCorporateById(subCorporateId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/sub-entity-corporate/${subCorporateId}`,
			{
				headers: this.headers,
			}
		);
	}

	completeShareholder(corporateId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/complete-shareholder/${corporateId}`,
			{
				headers: this.headers,
			}
		);
	}

	saveAndCompleteLater(): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/entity/save`,
			{
				headers: this.headers,
			}
		);
	}

	completeDirector(corporateId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/complete-director/${corporateId}`,
			{
				headers: this.headers,
			}
		);
	}

	removeCorporateSubEntity(
		entityId: string,
		parentId: string
	): Observable<any> {
		return this.http.delete<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/corporate/sub-entities/${entityId}/${parentId}`,
			{
				headers: this.headers,
			}
		);
	}

	addIndividualSubEntity(data: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/sub-entity/individual`,
			data
		);
	}

	addCorporateProduct(data: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/products`,
			data
		);
	}

	corporateRegistration(data: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/registration`,
			data
		);
	}

	addCorporateSubEntity(data: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/sub-entity/corporate`,
			data
		);
	}

	updateIndividualSubEntity(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/sub-entity/individual`,
			data
		);
	}

	updateDirectorSubEntity(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/sub-entity/individual`,
			data
		);
	}

	updateCorporateSubEntity(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/sub-entity/corporate`,
			data
		);
	}

	updateCorporateProfile(data: {
		entity: {
			entity_id: string;
			entity_name?: string;
			authorisation?: string;
			position: string;
			email: string;
			invoice_email: string;
			first_name: string;
			last_name: string;
			mobile: string;
			dob: any;
			nationality: string;
		};
		raw: Record<string, any>;
	}): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/profile`,
			data
		);
	}

	updateCorporateInfo(data: Record<string, any>): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/info`,
			data
		);
	}

	getAvailablePercentage(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/available/percentage`,
			data
		);
	}

	completeApplication(data: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/corporate/complete-application`,
			data
		);
	}

	completeOnboarding(data: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/complete-onboarding`,
			data
		);
	}

	completeApplicationWithSubCorporates(corporateId: string): Observable<any> {
		return this.http.get<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/complete-application/${corporateId}`,
			{
				headers: this.headers,
			}
		);
	}

	dueDiligence(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/due-diligence`,
			data
		);
	}

	uploadScamClaim(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/scam-claim`,
			data
		);
	}

	uploadVideo(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/upload-video`,
			data
		);
	}

	getTokenPayload(): any {
		const token = this.getToken();
		if (!token) {
			this.router.navigate(['/']);
			return;
		}
		const tokenPayload = decode(token);
		if (!tokenPayload) {
			return;
		} else {
			return tokenPayload;
		}
	}

	getOnboardingAuthType() {
		return (
			this.getTokenPayload().entity_type || this.router.navigate(['/'])
		);
	}

	logout(): Promise<any> {
		sessionStorage.removeItem('onboarding_token');
		return this.store
			.dispatch(new ClearOnboarding())
			.pipe(first())
			.toPromise();
	}

	getToken(): string | undefined {
		return sessionStorage.getItem('onboarding_token') ?? undefined;
	}

	fetchToken(entityId: string): Observable<string> {
		return this.http.get<{
			token: string
		}>(`${environment.onboardingApiUrl}/onboarding/token/${entityId}`).pipe(map(res => res.token));
	}

	//	New services
	getAllSubEntities(entityId: string): Observable<Array<Entity>> {
		return this.http.get<Array<Entity>>(
			`${environment.onboardingApiUrl}/onboarding/corporate/v2/sub-entities/${entityId}`
		);
	}

	completeWelcome(corporateId: string): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/corporate/welcome/${corporateId}`,
			undefined
		);
	}

	updateYieldProduct(
		questions: Record<string, any>,
		entityId: string
	): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/corporate/products/yield/${entityId}`,
			questions
		);
	}

	updateTaxForms(
		data: Record<string, any>,
		entityId: string
	): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/corporate/taxForms/${entityId}`,
			data
		);
	}

	updateSourceOfFunds(
		data: Record<string, string | object>,
		entityId: string
	): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/corporate/sourceOfFunds/${entityId}`,
			data
		);
	}

	updateOnboardingAnswers(
		answers: OnboardingSection,
		entityId: string
	): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/v2/answers/section/${entityId}`,
			answers
		);
	}

	uploadDocument_v2(document: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/document`,
			document
		);
	}

	uploadDocumentMulti_v2(
		documents: Array<any>,
		entity_id: string,
		identifier: string
	): Observable<Array<any>> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/document/multi`,
			{ documents, entity_id, identifier }
		);
	}

	deleteDocument(
		entity_id: string,
		identifier: string,
		documentId: string
	): Observable<Array<Document>> {
		return this.http.delete<Array<Document>>(
			`${environment.onboardingApiUrl}/onboarding/document/${entity_id}/${identifier}?document=${documentId}`
		);
	}

	addLegalEntity(data: any): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/legal-entity`,
			data
		);
	}

	updateLegalEntity(data: any): Observable<any> {
		return this.http.put<any>(
			`${environment.onboardingApiUrl}/onboarding/corporate/legal-entity`,
			data
		);
	}

	getOnboardingConfig(): Observable<Record<string, Array<ConfigQuestion>>> {
		return this.http.get<Record<string, Array<ConfigQuestion>>>(
			`${environment.onboardingApiUrl}/onboarding/config`
		);
	}

	getOnboardingConfigForSection(
		section: string
	): Observable<Array<ConfigQuestion>> {
		return this.http.get<Array<ConfigQuestion>>(
			`${environment.onboardingApiUrl}/onboarding/config/${section}`
		);
	}

	// ---------- REVIEWS ----------
	getLatestOnboardingReview(entity_id: string): Observable<OnboardingReview> {
		return this.http.get<OnboardingReview>(
			`${environment.onboardingApiUrl}/onboarding-review/${entity_id}/latest`
		);
	}

	saveAnswers(entityId: string, section: string, answer: Record<string, any>, path?: string): Observable<any> {
		return this.http.post<any>(
			`${environment.onboardingApiUrl}/onboarding/${entityId}/answers/${section}`,
			{ answer, path }
		);
	}

	updateAccounts(body: {entityId: string, businessAccounts: Array<{ questions: Array<any>; details: any }>}): Observable<Entity> {
		return this.http.put<Entity>(
			`${environment.onboardingApiUrl}/onboarding/corporate/accounts`,
			body
		);
	}

	// ---------- ADDITIONAL CONTACTS ----------
	getAdditionalContact(entityId: string, email: string): Observable<EntityContact> {
		return this.http.get<EntityContact>(
			`${environment.onboardingApiUrl}/onboarding/additional-contact/${entityId}?email=${email}`
		);
	}
	getPrimaryContactForEntity(entityId: string, email: string): Observable<EntityContact> {
		return this.http.get<EntityContact>(
			`${environment.onboardingApiUrl}/onboarding/primary-contact/${entityId}?email=${encodeURIComponent(email)}`
		);
	}
	saveAdditionalContact(data: EntityContact): Observable<EntityContact> {
		return this.http.post<EntityContact>(
			`${environment.onboardingApiUrl}/onboarding/additional-contact`,
			data
		);
	}
	deleteAdditionalContact(id: string): Observable<void> {
		return this.http.delete<void>(
			`${environment.onboardingApiUrl}/onboarding/additional-contact/${id}`
		);
	}
	requestOtp(payload: {entityId: string, contactId: string, method: 'email' | 'mobile', destination: string} ):  Observable<{
		id: string, expiresAt: Date
	}> {
		return this.http.post<{
			id: string, expiresAt: Date
		}>(`${environment.onboardingApiUrl}/onboarding/otp`, payload);
	}
	validateOtp(payload: { id: string, method: 'email' | 'mobile', code: string} ):  Observable<void> {
		return this.http.post<void>(`${environment.onboardingApiUrl}/onboarding/otp/verify`, payload);
	}
}
