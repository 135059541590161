import {Country} from '../models/country.interface';
import {USState} from '../models/us-state.interface';
import {Descriptor} from "../models/descriptor.interface";

export class StaticData {
    static readonly countries: Array<Country> = [
        {
            id: 1,
            code: 'AD',
            name: 'Andorra',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'AND',
            call_code: 376,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ad.svg',
            nationality: 'Andorran',
        },
        {
            id: 2,
            code: 'AE',
            name: 'United Arab Emirates',
            currency_code: 'AED',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'ARE',
            call_code: 971,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ae.svg',
            nationality: 'Emirati, Emirian, Emiri',
        },
        {
            id: 3,
            code: 'AF',
            name: 'Afghanistan',
            currency_code: 'AFN',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'AFG',
            call_code: 93,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/af.svg',
            nationality: 'Afghan',
        },
        {
            id: 4,
            code: 'AG',
            name: 'Antigua and Barbuda',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'ATG',
            call_code: 1268,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ag.svg',
            nationality: 'Antiguan or Barbudan',
        },
        {
            id: 5,
            code: 'AI',
            name: 'Anguilla',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'AIA',
            call_code: 1264,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ai.svg',
            nationality: 'Anguillan',
        },
        {
            id: 6,
            code: 'AL',
            name: 'Albania',
            currency_code: 'ALL',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'ALB',
            call_code: 355,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/al.svg',
            nationality: 'Albanian',
        },
        {
            id: 7,
            code: 'AM',
            name: 'Armenia',
            currency_code: 'AMD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'ARM',
            call_code: 374,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/am.svg',
            nationality: 'Armenian',
        },
        {
            id: 8,
            code: 'AO',
            name: 'Angola',
            currency_code: 'AOA',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'AGO',
            call_code: 244,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ao.svg',
            nationality: 'Angolan',
        },
        {
            id: 9,
            code: 'AQ',
            name: 'Antarctica',
            currency_code: '',
            continent_name: 'Antarctica',
            continent: 'AN',
            iso_alpha_3: 'ATA',
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/aq.svg',
            nationality: 'Antarctic',
        },
        {
            id: 10,
            code: 'AR',
            name: 'Argentina',
            currency_code: 'ARS',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'ARG',
            call_code: 54,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ar.svg',
            nationality: 'Argentine',
        },
        {
            id: 11,
            code: 'AS',
            name: 'American Samoa',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'ASM',
            call_code: 1684,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/as.svg',
            nationality: 'American Samoan',
        },
        {
            id: 12,
            code: 'AT',
            name: 'Austria',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'AUT',
            call_code: 43,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/at.svg',
            nationality: 'Austrian',
        },
        {
            id: 13,
            code: 'AU',
            name: 'Australia',
            currency_code: 'AUD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'AUS',
            call_code: 0,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/au.svg',
            nationality: 'Australian',
        },
        {
            id: 14,
            code: 'AW',
            name: 'Aruba',
            currency_code: 'AWG',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'ABW',
            call_code: 297,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/aw.svg',
            nationality: 'Aruban',
        },
        {
            id: 15,
            code: 'AX',
            name: 'Åland',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'ALA',
            call_code: 358,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ax.svg',
            nationality: 'Åland Island',
        },
        {
            id: 16,
            code: 'AZ',
            name: 'Azerbaijan',
            currency_code: 'AZN',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'AZE',
            call_code: 994,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/az.svg',
            nationality: 'Azerbaijani, Azeri',
        },
        {
            id: 17,
            code: 'BA',
            name: 'Bosnia and Herzegovina',
            currency_code: 'BAM',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'BIH',
            call_code: 387,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ba.svg',
            nationality: 'Bosnian or Herzegovinian',
        },
        {
            id: 18,
            code: 'BB',
            name: 'Barbados',
            currency_code: 'BBD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'BRB',
            call_code: 1246,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bb.svg',
            nationality: 'Barbadian',
        },
        {
            id: 19,
            code: 'BD',
            name: 'Bangladesh',
            currency_code: 'BDT',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'BGD',
            call_code: 880,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bd.svg',
            nationality: 'Bangladeshi',
        },
        {
            id: 20,
            code: 'BE',
            name: 'Belgium',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'BEL',
            call_code: 32,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/be.svg',
            nationality: 'Belgian',
        },
        {
            id: 21,
            code: 'BF',
            name: 'Burkina Faso',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'BFA',
            call_code: 226,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bf.svg',
            nationality: 'Burkinabé',
        },
        {
            id: 22,
            code: 'BG',
            name: 'Bulgaria',
            currency_code: 'BGN',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'BGR',
            call_code: 359,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bg.svg',
            nationality: 'Bulgarian',
        },
        {
            id: 23,
            code: 'BH',
            name: 'Bahrain',
            currency_code: 'BHD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'BHR',
            call_code: 973,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bh.svg',
            nationality: 'Bahraini',
        },
        {
            id: 24,
            code: 'BI',
            name: 'Burundi',
            currency_code: 'BIF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'BDI',
            call_code: 257,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bi.svg',
            nationality: 'Burundian',
        },
        {
            id: 25,
            code: 'BJ',
            name: 'Benin',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'BEN',
            call_code: 229,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bj.svg',
            nationality: 'Beninese, Beninois',
        },
        {
            id: 26,
            code: 'BL',
            name: 'Saint Barthélemy',
            currency_code: 'EUR',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'BLM',
            call_code: 590,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bl.svg',
            nationality: 'Barthélemois',
        },
        {
            id: 27,
            code: 'BM',
            name: 'Bermuda',
            currency_code: 'BMD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'BMU',
            call_code: 1441,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bm.svg',
            nationality: 'Bermudian, Bermudan',
        },
        {
            id: 28,
            code: 'BN',
            name: 'Brunei',
            currency_code: 'BND',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'BRN',
            call_code: 673,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bn.svg',
            nationality: 'Bruneian',
        },
        {
            id: 29,
            code: 'BO',
            name: 'Bolivia',
            currency_code: 'BOB',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'BOL',
            call_code: 591,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bo.svg',
            nationality: 'Bolivian',
        },
        {
            id: 30,
            code: 'BQ',
            name: 'Bonaire',
            currency_code: 'USD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'BES',
            call_code: 599,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bq.svg',
            nationality: 'Bonaire',
        },
        {
            id: 31,
            code: 'BR',
            name: 'Brazil',
            currency_code: 'BRL',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'BRA',
            call_code: 55,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/br.svg',
            nationality: 'Brazilian',
        },
        {
            id: 32,
            code: 'BS',
            name: 'Bahamas',
            currency_code: 'BSD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'BHS',
            call_code: 1242,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bs.svg',
            nationality: 'Bahamian',
        },
        {
            id: 33,
            code: 'BT',
            name: 'Bhutan',
            currency_code: 'BTN',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'BTN',
            call_code: 975,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bt.svg',
            nationality: 'Bhutanese',
        },
        {
            id: 34,
            code: 'BV',
            name: 'Bouvet Island',
            currency_code: 'NOK',
            continent_name: 'Antarctica',
            continent: 'AN',
            iso_alpha_3: 'BVT',
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bv.svg',
            nationality: 'Bouvet Island',
        },
        {
            id: 35,
            code: 'BW',
            name: 'Botswana',
            currency_code: 'BWP',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'BWA',
            call_code: 267,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bw.svg',
            nationality: 'Motswana, Botswanan',
        },
        {
            id: 36,
            code: 'BY',
            name: 'Belarus',
            currency_code: 'BYR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'BLR',
            call_code: 375,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/by.svg',
            nationality: 'Belarusian',
        },
        {
            id: 37,
            code: 'BZ',
            name: 'Belize',
            currency_code: 'BZD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'BLZ',
            call_code: 501,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/bz.svg',
            nationality: 'Belizean',
        },
        {
            id: 38,
            code: 'CA',
            name: 'Canada',
            currency_code: 'CAD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'CAN',
            call_code: 1,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ca.svg',
            nationality: 'Canadian',
        },
        {
            id: 39,
            code: 'CC',
            name: 'Cocos [Keeling] Islands',
            currency_code: 'AUD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'CCK',
            call_code: 61,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cc.svg',
            nationality: 'Cocos Island',
        },
        {
            id: 40,
            code: 'CD',
            name: 'Democratic Republic of the Congo',
            currency_code: 'CDF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'COD',
            call_code: 243,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cd.svg',
            nationality: 'Congolese',
        },
        {
            id: 41,
            code: 'CF',
            name: 'Central African Republic',
            currency_code: 'XAF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'CAF',
            call_code: 236,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cf.svg',
            nationality: 'Central African',
        },
        {
            id: 42,
            code: 'CG',
            name: 'Republic of the Congo',
            currency_code: 'XAF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'COG',
            call_code: 242,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cg.svg',
            nationality: 'Congolese',
        },
        {
            id: 43,
            code: 'CH',
            name: 'Switzerland',
            currency_code: 'CHF',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'CHE',
            call_code: 41,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ch.svg',
            nationality: 'Swiss',
        },
        {
            id: 44,
            code: 'CI',
            name: 'Ivory Coast',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'CIV',
            call_code: 225,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ci.svg',
            nationality: 'Ivorian',
        },
        {
            id: 45,
            code: 'CK',
            name: 'Cook Islands',
            currency_code: 'NZD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'COK',
            call_code: 682,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ck.svg',
            nationality: 'Cook Island',
        },
        {
            id: 46,
            code: 'CL',
            name: 'Chile',
            currency_code: 'CLP',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'CHL',
            call_code: 56,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cl.svg',
            nationality: 'Chilean',
        },
        {
            id: 47,
            code: 'CM',
            name: 'Cameroon',
            currency_code: 'XAF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'CMR',
            call_code: 237,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cm.svg',
            nationality: 'Cameroonian',
        },
        {
            id: 48,
            code: 'CN',
            name: 'China',
            currency_code: 'CNY',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'CHN',
            call_code: 86,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cn.svg',
            nationality: 'Chinese',
        },
        {
            id: 49,
            code: 'CO',
            name: 'Colombia',
            currency_code: 'COP',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'COL',
            call_code: 57,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/co.svg',
            nationality: 'Colombian',
        },
        {
            id: 50,
            code: 'CR',
            name: 'Costa Rica',
            currency_code: 'CRC',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'CRI',
            call_code: 506,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cr.svg',
            nationality: 'Costa Rican',
        },
        {
            id: 51,
            code: 'CU',
            name: 'Cuba',
            currency_code: 'CUP',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'CUB',
            call_code: 53,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cu.svg',
            nationality: 'Cuban',
        },
        {
            id: 52,
            code: 'CV',
            name: 'Cape Verde',
            currency_code: 'CVE',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'CPV',
            call_code: 238,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cv.svg',
            nationality: 'Cabo Verdean',
        },
        {
            id: 53,
            code: 'CW',
            name: 'Curacao',
            currency_code: 'ANG',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'CUW',
            call_code: 599,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cw.svg',
            nationality: 'Curaçaoan',
        },
        {
            id: 54,
            code: 'CX',
            name: 'Christmas Island',
            currency_code: 'AUD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'CXR',
            call_code: 61,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cx.svg',
            nationality: 'Christmas Island',
        },
        {
            id: 55,
            code: 'CY',
            name: 'Cyprus',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'CYP',
            call_code: 357,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cy.svg',
            nationality: 'Cypriot',
        },
        {
            id: 56,
            code: 'CZ',
            name: 'Czech Republic',
            currency_code: 'CZK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'CZE',
            call_code: 420,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/cz.svg',
            nationality: 'Czech',
        },
        {
            id: 57,
            code: 'DE',
            name: 'Germany',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'DEU',
            call_code: 49,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/de.svg',
            nationality: 'German',
        },
        {
            id: 58,
            code: 'DJ',
            name: 'Djibouti',
            currency_code: 'DJF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'DJI',
            call_code: 253,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/dj.svg',
            nationality: 'Djiboutian',
        },
        {
            id: 59,
            code: 'DK',
            name: 'Denmark',
            currency_code: 'DKK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'DNK',
            call_code: 54,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/dk.svg',
            nationality: 'Danish',
        },
        {
            id: 60,
            code: 'DM',
            name: 'Dominica',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'DMA',
            call_code: 1767,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/dm.svg',
            nationality: 'Dominican',
        },
        {
            id: 61,
            code: 'DO',
            name: 'Dominican Republic',
            currency_code: 'DOP',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'DOM',
            call_code: 1,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/do.svg',
            nationality: 'Dominican',
        },
        {
            id: 62,
            code: 'DZ',
            name: 'Algeria',
            currency_code: 'DZD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'DZA',
            call_code: 213,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/dz.svg',
            nationality: 'Algerian',
        },
        {
            id: 63,
            code: 'EC',
            name: 'Ecuador',
            currency_code: 'USD',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'ECU',
            call_code: 593,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ec.svg',
            nationality: 'Ecuadorian',
        },
        {
            id: 64,
            code: 'EE',
            name: 'Estonia',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'EST',
            call_code: 372,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ee.svg',
            nationality: 'Estonian',
        },
        {
            id: 65,
            code: 'EG',
            name: 'Egypt',
            currency_code: 'EGP',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'EGY',
            call_code: 20,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/eg.svg',
            nationality: 'Egyptian',
        },
        {
            id: 66,
            code: 'EH',
            name: 'Western Sahara',
            currency_code: 'MAD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'ESH',
            call_code: 212,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/eh.svg',
            nationality: 'Sahrawi, Sahrawian, Sahraouian',
        },
        {
            id: 67,
            code: 'ER',
            name: 'Eritrea',
            currency_code: 'ERN',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'ERI',
            call_code: 291,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/er.svg',
            nationality: 'Eritrean',
        },
        {
            id: 68,
            code: 'ES',
            name: 'Spain',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'ESP',
            call_code: 34,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/es.svg',
            nationality: 'Spanish',
        },
        {
            id: 69,
            code: 'ET',
            name: 'Ethiopia',
            currency_code: 'ETB',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'ETH',
            call_code: 251,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/et.svg',
            nationality: 'Ethiopian',
        },
        {
            id: 70,
            code: 'FI',
            name: 'Finland',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'FIN',
            call_code: 358,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/fi.svg',
            nationality: 'Finnish',
        },
        {
            id: 71,
            code: 'FJ',
            name: 'Fiji',
            currency_code: 'FJD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'FJI',
            call_code: 679,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/fj.svg',
            nationality: 'Fijian',
        },
        {
            id: 72,
            code: 'FK',
            name: 'Falkland Islands',
            currency_code: 'FKP',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'FLK',
            call_code: 500,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/fk.svg',
            nationality: 'Falkland Island',
        },
        {
            id: 73,
            code: 'FM',
            name: 'Micronesia',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'FSM',
            call_code: 691,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/fm.svg',
            nationality: 'Micronesian',
        },
        {
            id: 74,
            code: 'FO',
            name: 'Faroe Islands',
            currency_code: 'DKK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'FRO',
            call_code: 298,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/fo.svg',
            nationality: 'Faroese',
        },
        {
            id: 75,
            code: 'FR',
            name: 'France',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'FRA',
            call_code: 33,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/fr.svg',
            nationality: 'French',
        },
        {
            id: 76,
            code: 'GA',
            name: 'Gabon',
            currency_code: 'XAF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'GAB',
            call_code: 241,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ga.svg',
            nationality: 'Gabonese',
        },
        {
            id: 77,
            code: 'GB',
            name: 'United Kingdom',
            currency_code: 'GBP',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'GBR',
            call_code: 44,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gb.svg',
            nationality: 'British, UK',
        },
        {
            id: 78,
            code: 'GD',
            name: 'Grenada',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'GRD',
            call_code: 1473,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gd.svg',
            nationality: 'Grenadian',
        },
        {
            id: 79,
            code: 'GE',
            name: 'Georgia',
            currency_code: 'GEL',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'GEO',
            call_code: 995,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ge.svg',
            nationality: 'Georgian',
        },
        {
            id: 80,
            code: 'GF',
            name: 'French Guiana',
            currency_code: 'EUR',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'GUF',
            call_code: 594,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gf.svg',
            nationality: 'French Guianese',
        },
        {
            id: 81,
            code: 'GG',
            name: 'Guernsey',
            currency_code: 'GBP',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'GGY',
            call_code: 44,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gg.svg',
            nationality: 'Channel Island',
        },
        {
            id: 82,
            code: 'GH',
            name: 'Ghana',
            currency_code: 'GHS',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'GHA',
            call_code: 233,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gh.svg',
            nationality: 'Ghanaian',
        },
        {
            id: 83,
            code: 'GI',
            name: 'Gibraltar',
            currency_code: 'GIP',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'GIB',
            call_code: 350,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gi.svg',
            nationality: 'Gibraltar',
        },
        {
            id: 84,
            code: 'GL',
            name: 'Greenland',
            currency_code: 'DKK',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'GRL',
            call_code: 299,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gl.svg',
            nationality: 'Greenlandic',
        },
        {
            id: 85,
            code: 'GM',
            name: 'Gambia',
            currency_code: 'GMD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'GMB',
            call_code: 220,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gm.svg',
            nationality: 'Gambian',
        },
        {
            id: 86,
            code: 'GN',
            name: 'Guinea',
            currency_code: 'GNF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'GIN',
            call_code: 224,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gn.svg',
            nationality: 'Guinean',
        },
        {
            id: 87,
            code: 'GP',
            name: 'Guadeloupe',
            currency_code: 'EUR',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'GLP',
            call_code: 590,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gp.svg',
            nationality: 'Guadeloupe',
        },
        {
            id: 88,
            code: 'GQ',
            name: 'Equatorial Guinea',
            currency_code: 'XAF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'GNQ',
            call_code: 240,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gq.svg',
            nationality: 'Equatorial Guinean, Equatoguinean',
        },
        {
            id: 89,
            code: 'GR',
            name: 'Greece',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'GRC',
            call_code: 30,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gr.svg',
            nationality: 'Greek, Hellenic',
        },
        {
            id: 90,
            code: 'GS',
            name: 'South Georgia and the South Sandwich Islands',
            currency_code: 'GBP',
            continent_name: 'Antarctica',
            continent: 'AN',
            iso_alpha_3: 'SGS',
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gs.svg',
            nationality: 'South Georgia or South Sandwich Islands',
        },
        {
            id: 91,
            code: 'GT',
            name: 'Guatemala',
            currency_code: 'GTQ',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'GTM',
            call_code: 502,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gt.svg',
            nationality: 'Guatemalan',
        },
        {
            id: 92,
            code: 'GU',
            name: 'Guam',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'GUM',
            call_code: 1671,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gu.svg',
            nationality: 'Guamanian, Guambat',
        },
        {
            id: 93,
            code: 'GW',
            name: 'Guinea-Bissau',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'GNB',
            call_code: 2245,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gw.svg',
            nationality: 'Bissau-Guinean',
        },
        {
            id: 94,
            code: 'GY',
            name: 'Guyana',
            currency_code: 'GYD',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'GUY',
            call_code: 592,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/gy.svg',
            nationality: 'Guyanese',
        },
        {
            id: 95,
            code: 'HK',
            name: 'Hong Kong',
            currency_code: 'HKD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'HKG',
            call_code: 852,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/hk.svg',
            nationality: 'Hong Kong, Hong Kongese',
        },
        {
            id: 96,
            code: 'HM',
            name: 'Heard Island and McDonald Islands',
            currency_code: 'AUD',
            continent_name: 'Antarctica',
            continent: 'AN',
            iso_alpha_3: 'HMD',
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/hm.svg',
            nationality: 'Heard Island or McDonald Islands',
        },
        {
            id: 97,
            code: 'HN',
            name: 'Honduras',
            currency_code: 'HNL',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'HND',
            call_code: 504,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/hn.svg',
            nationality: 'Honduran',
        },
        {
            id: 98,
            code: 'HR',
            name: 'Croatia',
            currency_code: 'HRK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'HRV',
            call_code: 385,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/hr.svg',
            nationality: 'Croatian',
        },
        {
            id: 99,
            code: 'HT',
            name: 'Haiti',
            currency_code: 'HTG',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'HTI',
            call_code: 509,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ht.svg',
            nationality: 'Haitian',
        },
        {
            id: 100,
            code: 'HU',
            name: 'Hungary',
            currency_code: 'HUF',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'HUN',
            call_code: 36,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/hu.svg',
            nationality: 'Hungarian, Magyar',
        },
        {
            id: 101,
            code: 'ID',
            name: 'Indonesia',
            currency_code: 'IDR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'IDN',
            call_code: 62,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/id.svg',
            nationality: 'Indonesian',
        },
        {
            id: 102,
            code: 'IE',
            name: 'Ireland',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'IRL',
            call_code: 353,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ie.svg',
            nationality: 'Irish',
        },
        {
            id: 103,
            code: 'IL',
            name: 'Israel',
            currency_code: 'ILS',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'ISR',
            call_code: 972,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/il.svg',
            nationality: 'Israeli',
        },
        {
            id: 104,
            code: 'IM',
            name: 'Isle of Man',
            currency_code: 'GBP',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'IMN',
            call_code: 44,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/im.svg',
            nationality: 'Manx',
        },
        {
            id: 105,
            code: 'IN',
            name: 'India',
            currency_code: 'INR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'IND',
            call_code: 91,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/in.svg',
            nationality: 'Indian',
        },
        {
            id: 106,
            code: 'IO',
            name: 'British Indian Ocean Territory',
            currency_code: 'USD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'IOT',
            call_code: 246,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/io.svg',
            nationality: 'BIOT',
        },
        {
            id: 107,
            code: 'IQ',
            name: 'Iraq',
            currency_code: 'IQD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'IRQ',
            call_code: 964,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/iq.svg',
            nationality: 'Iraqi',
        },
        {
            id: 108,
            code: 'IR',
            name: 'Iran',
            currency_code: 'IRR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'IRN',
            call_code: 98,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ir.svg',
            nationality: 'Iranian, Persian',
        },
        {
            id: 109,
            code: 'IS',
            name: 'Iceland',
            currency_code: 'ISK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'ISL',
            call_code: 354,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/is.svg',
            nationality: 'Icelandic',
        },
        {
            id: 110,
            code: 'IT',
            name: 'Italy',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'ITA',
            call_code: 39,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/it.svg',
            nationality: 'Italian',
        },
        {
            id: 111,
            code: 'JE',
            name: 'Jersey',
            currency_code: 'GBP',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'JEY',
            call_code: 44,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/je.svg',
            nationality: 'Channel Island',
        },
        {
            id: 112,
            code: 'JM',
            name: 'Jamaica',
            currency_code: 'JMD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'JAM',
            call_code: 1,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/jm.svg',
            nationality: 'Jamaican',
        },
        {
            id: 113,
            code: 'JO',
            name: 'Jordan',
            currency_code: 'JOD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'JOR',
            call_code: 962,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/jo.svg',
            nationality: 'Jordanian',
        },
        {
            id: 114,
            code: 'JP',
            name: 'Japan',
            currency_code: 'JPY',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'JPN',
            call_code: 81,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/jp.svg',
            nationality: 'Japanese',
        },
        {
            id: 115,
            code: 'KE',
            name: 'Kenya',
            currency_code: 'KES',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'KEN',
            call_code: 254,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ke.svg',
            nationality: 'Kenyan',
        },
        {
            id: 116,
            code: 'KG',
            name: 'Kyrgyzstan',
            currency_code: 'KGS',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'KGZ',
            call_code: 996,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/kg.svg',
            nationality: 'Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz',
        },
        {
            id: 117,
            code: 'KH',
            name: 'Cambodia',
            currency_code: 'KHR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'KHM',
            call_code: 855,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/kh.svg',
            nationality: 'Cambodian',
        },
        {
            id: 118,
            code: 'KI',
            name: 'Kiribati',
            currency_code: 'AUD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'KIR',
            call_code: 686,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ki.svg',
            nationality: 'I-Kiribati',
        },
        {
            id: 119,
            code: 'KM',
            name: 'Comoros',
            currency_code: 'KMF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'COM',
            call_code: 269,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/km.svg',
            nationality: 'Comoran, Comorian',
        },
        {
            id: 120,
            code: 'KN',
            name: 'Saint Kitts and Nevis',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'KNA',
            call_code: 1869,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/kn.svg',
            nationality: 'Kittitian or Nevisian',
        },
        {
            id: 121,
            code: 'KP',
            name: 'North Korea',
            currency_code: 'KPW',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'PRK',
            call_code: 850,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/kp.svg',
            nationality: 'North Korean',
        },
        {
            id: 122,
            code: 'KR',
            name: 'South Korea',
            currency_code: 'KRW',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'KOR',
            call_code: 82,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/kr.svg',
            nationality: 'South Korean',
        },
        {
            id: 123,
            code: 'KW',
            name: 'Kuwait',
            currency_code: 'KWD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'KWT',
            call_code: 965,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/kw.svg',
            nationality: 'Kuwaiti',
        },
        {
            id: 124,
            code: 'KY',
            name: 'Cayman Islands',
            currency_code: 'KYD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'CYM',
            call_code: 1345,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ky.svg',
            nationality: 'Caymanian',
        },
        {
            id: 125,
            code: 'KZ',
            name: 'Kazakhstan',
            currency_code: 'KZT',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'KAZ',
            call_code: 7,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/kz.svg',
            nationality: 'Kazakhstani, Kazakh',
        },
        {
            id: 126,
            code: 'LA',
            name: 'Laos',
            currency_code: 'LAK',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'LAO',
            call_code: 856,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/la.svg',
            nationality: 'Lao, Laotian',
        },
        {
            id: 127,
            code: 'LB',
            name: 'Lebanon',
            currency_code: 'LBP',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'LBN',
            call_code: 961,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/lb.svg',
            nationality: 'Lebanese',
        },
        {
            id: 128,
            code: 'LC',
            name: 'Saint Lucia',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'LCA',
            call_code: 1758,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/lc.svg',
            nationality: 'Saint Lucian',
        },
        {
            id: 129,
            code: 'LI',
            name: 'Liechtenstein',
            currency_code: 'CHF',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'LIE',
            call_code: 423,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/li.svg',
            nationality: 'Liechtenstein',
        },
        {
            id: 130,
            code: 'LK',
            name: 'Sri Lanka',
            currency_code: 'LKR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'LKA',
            call_code: 94,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/lk.svg',
            nationality: 'Sri Lankan',
        },
        {
            id: 131,
            code: 'LR',
            name: 'Liberia',
            currency_code: 'LRD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'LBR',
            call_code: 231,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/lr.svg',
            nationality: 'Liberian',
        },
        {
            id: 132,
            code: 'LS',
            name: 'Lesotho',
            currency_code: 'LSL',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'LSO',
            call_code: 266,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ls.svg',
            nationality: 'Basotho',
        },
        {
            id: 133,
            code: 'LT',
            name: 'Lithuania',
            currency_code: 'LTL',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'LTU',
            call_code: 370,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/lt.svg',
            nationality: 'Lithuanian',
        },
        {
            id: 134,
            code: 'LU',
            name: 'Luxembourg',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'LUX',
            call_code: 352,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/lu.svg',
            nationality: 'Luxembourg, Luxembourgish',
        },
        {
            id: 135,
            code: 'LV',
            name: 'Latvia',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'LVA',
            call_code: 371,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/lv.svg',
            nationality: 'Latvian',
        },
        {
            id: 136,
            code: 'LY',
            name: 'Libya',
            currency_code: 'LYD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'LBY',
            call_code: 218,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ly.svg',
            nationality: 'Libyan',
        },
        {
            id: 137,
            code: 'MA',
            name: 'Morocco',
            currency_code: 'MAD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MAR',
            call_code: 212,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ma.svg',
            nationality: 'Moroccan',
        },
        {
            id: 138,
            code: 'MC',
            name: 'Monaco',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'MCO',
            call_code: 377,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mc.svg',
            nationality: 'Monégasque, Monacan',
        },
        {
            id: 139,
            code: 'MD',
            name: 'Moldova',
            currency_code: 'MDL',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'MDA',
            call_code: 373,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/md.svg',
            nationality: 'Moldovan',
        },
        {
            id: 140,
            code: 'ME',
            name: 'Montenegro',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'MNE',
            call_code: 382,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/me.svg',
            nationality: 'Montenegrin',
        },
        {
            id: 141,
            code: 'MF',
            name: 'Saint Martin',
            currency_code: 'EUR',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'MAF',
            call_code: 590,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mf.svg',
            nationality: 'Saint-Martinoise',
        },
        {
            id: 142,
            code: 'MG',
            name: 'Madagascar',
            currency_code: 'MGA',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MDG',
            call_code: 261,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mg.svg',
            nationality: 'Malagasy',
        },
        {
            id: 143,
            code: 'MH',
            name: 'Marshall Islands',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'MHL',
            call_code: 692,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mh.svg',
            nationality: 'Marshallese',
        },
        {
            id: 144,
            code: 'MK',
            name: 'Macedonia',
            currency_code: 'MKD',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'MKD',
            call_code: 389,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mk.svg',
            nationality: 'Macedonian',
        },
        {
            id: 145,
            code: 'ML',
            name: 'Mali',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MLI',
            call_code: 223,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ml.svg',
            nationality: 'Malian, Malinese',
        },
        {
            id: 146,
            code: 'MM',
            name: 'Myanmar [Burma]',
            currency_code: 'MMK',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'MMR',
            call_code: 95,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mm.svg',
            nationality: 'Burmese',
        },
        {
            id: 147,
            code: 'MN',
            name: 'Mongolia',
            currency_code: 'MNT',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'MNG',
            call_code: 976,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mn.svg',
            nationality: 'Mongolian',
        },
        {
            id: 148,
            code: 'MO',
            name: 'Macao',
            currency_code: 'MOP',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'MAC',
            call_code: 853,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mo.svg',
            nationality: 'Macanese, Chinese',
        },
        {
            id: 149,
            code: 'MP',
            name: 'Northern Mariana Islands',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'MNP',
            call_code: 1670,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mp.svg',
            nationality: 'Northern Marianan',
        },
        {
            id: 150,
            code: 'MQ',
            name: 'Martinique',
            currency_code: 'EUR',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'MTQ',
            call_code: 596,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mq.svg',
            nationality: 'Martiniquais, Martinican',
        },
        {
            id: 151,
            code: 'MR',
            name: 'Mauritania',
            currency_code: 'MRO',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MRT',
            call_code: 222,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mr.svg',
            nationality: 'Mauritanian',
        },
        {
            id: 152,
            code: 'MS',
            name: 'Montserrat',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'MSR',
            call_code: 1664,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ms.svg',
            nationality: 'Montserratian',
        },
        {
            id: 153,
            code: 'MT',
            name: 'Malta',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'MLT',
            call_code: 356,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mt.svg',
            nationality: 'Maltese',
        },
        {
            id: 154,
            code: 'MU',
            name: 'Mauritius',
            currency_code: 'MUR',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MUS',
            call_code: 230,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mu.svg',
            nationality: 'Mauritian',
        },
        {
            id: 155,
            code: 'MV',
            name: 'Maldives',
            currency_code: 'MVR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'MDV',
            call_code: 960,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mv.svg',
            nationality: 'Maldivian',
        },
        {
            id: 156,
            code: 'MW',
            name: 'Malawi',
            currency_code: 'MWK',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MWI',
            call_code: 265,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mw.svg',
            nationality: 'Malawian',
        },
        {
            id: 157,
            code: 'MX',
            name: 'Mexico',
            currency_code: 'MXN',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'MEX',
            call_code: 52,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mx.svg',
            nationality: 'Mexican',
        },
        {
            id: 158,
            code: 'MY',
            name: 'Malaysia',
            currency_code: 'MYR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'MYS',
            call_code: 60,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/my.svg',
            nationality: 'Malaysian',
        },
        {
            id: 159,
            code: 'MZ',
            name: 'Mozambique',
            currency_code: 'MZN',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MOZ',
            call_code: 258,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/mz.svg',
            nationality: 'Mozambican',
        },
        {
            id: 160,
            code: 'NA',
            name: 'Namibia',
            currency_code: 'NAD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'NAM',
            call_code: 264,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/na.svg',
            nationality: 'Namibian',
        },
        {
            id: 161,
            code: 'NC',
            name: 'New Caledonia',
            currency_code: 'XPF',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'NCL',
            call_code: 687,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/nc.svg',
            nationality: 'New Caledonian',
        },
        {
            id: 162,
            code: 'NE',
            name: 'Niger',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'NER',
            call_code: 277,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ne.svg',
            nationality: 'Nigerien',
        },
        {
            id: 163,
            code: 'NF',
            name: 'Norfolk Island',
            currency_code: 'AUD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'NFK',
            call_code: 672,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/nf.svg',
            nationality: 'Norfolk Island',
        },
        {
            id: 164,
            code: 'NG',
            name: 'Nigeria',
            currency_code: 'NGN',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'NGA',
            call_code: 234,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ng.svg',
            nationality: 'Nigerian',
        },
        {
            id: 165,
            code: 'NI',
            name: 'Nicaragua',
            currency_code: 'NIO',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'NIC',
            call_code: 505,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ni.svg',
            nationality: 'Nicaraguan',
        },
        {
            id: 166,
            code: 'NL',
            name: 'Netherlands',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'NLD',
            call_code: 31,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/nl.svg',
            nationality: 'Dutch, Netherlandic',
        },
        {
            id: 167,
            code: 'NO',
            name: 'Norway',
            currency_code: 'NOK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'NOR',
            call_code: 47,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/no.svg',
            nationality: 'Norwegian',
        },
        {
            id: 168,
            code: 'NP',
            name: 'Nepal',
            currency_code: 'NPR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'NPL',
            call_code: 977,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/np.svg',
            nationality: 'Nepali, Nepalese',
        },
        {
            id: 169,
            code: 'NR',
            name: 'Nauru',
            currency_code: 'AUD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'NRU',
            call_code: 674,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/nr.svg',
            nationality: 'Nauruan',
        },
        {
            id: 170,
            code: 'NU',
            name: 'Niue',
            currency_code: 'NZD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'NIU',
            call_code: 683,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/nu.svg',
            nationality: 'Niuean',
        },
        {
            id: 171,
            code: 'NZ',
            name: 'New Zealand',
            currency_code: 'NZD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'NZL',
            call_code: 64,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/nz.svg',
            nationality: 'New Zealand, NZ',
        },
        {
            id: 172,
            code: 'OM',
            name: 'Oman',
            currency_code: 'OMR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'OMN',
            call_code: 968,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/om.svg',
            nationality: 'Omani',
        },
        {
            id: 173,
            code: 'PA',
            name: 'Panama',
            currency_code: 'PAB',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'PAN',
            call_code: 507,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pa.svg',
            nationality: 'Panamanian',
        },
        {
            id: 174,
            code: 'PE',
            name: 'Peru',
            currency_code: 'PEN',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'PER',
            call_code: 51,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pe.svg',
            nationality: 'Peruvian',
        },
        {
            id: 175,
            code: 'PF',
            name: 'French Polynesia',
            currency_code: 'XPF',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'PYF',
            call_code: 689,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pf.svg',
            nationality: 'French Polynesian',
        },
        {
            id: 176,
            code: 'PG',
            name: 'Papua New Guinea',
            currency_code: 'PGK',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'PNG',
            call_code: 675,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pg.svg',
            nationality: 'Papua New Guinean, Papuan',
        },
        {
            id: 177,
            code: 'PH',
            name: 'Philippines',
            currency_code: 'PHP',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'PHL',
            call_code: 63,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ph.svg',
            nationality: 'Philippine, Filipino',
        },
        {
            id: 178,
            code: 'PK',
            name: 'Pakistan',
            currency_code: 'PKR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'PAK',
            call_code: 92,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pk.svg',
            nationality: 'Pakistani',
        },
        {
            id: 179,
            code: 'PL',
            name: 'Poland',
            currency_code: 'PLN',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'POL',
            call_code: 48,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pl.svg',
            nationality: 'Polish',
        },
        {
            id: 180,
            code: 'PM',
            name: 'Saint Pierre and Miquelon',
            currency_code: 'EUR',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'SPM',
            call_code: 508,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pm.svg',
            nationality: 'Saint-Pierrais or Miquelonnais',
        },
        {
            id: 181,
            code: 'PN',
            name: 'Pitcairn Islands',
            currency_code: 'NZD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'PCN',
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pn.svg',
            nationality: 'Pitcairn Island',
        },
        {
            id: 182,
            code: 'PR',
            name: 'Puerto Rico',
            currency_code: 'USD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'PRI',
            call_code: 1,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pr.svg',
            nationality: 'Puerto Rican',
        },
        {
            id: 183,
            code: 'PS',
            name: 'Palestine',
            currency_code: 'ILS',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'PSE',
            call_code: 970,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ps.svg',
            nationality: 'Palestinian',
        },
        {
            id: 184,
            code: 'PT',
            name: 'Portugal',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'PRT',
            call_code: 351,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pt.svg',
            nationality: 'Portuguese',
        },
        {
            id: 185,
            code: 'PW',
            name: 'Palau',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'PLW',
            call_code: 680,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/pw.svg',
            nationality: 'Palauan',
        },
        {
            id: 186,
            code: 'PY',
            name: 'Paraguay',
            currency_code: 'PYG',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'PRY',
            call_code: 595,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/py.svg',
            nationality: 'Paraguayan',
        },
        {
            id: 187,
            code: 'QA',
            name: 'Qatar',
            currency_code: 'QAR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'QAT',
            call_code: 974,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/qa.svg',
            nationality: 'Qatari',
        },
        {
            id: 188,
            code: 'RE',
            name: 'Réunion',
            currency_code: 'EUR',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'REU',
            call_code: 262,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/re.svg',
            nationality: 'Réunionese, Réunionnais',
        },
        {
            id: 189,
            code: 'RO',
            name: 'Romania',
            currency_code: 'RON',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'ROU',
            call_code: 40,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ro.svg',
            nationality: 'Romanian',
        },
        {
            id: 190,
            code: 'RS',
            name: 'Serbia',
            currency_code: 'RSD',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'SRB',
            call_code: 381,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/rs.svg',
            nationality: 'Serbian',
        },
        {
            id: 191,
            code: 'RU',
            name: 'Russia',
            currency_code: 'RUB',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'RUS',
            call_code: 7,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ru.svg',
            nationality: 'Russian',
        },
        {
            id: 192,
            code: 'RW',
            name: 'Rwanda',
            currency_code: 'RWF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'RWA',
            call_code: 250,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/rw.svg',
            nationality: 'Rwandan',
        },
        {
            id: 193,
            code: 'SA',
            name: 'Saudi Arabia',
            currency_code: 'SAR',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'SAU',
            call_code: 966,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sa.svg',
            nationality: 'Saudi, Saudi Arabian',
        },
        {
            id: 194,
            code: 'SB',
            name: 'Solomon Islands',
            currency_code: 'SBD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'SLB',
            call_code: 677,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sb.svg',
            nationality: 'Solomon Island',
        },
        {
            id: 195,
            code: 'SC',
            name: 'Seychelles',
            currency_code: 'SCR',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SYC',
            call_code: 248,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sc.svg',
            nationality: 'Seychellois',
        },
        {
            id: 196,
            code: 'SD',
            name: 'Sudan',
            currency_code: 'SDG',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SDN',
            call_code: 249,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sd.svg',
            nationality: 'Sudanese',
        },
        {
            id: 197,
            code: 'SE',
            name: 'Sweden',
            currency_code: 'SEK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'SWE',
            call_code: 46,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/se.svg',
            nationality: 'Swedish',
        },
        {
            id: 198,
            code: 'SG',
            name: 'Singapore',
            currency_code: 'SGD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'SGP',
            call_code: 65,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sg.svg',
            nationality: 'Singaporean',
        },
        {
            id: 199,
            code: 'SH',
            name: 'Saint Helena',
            currency_code: 'SHP',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SHN',
            call_code: 290,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sh.svg',
            nationality: 'Saint Helenian',
        },
        {
            id: 200,
            code: 'SI',
            name: 'Slovenia',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'SVN',
            call_code: 386,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/si.svg',
            nationality: 'Slovenian, Slovene',
        },
        {
            id: 201,
            code: 'SJ',
            name: 'Svalbard and Jan Mayen',
            currency_code: 'NOK',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'SJM',
            call_code: 47,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sj.svg',
            nationality: 'Svalbard',
        },
        {
            id: 202,
            code: 'SK',
            name: 'Slovakia',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'SVK',
            call_code: 421,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sk.svg',
            nationality: 'Slovak',
        },
        {
            id: 203,
            code: 'SL',
            name: 'Sierra Leone',
            currency_code: 'SLL',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SLE',
            call_code: 232,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sl.svg',
            nationality: 'Sierra Leonean',
        },
        {
            id: 204,
            code: 'SM',
            name: 'San Marino',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'SMR',
            call_code: 378,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sm.svg',
            nationality: 'Sammarinese',
        },
        {
            id: 205,
            code: 'SN',
            name: 'Senegal',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SEN',
            call_code: 221,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sn.svg',
            nationality: 'Senegalese',
        },
        {
            id: 206,
            code: 'SO',
            name: 'Somalia',
            currency_code: 'SOS',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SOM',
            call_code: 252,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/so.svg',
            nationality: 'Somali, Somalian',
        },
        {
            id: 207,
            code: 'SR',
            name: 'Suriname',
            currency_code: 'SRD',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'SUR',
            call_code: 597,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sr.svg',
            nationality: 'Surinamese',
        },
        {
            id: 208,
            code: 'SS',
            name: 'South Sudan',
            currency_code: 'SSP',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SSD',
            call_code: 211,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ss.svg',
            nationality: 'South Sudanese',
        },
        {
            id: 209,
            code: 'ST',
            name: 'São Tomé and Príncipe',
            currency_code: 'STD',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'STP',
            call_code: 239,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/st.svg',
            nationality: 'São Toméan',
        },
        {
            id: 210,
            code: 'SV',
            name: 'El Salvador',
            currency_code: 'USD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'SLV',
            call_code: 503,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sv.svg',
            nationality: 'Salvadoran',
        },
        {
            id: 211,
            code: 'SX',
            name: 'Sint Maarten',
            currency_code: 'ANG',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'SXM',
            call_code: 1721,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sx.svg',
            nationality: 'Sint Maarten',
        },
        {
            id: 212,
            code: 'SY',
            name: 'Syria',
            currency_code: 'SYP',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'SYR',
            call_code: 963,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sy.svg',
            nationality: 'Syrian',
        },
        {
            id: 213,
            code: 'SZ',
            name: 'Swaziland',
            currency_code: 'SZL',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'SWZ',
            call_code: 268,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/sz.svg',
            nationality: 'Swazi',
        },
        {
            id: 214,
            code: 'TC',
            name: 'Turks and Caicos Islands',
            currency_code: 'USD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'TCA',
            call_code: 1649,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tc.svg',
            nationality: 'Turks and Caicos Island',
        },
        {
            id: 215,
            code: 'TD',
            name: 'Chad',
            currency_code: 'XAF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'TCD',
            call_code: 235,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/td.svg',
            nationality: 'Chadian',
        },
        {
            id: 216,
            code: 'TF',
            name: 'French Southern Territories',
            currency_code: 'EUR',
            continent_name: 'Antarctica',
            continent: 'AN',
            iso_alpha_3: 'ATF',
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tf.svg',
            nationality: 'French Southern Territories',
        },
        {
            id: 217,
            code: 'TG',
            name: 'Togo',
            currency_code: 'XOF',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'TGO',
            call_code: 228,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tg.svg',
            nationality: 'Togolese',
        },
        {
            id: 218,
            code: 'TH',
            name: 'Thailand',
            currency_code: 'THB',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'THA',
            call_code: 66,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/th.svg',
            nationality: 'Thai',
        },
        {
            id: 219,
            code: 'TJ',
            name: 'Tajikistan',
            currency_code: 'TJS',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'TJK',
            call_code: 992,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tj.svg',
            nationality: 'Tajikistani',
        },
        {
            id: 220,
            code: 'TK',
            name: 'Tokelau',
            currency_code: 'NZD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'TKL',
            call_code: 690,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tk.svg',
            nationality: 'Tokelauan',
        },
        {
            id: 221,
            code: 'TL',
            name: 'East Timor',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'TLS',
            call_code: 670,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tl.svg',
            nationality: 'Timorese',
        },
        {
            id: 222,
            code: 'TM',
            name: 'Turkmenistan',
            currency_code: 'TMT',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'TKM',
            call_code: 993,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tm.svg',
            nationality: 'Turkmen',
        },
        {
            id: 223,
            code: 'TN',
            name: 'Tunisia',
            currency_code: 'TND',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'TUN',
            call_code: 216,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tn.svg',
            nationality: 'Tunisian',
        },
        {
            id: 224,
            code: 'TO',
            name: 'Tonga',
            currency_code: 'TOP',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'TON',
            call_code: 676,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/to.svg',
            nationality: 'Tongan',
        },
        {
            id: 225,
            code: 'TR',
            name: 'Turkey',
            currency_code: 'TRY',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'TUR',
            call_code: 90,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tr.svg',
            nationality: 'Turkish',
        },
        {
            id: 226,
            code: 'TT',
            name: 'Trinidad and Tobago',
            currency_code: 'TTD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'TTO',
            call_code: 1868,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tt.svg',
            nationality: 'Trinidadian or Tobagonian',
        },
        {
            id: 227,
            code: 'TV',
            name: 'Tuvalu',
            currency_code: 'AUD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'TUV',
            call_code: 688,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tv.svg',
            nationality: 'Tuvaluan',
        },
        {
            id: 228,
            code: 'TW',
            name: 'Taiwan',
            currency_code: 'TWD',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'TWN',
            call_code: 886,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tw.svg',
            nationality: 'Chinese, Taiwanese',
        },
        {
            id: 229,
            code: 'TZ',
            name: 'Tanzania',
            currency_code: 'TZS',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'TZA',
            call_code: 255,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/tz.svg',
            nationality: 'Tanzanian',
        },
        {
            id: 230,
            code: 'UA',
            name: 'Ukraine',
            currency_code: 'UAH',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'UKR',
            call_code: 380,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ua.svg',
            nationality: 'Ukrainian',
        },
        {
            id: 231,
            code: 'UG',
            name: 'Uganda',
            currency_code: 'UGX',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'UGA',
            call_code: 256,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ug.svg',
            nationality: 'Ugandan',
        },
        {
            id: 232,
            code: 'UM',
            name: 'U.S. Minor Outlying Islands',
            currency_code: 'USD',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'UMI',
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/um.svg',
            nationality: 'American',
        },
        {
            id: 233,
            code: 'US',
            name: 'United States',
            currency_code: 'USD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'USA',
            call_code: 1,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/us.svg',
            nationality: 'American',
        },
        {
            id: 234,
            code: 'UY',
            name: 'Uruguay',
            currency_code: 'UYU',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'URY',
            call_code: 598,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/uy.svg',
            nationality: 'Uruguayan',
        },
        {
            id: 235,
            code: 'UZ',
            name: 'Uzbekistan',
            currency_code: 'UZS',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'UZB',
            call_code: 998,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/uz.svg',
            nationality: 'Uzbekistani, Uzbek',
        },
        {
            id: 236,
            code: 'VA',
            name: 'Vatican City',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'VAT',
            call_code: 39,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/va.svg',
            nationality: 'Vatican',
        },
        {
            id: 237,
            code: 'VC',
            name: 'Saint Vincent and the Grenadines',
            currency_code: 'XCD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'VCT',
            call_code: 1784,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/vc.svg',
            nationality: 'Saint Vincentian, Vincentian',
        },
        {
            id: 238,
            code: 'VE',
            name: 'Venezuela',
            currency_code: 'VEF',
            continent_name: 'South America',
            continent: 'SA',
            iso_alpha_3: 'VEN',
            call_code: 58,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ve.svg',
            nationality: 'Venezuelan',
        },
        {
            id: 239,
            code: 'VG',
            name: 'British Virgin Islands',
            currency_code: 'USD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'VGB',
            call_code: 1284,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/vg.svg',
            nationality: 'British Virgin Island',
        },
        {
            id: 240,
            code: 'VI',
            name: 'U.S. Virgin Islands',
            currency_code: 'USD',
            continent_name: 'North America',
            continent: 'NA',
            iso_alpha_3: 'VIR',
            call_code: 1340,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/vi.svg',
            nationality: 'U.S. Virgin Island',
        },
        {
            id: 241,
            code: 'VN',
            name: 'Vietnam',
            currency_code: 'VND',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'VNM',
            call_code: 84,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/vn.svg',
            nationality: 'Vietnamese',
        },
        {
            id: 242,
            code: 'VU',
            name: 'Vanuatu',
            currency_code: 'VUV',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'VUT',
            call_code: 678,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/vu.svg',
            nationality: 'Ni-Vanuatu, Vanuatuan',
        },
        {
            id: 243,
            code: 'WF',
            name: 'Wallis and Futuna',
            currency_code: 'XPF',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'WLF',
            call_code: 681,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/wf.svg',
            nationality: 'Wallis and Futuna, Wallisian or Futunan',
        },
        {
            id: 244,
            code: 'WS',
            name: 'Samoa',
            currency_code: 'WST',
            continent_name: 'Oceania',
            continent: 'OC',
            iso_alpha_3: 'WSM',
            call_code: 685,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ws.svg',
            nationality: 'Samoan',
        },
        {
            id: 245,
            code: 'XK',
            name: 'Kosovo',
            currency_code: 'EUR',
            continent_name: 'Europe',
            continent: 'EU',
            iso_alpha_3: 'XKX',
            call_code: 383,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/xk.svg',
            nationality: 'Kosovar'
        },
        {
            id: 246,
            code: 'YE',
            name: 'Yemen',
            currency_code: 'YER',
            continent_name: 'Asia',
            continent: 'AS',
            iso_alpha_3: 'YEM',
            call_code: 967,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/ye.svg',
            nationality: 'Yemeni',
        },
        {
            id: 247,
            code: 'YT',
            name: 'Mayotte',
            currency_code: 'EUR',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'MYT',
            call_code: 262,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/yt.svg',
            nationality: 'Mahoran',
        },
        {
            id: 248,
            code: 'ZA',
            name: 'South Africa',
            currency_code: 'ZAR',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'ZAF',
            call_code: 27,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/za.svg',
            nationality: 'South African',
        },
        {
            id: 249,
            code: 'ZM',
            name: 'Zambia',
            currency_code: 'ZMW',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'ZMB',
            call_code: 260,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/zm.svg',
            nationality: 'Zambian',
        },
        {
            id: 250,
            code: 'ZW',
            name: 'Zimbabwe',
            currency_code: 'ZWL',
            continent_name: 'Africa',
            continent: 'AF',
            iso_alpha_3: 'ZWE',
            call_code: 263,
            flag_url: 'https://hatscripts.github.io/circle-flags/flags/zw.svg',
            nationality: 'Zimbabwean',
        },
    ];

    static readonly usSates: Array<USState> = [
        {
            code: 'AL',
            name: 'Alabama',
        },
        {
            code: 'AK',
            name: 'Alaska',
        },
        {
            code: 'AS',
            name: 'American Samoa',
        },
        {
            code: 'AZ',
            name: 'Arizona',
        },
        {
            code: 'AR',
            name: 'Arkansas',
        },
        {
            code: 'CA',
            name: 'California',
        },
        {
            code: 'CO',
            name: 'Colorado',
        },
        {
            code: 'CT',
            name: 'Connecticut',
        },
        {
            code: 'DE',
            name: 'Delaware',
        },
        {
            code: 'DC',
            name: 'District Of Columbia',
        },
        {
            code: 'FM',
            name: 'Federated States Of Micronesia',
        },
        {
            code: 'FL',
            name: 'Florida',
        },
        {
            code: 'GA',
            name: 'Georgia',
        },
        {
            code: 'GU',
            name: 'Guam',
        },
        {
            code: 'HI',
            name: 'Hawaii',
        },
        {
            code: 'ID',
            name: 'Idaho',
        },
        {
            code: 'IL',
            name: 'Illinois',
        },
        {
            code: 'IN',
            name: 'Indiana',
        },
        {
            code: 'IA',
            name: 'Iowa',
        },
        {
            code: 'KS',
            name: 'Kansas',
        },
        {
            code: 'KY',
            name: 'Kentucky',
        },
        {
            code: 'LA',
            name: 'Louisiana',
        },
        {
            code: 'ME',
            name: 'Maine',
        },
        {
            code: 'MH',
            name: 'Marshall Islands',
        },
        {
            code: 'MD',
            name: 'Maryland',
        },
        {
            code: 'MA',
            name: 'Massachusetts',
        },
        {
            code: 'MI',
            name: 'Michigan',
        },
        {
            code: 'MN',
            name: 'Minnesota',
        },
        {
            code: 'MS',
            name: 'Mississippi',
        },
        {
            code: 'MO',
            name: 'Missouri',
        },
        {
            code: 'MT',
            name: 'Montana',
        },
        {
            code: 'NE',
            name: 'Nebraska',
        },
        {
            code: 'NV',
            name: 'Nevada',
        },
        {
            code: 'NH',
            name: 'New Hampshire',
        },
        {
            code: 'NJ',
            name: 'New Jersey',
        },
        {
            code: 'NM',
            name: 'New Mexico',
        },
        {
            code: 'NY',
            name: 'New York',
        },
        {
            code: 'NC',
            name: 'North Carolina',
        },
        {
            code: 'ND',
            name: 'North Dakota',
        },
        {
            code: 'MP',
            name: 'Northern Mariana Islands',
        },
        {
            code: 'OH',
            name: 'Ohio',
        },
        {
            code: 'OK',
            name: 'Oklahoma',
        },
        {
            code: 'OR',
            name: 'Oregon',
        },
        {
            code: 'PW',
            name: 'Palau',
        },
        {
            code: 'PA',
            name: 'Pennsylvania',
        },
        {
            code: 'PR',
            name: 'Puerto Rico',
        },
        {
            code: 'RI',
            name: 'Rhode Island',
        },
        {
            code: 'SC',
            name: 'South Carolina',
        },
        {
            code: 'SD',
            name: 'South Dakota',
        },
        {
            code: 'TN',
            name: 'Tennessee',
        },
        {
            code: 'TX',
            name: 'Texas',
        },
        {
            code: 'UT',
            name: 'Utah',
        },
        {
            code: 'VT',
            name: 'Vermont',
        },
        {
            code: 'VI',
            name: 'Virgin Islands',
        },
        {
            code: 'VA',
            name: 'Virginia',
        },
        {
            code: 'WA',
            name: 'Washington',
        },
        {
            code: 'WV',
            name: 'West Virginia',
        },
        {
            code: 'WI',
            name: 'Wisconsin',
        },
        {
            code: 'WY',
            name: 'Wyoming',
        },
    ];

    static readonly countriesRequiringStates: Array<Record<string, number | string>> = [
		{
			id: 13,
			code: 'AU',
			name: 'Australia',
		},
		{
			id: 233,
			code: 'US',
			name: 'United States',
		},
	];

    static readonly industryTypes: Array<Descriptor> = [
        {code: 'exchange', description: 'Exchange'},
        {code: 'otc_desk', description: 'OTC Desk'},
        {code: 'asset_manager', description: 'Asset Manager'},
        {code: 'prop_trader', description: 'Prop Trader'},
        {code: 'otc_trading', description: 'OTC Trading'},
        {code: 'crypto_custodian', description: 'Crypto Custodian'},
        {code: 'crypto_fund', description: 'Crypto Fund'},
        {code: 'technology', description: 'Technology'},
        {code: 'consultant', description: 'Consultant'}
    ];

    static readonly genders: Array<Descriptor> = [
        {code: 'male', description: 'Male'},
        {code: 'female', description: 'Female'}
    ]

    static readonly sourceOfFundsOptions: Array<Descriptor> = [
        {
            code: 'Source of funds: Saving from salary (Basic and/or/ bonus)',
            description: 'Saving from salary(Basic and / or / bonus)'
        },
        {
            code: 'Source of funds: Sales of shares or investments / liquidation or investment portfolio',
            description: ' Sales of shares or investments / liquidation or investment portfolio'
        }, {
            code: 'Source of funds: Sale of property',
            description: 'Sale of property'
        }, {
            code: 'Source of funds: Inheritance',
            description: 'Inheritance'
        }
    ]

    static readonly cryptoCurrencies: Array<string> = [
        'ADA',
        'ALGO',
        'AMS',
        'ARDR',
        'ARK',
        'BAY',
        'BCH',
        'BTC',
        'ENJ',
        'EOS',
        'ETC',
        'ETH',
        'FUN',
        'GAME',
        'HVN',
        'IOTA',
        'KMD',
        'LOOM',
        'LSK',
        'LTC',
        'MAID',
        'MIOTA',
        'NANO',
        'NEO',
        'NXT',
        'OMG',
        'OST',
        'PPT',
        'PURE',
        'RADS',
        'REN',
        'REQ',
        'RUPX',
        'SC',
        'SPANK',
        'SPSL',
        'STEEM',
        'STRAT',
        'SUR',
        'SYS',
        'TRX',
        'USDT',
        'VRC',
        'WAX',
        'XEL',
        'XEM',
        'XLM',
        'XMR',
        'XRP',
        'XVG',
        'ZEC',
        'ZIL'
    ];

    static readonly ReviewIgnoredSteps: Array<string> = ['terms', 'welcome']
}
