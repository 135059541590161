<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">BCB Group onboarding</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
        <p>Welcome to the BCB Group's onboarding area. Interested in opening an account? Please send your details to <a
                href="mailto:info@bcbgroup.io">info@bcbgroup.io</a></p>
    </div>
</div>
