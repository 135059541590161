import {
	Directive,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { MobileNumberPipe } from '../../pipes/mobile-number/mobile-number.pipe';
import { PhoneNumber } from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { Country } from '../../models/country.interface';
import { StaticData } from '../../constants/static.const';

@Directive({
	selector: '[bcbMobileInput]',
})
export class MobileInputDirective implements OnInit, OnDestroy {
	@Input() displayFormat: 'NATIONAL' | 'INTERNATIONAL' = 'INTERNATIONAL';
	@Output() errorMessageChange: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() mobileNumberChange: EventEmitter<string> =
		new EventEmitter<string>();
	@Output() mobileNumberObjectChange: EventEmitter<PhoneNumber> =
		new EventEmitter<PhoneNumber>();
	@Output() selectedCountryChange: EventEmitter<Country> =
		new EventEmitter<Country>();

	private readonly el: HTMLInputElement;
	private readonly unsubscribe$: Subject<void> = new Subject<void>();
	private readonly phoneNumber$: Subject<PhoneNumber> =
		new Subject<PhoneNumber>();
	private readonly selectedCountry$: Subject<Country> =
		new Subject<Country>();

	constructor(
		private elementRef: ElementRef,
		private readonly mobileNumberPipe: MobileNumberPipe
	) {
		this.el = this.elementRef.nativeElement;
		this.phoneNumber$.next(undefined);
		this.selectedCountry$.next(undefined);
	}

	ngOnInit(): void {
		this.setupSubscriptions();

		this.phoneNumber$.next(this.getPhoneNumberObject(this.el.value));
		this.displayNational(this.el.value);
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
	}

	@HostListener('change', ['$event.target.value'])
	@HostListener('input', ['$event.target.value'])
	onChange(value: string): void {
		const _phoneNumber = this.getPhoneNumberObject(value);
		if (_phoneNumber) {
			this.phoneNumber$.next(_phoneNumber);
		}
	}

	@HostListener('blur', ['$event.target.value'])
	onBlur(value: string): void {
		this.emitChange(value);
		this.displayNational(value);
	}

	@HostListener('focus', ['$event.target.value'])
	onFocus(value: string): void {
		this.displayInternational(value);
	}

	private setupSubscriptions(): void {
		// Track when phone number value changes
		this.phoneNumber$
			.pipe(
				takeUntil(this.unsubscribe$),
				tap((phoneNumber: PhoneNumber) =>
					this.mobileNumberObjectChange.emit(phoneNumber)
				)
			)
			.subscribe();

		// Track when phone number country changes
		this.phoneNumber$
			.pipe(
				takeUntil(this.unsubscribe$),
				tap((phoneNumber: PhoneNumber) => {
					if (phoneNumber && phoneNumber.countryCallingCode) {
						if (phoneNumber.country) {
							this.selectedCountry$.next(
								this.getCountryByCallCode(phoneNumber.country) // Ex: 'GB'
							);
						} else if (phoneNumber.countryCallingCode) {
							this.selectedCountry$.next(
								this.getCountryByCallCode(
									Number(phoneNumber.countryCallingCode) // Ex: 44
								)
							);
						}
					}
				})
			)
			.subscribe();

		this.selectedCountry$
			.pipe(
				takeUntil(this.unsubscribe$),
				tap((country: Country) =>
					this.selectedCountryChange.emit(country)
				)
			)
			.subscribe();
	}

	private displayNational(value: string): void {
		if (!this.shouldProcess(value)) {
			return;
		}

		this.el.value = this.mobileNumberPipe.parse(
			value,
			undefined,
			'INTERNATIONAL'
		) as unknown as string;
	}

	private displayInternational(value: string): void {
		if (!this.shouldProcess(value)) {
			return;
		}

		this.el.value = this.mobileNumberPipe.transform(
			value,
			undefined,
			'INTERNATIONAL'
		) as unknown as string;
	}

	private emitChange(value: string): void {
		if (!this.shouldProcess(value)) {
			this.mobileNumberChange.emit(undefined);
		}
		this.mobileNumberChange.emit(
			this.mobileNumberPipe.transform(
				value,
				undefined,
				'INTERNATIONAL'
			) as unknown as string
		);
	}

	private shouldProcess(value: string): boolean {
		return !(!value || value.length < 1);
	}

	private getCountryByCallCode(code: number | string): Country {
		let found: Country;
		if (typeof code === 'string') {
			found = StaticData.countries.find(
				(country) => country.code === code
			) as Country;
		} else {
			found = StaticData.countries.find(
				(country) => country.call_code === code
			) as Country;
		}

		return found;
	}

	private getPhoneNumberObject(value: string): PhoneNumber | undefined {
		try {
			return this.mobileNumberPipe.phoneNumber(value) as PhoneNumber;
		} catch (e) {
			this.errorMessageChange.emit(e.message);
			return;
		}
	}
}
