import { Pipe, PipeTransform } from '@angular/core';
import {
	CountryCode,
	formatIncompletePhoneNumber,
	parsePhoneNumberWithError,
	PhoneNumber,
} from 'libphonenumber-js';

@Pipe({ name: 'mobile', pure: true })
export class MobileNumberPipe implements PipeTransform {
	transform(
		value: string,
		countryCode?: CountryCode,
		responseType?: 'NATIONAL' | 'INTERNATIONAL'
	) {
		try {
			switch (responseType) {
				case 'NATIONAL':
					return parsePhoneNumberWithError(value, countryCode)
						.nationalNumber;
				case 'INTERNATIONAL':
					return parsePhoneNumberWithError(value, countryCode).number;
				default:
					return parsePhoneNumberWithError(value, countryCode);
			}
		} catch (e) {
			return value;
		}
	}

	parse(
		value: string,
		countryCode?: CountryCode,
		responseType?: 'NATIONAL' | 'INTERNATIONAL'
	): string {
		try {
			const _phoneNumber: PhoneNumber = parsePhoneNumberWithError(
				value,
				countryCode
			);
			switch (responseType) {
				case 'NATIONAL':
					return parsePhoneNumberWithError(
						value,
						countryCode
					).formatNational() as string;
				case 'INTERNATIONAL':
					return parsePhoneNumberWithError(
						value,
						countryCode
					).formatInternational() as string;
				default:
					return formatIncompletePhoneNumber(value, countryCode);
			}
		} catch (e) {
			return value;
		}
	}

	phoneNumber(
		value: string,
		countryCode?: CountryCode
	): PhoneNumber | undefined {
		return parsePhoneNumberWithError(value, countryCode);
	}
}
