import { StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnboardingStateModel } from './onboarding.state';
import { UpdateOnboarding } from './onboarding.actions';

@Injectable()
export class OnboardingQueries {
	constructor(private readonly store: Store) {}

	getState(): Observable<OnboardingStateModel> {
		return this.store.select(
			new StateToken<OnboardingStateModel>('onboarding')
		);
	}

	updateState(
		payload: OnboardingStateModel
	): void {
		this.store.dispatch(new UpdateOnboarding(payload));
	}
}
