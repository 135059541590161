import { Action, NgxsOnInit, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
    GenerateConfig,
    Navigate,
    NavigateToStep,
    UpdateConfig,
    UpdateStep,
    UpdateStepMetadata
} from './stepper.actions';
import { first, tap } from "rxjs/operators";
import { StepperConfigModel } from '../../../modules/stepper/models/stepper-config.model';
import { OnboardingStateModel } from '../onboarding/onboarding.state';
import { StepModel } from '../../../modules/stepper/models/step.model';
import { StepperConfig } from './stepper-config';

const initialState: StepperConfigModel = {
    currentStep: '',
    steps: []
};

@State<StepperConfigModel>({
    name: 'stepper',
    defaults: { ...initialState }
})
@Injectable()
export class StepperState implements NgxsOnInit {
    constructor(private readonly store: Store) {
    }

    ngxsOnInit(ctx?: StateContext<any>) {
        ctx?.dispatch(GenerateConfig).subscribe();
    }

    // -------------------------- Actions --------------------------

    @Action(GenerateConfig)
    GenerateConfig(ctx: StateContext<StepperConfigModel>, { payload, withNavigation }: GenerateConfig): void {
        const newConfig = (data: OnboardingStateModel) => {
            const stepperConfig = new StepperConfig(data)
            if (data?.steps) {
                const config: StepperConfigModel =
                  data.onboarding_type === "corporate"
                    ? stepperConfig.getCorporateStepper()
                    : stepperConfig.getIndividualStepper();

                config.steps.push(...stepperConfig.getDefaultStep())

                config.currentStep = ctx.getState().currentStep;
                config.parentStepKey = ctx.getState().parentStepKey;

                if (withNavigation) {
                    config.currentStep = data.active_step ?? '';

                    if (data.active_step === 'due_diligence_form' && data.due_diligence_form) {
                        config.currentStep =
                          data.due_diligence_form.active_step ?? '';
                        config.parentStepKey = data.active_step;
                    }
                }

                ctx.dispatch(new UpdateConfig(config));
            }
        };

        if (payload) {
            newConfig(payload);
        } else {
            this.store
              .select(new StateToken<OnboardingStateModel>("onboarding"))
              .pipe(
                first((data: OnboardingStateModel) => !!data),
                tap(data => newConfig(data))
              )
              .subscribe();
        }
    }

    @Action(UpdateConfig)
    UpdateConfig(
        { patchState }: StateContext<StepperConfigModel>,
        { payload }: UpdateConfig
    ): void {
        patchState(payload);
    }

    @Action(UpdateStep)
    UpdateStep(
        { patchState, getState }: StateContext<StepperConfigModel>,
        { payload }: UpdateStep
    ): void {
        const state: StepperConfigModel = getState();

        const { parentIndex, stepIndex } = this.getStepIndex(
            state.steps,
            payload.step.key,
            payload.parentStepKey
        );

        if (parentIndex > -1) {
            // @ts-ignore
            state.steps[parentIndex].subSteps[stepIndex] = payload.step;
        } else {
            state.steps[stepIndex] = payload.step;
        }

        patchState(state);
    }

    @Action(UpdateStepMetadata)
    UpdateStepMetadata(
        { patchState, getState }: StateContext<StepperConfigModel>,
        { payload }: UpdateStepMetadata
    ): void {
        const state: StepperConfigModel = getState();

        const { parentIndex, stepIndex } = this.getStepIndex(
            state.steps,
            payload.stepKey,
            payload.parentStepKey
        );

        if (parentIndex > -1) {
            // @ts-ignore
            state.steps[parentIndex].subSteps[stepIndex] = {
                // @ts-ignore
                ...state.steps[parentIndex].subSteps[stepIndex],
                metadata: { ...payload.metadata }
            };
        } else {
            state.steps[stepIndex] = {
                ...state.steps[stepIndex],
                metadata: { ...payload.metadata }
            };
        }

        patchState(state);
    }

    @Action(Navigate)
    navigate(
        { patchState, getState }: StateContext<StepperConfigModel>,
        { payload }: Navigate
    ): void {
        // only work with visible steps
        const steps = getState()
            .steps.filter((step: StepModel) => step.show)
            .map((item: StepModel) => {
                if (item.subSteps) {
                    return {
                        ...item,
                        subSteps: item.subSteps.filter(
                            (subStep: StepModel) => subStep.show
                        )
                    };
                } else {
                    return item;
                }
            });
        const state: StepperConfigModel = { ...getState(), steps };

        let parentIndex: number = -1;
        let stepIndex: number = -1;
        let maxSubIndex: number = -1;

        let activeStepKey = '';
        let parentStepKey = undefined;

        // get index of current key
        stepIndex = state.steps.findIndex(
            (step: StepModel) => step.key === state.currentStep
        );

        // if stepIndex is null look for a subStep
        if (stepIndex < 0) {
            state.steps
                .filter((step: StepModel) => step.subSteps)
                .some((step: StepModel) => {
                    const index =
                        step.subSteps?.findIndex(
                            (subStep: StepModel) =>
                                subStep.key === state.currentStep
                        ) ?? -1;

                    if (index > -1) {
                        parentIndex = state.steps.findIndex(
                            (parentStep: StepModel) =>
                                parentStep.key === step.key
                        );
                        stepIndex = index;

                        maxSubIndex =
                            // @ts-ignore
                            state.steps[parentIndex].subSteps.length - 1; // this will always be defined in this case

                        return true;
                    }

                    return false;
                });
        }

        // set the key for the given indexes
        if (payload.direction === 'next') {
            if (parentIndex > -1 && stepIndex === maxSubIndex) {
                // Sub steps to a regular step
                activeStepKey = state.steps[parentIndex + 1].key;
                parentStepKey = undefined;
            } else if (parentIndex > -1) {
                // Sub step to sub step
                activeStepKey =
                    // @ts-ignore
                    state.steps[parentIndex].subSteps[stepIndex + 1].key;
                parentStepKey = state.steps[parentIndex].key;
            } else if (state.steps[stepIndex + 1].subSteps) {
                // Regular step to sub step
                parentStepKey = state.steps[stepIndex + 1].key;
                // @ts-ignore
                activeStepKey = state.steps[stepIndex + 1].subSteps[0].key;
            } else {
                // Regular step to regular step
                activeStepKey = state.steps[stepIndex + 1].key;
            }
        } else if (payload.direction === 'prev') {
            if (parentIndex > -1 && stepIndex === 0) {
                // Sub steps to a regular step
                activeStepKey = state.steps[parentIndex - 1].key;
                parentStepKey = undefined;
            } else if (parentIndex > -1) {
                // Sub step to sub step
                activeStepKey =
                    // @ts-ignore
                    state.steps[parentIndex].subSteps[stepIndex - 1].key;
                parentStepKey = state.steps[parentIndex].key;
            } else if (state.steps[stepIndex - 1].subSteps) {
                // Regular step to sub step
                parentStepKey = state.steps[stepIndex - 1].key;
                const lastIndex =
                    // @ts-ignore
                    state.steps[stepIndex - 1].subSteps.length - 1;
                activeStepKey =
                    // @ts-ignore
                    state.steps[stepIndex - 1].subSteps[lastIndex].key;
            } else {
                // Regular step to regular step
                activeStepKey = state.steps[stepIndex - 1].key;
            }
        }

        patchState({
            ...state,
            currentStep: activeStepKey,
            parentStepKey: parentStepKey
        });
    }

    @Action(NavigateToStep)
    navigateToStep(
        { patchState, getState }: StateContext<StepperConfigModel>,
        { payload }: NavigateToStep
    ): void {
        const state: StepperConfigModel = getState();

        patchState({
            ...state,
            currentStep: payload.stepKey,
            parentStepKey: payload.parentStepKey
        });
    }

    // -------------------------- Selectors --------------------------
    @Selector()
    getState(state: StepperConfigModel): StepperConfigModel {
        return state;
    }

    private getStepIndex(
        steps: Array<StepModel>,
        stepKey: string,
        parentStepKey?: string
    ): { parentIndex: number; stepIndex: number } {
        const parentIndex: number = parentStepKey
            ? steps.findIndex((step) => step.key === parentStepKey)
            : -1;
        const stepIndex: number =
            parentIndex > -1
                ? steps[parentIndex].subSteps?.findIndex(
                (step) => step.key === stepKey
            ) ?? -1
                : steps.findIndex((step) => step.key === stepKey);

        return { parentIndex, stepIndex };
    }
}
