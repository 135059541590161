import { EntityStateModel } from './entity.state';

export class LoadEntity {
	static readonly type = '[entity] load entity';
}

export class ReloadEntity {
	static readonly type = '[entity] reload entity';
	constructor(public payload: boolean) {
	}
}

export class LoadSubEntities {
	static readonly type = '[entity] load all sub entities';
}

export class UpdateEntity {
	static readonly type = '[entity] update entity';

	constructor(public payload: EntityStateModel) {}
}

export class ClearEntity {
	static readonly type = '[entity] clear entity state';

	constructor() {}
}
