import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperComponent } from './stepper.component';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { StoreModule } from '../../core/store/store.module';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    StepperComponent
  ],
  exports: [
    StepperComponent
  ],
    imports: [
        CommonModule,

        MatIconModule,
        MatListModule,
        MatExpansionModule,
        StoreModule,
        MatTooltipModule
    ]
})
export class StepperModule {
}
