<h3>Legal persons for {{shareholder?.entity_name}}</h3>
<section>
    <table mat-table [dataSource]="linkedDirectors" *ngIf="!addEditDirector">

        <ng-container matColumnDef="shares">
            <th mat-header-cell *matHeaderCellDef> Shares</th>
            <td mat-cell *matCellDef="let element">
                <div class="shares">{{element.profile?.shareholder ? element.profile?.shares + '%' : 'N/A'}}</div>
            </td>
        </ng-container>

        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name</th>
            <td mat-cell *matCellDef="let element"> {{element.first_name}} {{element.last_name}}</td>
        </ng-container>

        <ng-container matColumnDef="types">
            <th mat-header-cell *matHeaderCellDef> Types</th>
            <td mat-cell *matCellDef="let element"> {{legalEntityTypes.get(element.id)?.label}} </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef> Actions</th>
            <td mat-cell *matCellDef='let element' [class.markedForDelete]="markedForDelete.has(element)" [style.text-align]='disabled ? "end" : "inherit"'>
                <ng-container *ngIf="markedForDelete.has(element)">
                    <span>You are about to delete <b>{{element.first_name}} {{element.last_name}}</b>. Please confirm this is correct.</span>
                    <span>
                            <button *ngIf='!disabled' mat-icon-button title="Confirm" (click)='onRemoveDirector(element)'>
                                <mat-icon>done</mat-icon>
                            </button>
                            <button *ngIf='!disabled' mat-icon-button title="Cancel" (click)='handleDeleteClick(element, true)'>
                                <mat-icon>close</mat-icon>
                            </button>
                        </span>
                </ng-container>
                <ng-container *ngIf="!markedForDelete.has(element)">
                    <button *ngIf='disabled' mat-icon-button (click)='onEditDirector(element)'>
                        <mat-icon>visibility</mat-icon>
                    </button>
                    <button *ngIf='!disabled' title="Edit" mat-icon-button (click)='onEditDirector(element)'>
                        <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf='!disabled' title="Delete" mat-icon-button (click)='handleDeleteClick(element)'>
                        <mat-icon>delete</mat-icon>
                    </button>
                </ng-container>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No legal persons have been added</td>
        </tr>
    </table>
    <div class="d-flex align-items-center" *ngIf="directorsBeingAdded > 0">
        <mat-spinner diameter="24" class="me-2"></mat-spinner>
        Saving director...
    </div>
</section>

<section *ngIf="addEditDirector" class="form-section mt-2">
    <bcb-directors-information-content [data]="data" (formValid)="canSubmit = $event" (submit)="onSubmitChanged($event)"></bcb-directors-information-content>
    <div class="d-flex align-items-center justify-content-end">
        <button mat-stroked-button class="bcb-btn btn-small danger me-2" (click)="cancel()">Cancel</button>
        <button type="submit" mat-flat-button color="primary" *ngIf="!disabled" [disabled]="!$systemUser && !canSubmit" (click)="triggerSubmit()">Save</button>
    </div>
</section>
<div class="d-flex align-items-center justify-content-end mt-2" *ngIf="!addEditDirector">
    <button mat-flat-button color="primary" *ngIf='!disabled' (click)="onAddNewClick()">Add Legal Person
    </button>
</div>


