import { Component, OnDestroy } from '@angular/core';
import { StepperQueries } from '../../store/stepper/stepper.queries';
import { EntityQueries } from '../../store/entity/entity.queries';
import { ConfigQueries } from '../../store/config/config.queries';
import { OnboardingQueries } from '../../store/onboarding/onboarding.queries';
import { HttpService } from '../../services/http/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { REVIEW_STATUS } from '../../constants/enum.const';
import { StepModel } from '../../../modules/stepper/models/step.model';
import { Entity } from '../../models/entity.model';
import { EntityUtils } from '../../utils/entity.util';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ConfigQuestion } from '../../models/config-question.interface';
import { ConfigUtil } from '../../utils/config.util';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { catchError, finalize, first, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OnboardingStateModel } from '../../store/onboarding/onboarding.state';
import { OnboardingSection } from '../../models/onboarding.interface';
import { StaticData } from '../../constants/static.const';

@Component({
  selector: 'bcb-component-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss']
})
export class BasePageComponent implements OnDestroy {
  // configQueries = inject(ConfigQueries)
  // entityQueries = inject(EntityQueries)
  // http = inject(HttpService)
  // onboardingQueries = inject(OnboardingQueries)
  // stepperQueries = inject(StepperQueries)
  // _snackBar = inject(MatSnackBar)

  overrideStep?: StepModel;
  componentLayoutClassList: string[] = ['col-md-6'];
  configQuestions!: Record<string, Array<ConfigQuestion>>;
  currentStep!: StepModel;
  customSections?: Array<string>;
  entity!: Entity;
  protected entity$: Subject<Entity> = new Subject<Entity>();
  protected entityUtils = new EntityUtils();
  formGroup!: FormGroup;
  isDisabled: boolean = false;
  justificationFormControl: FormControl = new FormControl();
  protected parentStep?: StepModel;
  processing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  questions: Array<ConfigQuestion> = [];
  questionsConfig!: ConfigUtil;
  showJustification: boolean = false;
  protected stepAnswers$: BehaviorSubject<Record<string, any>> = new BehaviorSubject<Record<string, any>>({});
  systemUser$: BehaviorSubject<string | undefined>
  primaryContact: string | undefined
  visibleControls!: Set<string>;

  constructor(
    private readonly configQueries: ConfigQueries,
    readonly entityQueries: EntityQueries,
    readonly http: HttpService,
    private readonly onboardingQueries: OnboardingQueries,
    readonly stepperQueries: StepperQueries,
    private readonly _snackBar: MatSnackBar
  ) {
    this.systemUser$ = this.http.systemUser
    this.primaryContact = this.http.getPrimaryContact()
  }

  runInit = (): Observable<unknown> => concat(
    this.loadEntity(),
    this.loadStep(),
    this.loadConfig(),
    this.loadOnboarding()
  )

  getStepAnswers = (data: Record<string, any>) => data[this.currentStep.key]

  ngOnDestroy(): void {
    this.questionsConfig?.unsubscribe$.next(true);
  }

  initialStepAnswers(): Record<string, any> | undefined {
    return undefined;
  }

  private loadEntity(): Observable<Entity> {
    return this.entityQueries
      .getState()
      .pipe(
        first((data) => !!data),
        tap((entity: Entity) => {
          this.entity = entity;
          this.entity$.next(entity);
        })
      )
  }

  private loadStep(): Observable<StepModel> {
    return this.stepperQueries
      .getStep()
      .pipe(
        first(data => !!data),
        map((step: StepModel) => {
          if(this.overrideStep)
            return this.overrideStep
          return step
        }),
        tap((currentStepData: StepModel) => (this.currentStep = currentStepData)),
        tap((currentStepData: StepModel) => (this.parentStep = currentStepData.parentStep)),
        tap((currentStepData: StepModel) => {
          if ((currentStepData.metadata?.disable ?? false) && !StaticData.ReviewIgnoredSteps.includes(currentStepData.key)) {
            this.isDisabled = true
            this.formGroup?.disable();
          }
        }),
        tap((currentStepData: StepModel) => {
          this.showJustification =
            this.entity?.profile?.onboarding?.latestReview?.review?.reviews?.find(
              (item) => item.section_id === currentStepData.key
            )?.status === 'REJECTED';
        }),
        tap((currentStepData: StepModel) => {
          this.justificationFormControl.setValue(
            this.entity?.profile?.onboarding?.answers?.sections?.find(
              (item) => item.id === currentStepData.key
            )?.answers?.justification
          );
        })
      )
  }

  onAnswerChange(question: ConfigQuestion, value: any): Observable<any> {
    return this.http.saveAnswers(this.entity.id, this.currentStep.key, {[question.key]: value})
  }

  protected monitorAnswerChanges(questionsConfig: ConfigUtil): void {
    questionsConfig.onAnswerReceived$
      .pipe(
        takeUntil(this.questionsConfig?.unsubscribe$),
        switchMap(({ question, value }) => this.onAnswerChange(question, value).toPromise()),
        tap(() => this.showMessage()),
        catchError(() => of(undefined))
      )
      .subscribe()
  }

  handleConfigResponse(questions: Record<string, Array<ConfigQuestion>>): void {
    if (!questions) throw Error('No questions found');

    let _sectionQuestions = questions[this.currentStep.key];

    if(this.customSections) {
      _sectionQuestions = []
      for(let section of this.customSections) {
        _sectionQuestions.push(...questions[section])
      }
    }

    this.questionsConfig = new ConfigUtil(_sectionQuestions);
    this.questions = this.questionsConfig.questions.getValue();
    this.formGroup = this.questionsConfig.formGroup;
    this.visibleControls = this.questionsConfig.visibleControls;

    if(this.isDisabled) {
      this.formGroup?.disable()
    }

    this.monitorAnswerChanges(this.questionsConfig)
  }

  handleOnboardingResponse(data: OnboardingStateModel): void {
    if (!data) throw Error('No onboarding data found');

    this.stepAnswers$.next({...(this.initialStepAnswers() ?? {}), ...this.getStepAnswers(data)})
    this.questionsConfig.setDefaultValues(this.stepAnswers$.value);

    this.initFormGroupState(this.formGroup);
  }

  private isControlPopulated(control: AbstractControl, key?: string): boolean {
    if (control instanceof FormControl) {
      if(key && this.visibleControls.has(key) && this.questionsConfig.requiredControls.has(key)) {
        return control.value !== null && control.value !== undefined;
      }

      return true
    }

    if (control instanceof FormArray) {
      return control.controls.every((ctrl) => this.isControlPopulated(ctrl));
    }

    if (control instanceof FormGroup) {
      Object.keys(control.controls)
        .filter(item => this.visibleControls.has(item))
        .every((key) => this.isControlPopulated(control.controls[key], key));
    }

    return false;
  };

  protected initFormGroupState(formGroup: AbstractControl): void {
    if(this.isControlPopulated(formGroup) && !this.currentStep.complete) {
      formGroup.markAsDirty()
      formGroup.markAllAsTouched()
    }
  }


  private loadConfig(): Observable<Record<string, Array<ConfigQuestion>>> {
    return this.configQueries
      .getState()
      .pipe(
        first(data => Object.keys(data).length > 0),
        tap((data) => this.configQuestions = data),
        tap((config) => this.handleConfigResponse(config)),
        retry(3)
      )
  }

  private loadOnboarding(): Observable<OnboardingStateModel> {
    return this.onboardingQueries.getState()
      .pipe(
        first(data => !!data),
        tap((data: OnboardingStateModel) => this.handleOnboardingResponse(data)),
        retry(3)
      )
  }

  onStepComplete(): Observable<any> {
    throw new Error('Method not implemented.');
  }

  afterStepSaveComplete(): void {
    console.info('No additional actions defined for afterStepSaveComplete');
  }

  formatOnboardingAnswersSection(): OnboardingSection {
    return {
      id: this.currentStep.key,
      title: this.currentStep.title,
      status: REVIEW_STATUS.ENTITY_UPDATED,
      answers: this.formatOnboardingAnswers()
    }
  }

  formatOnboardingAnswers(): { justification: string, [key: string]: any } {
    return {
      ...this.questionsConfig.getFormValuesMap(),
      justification:
      this.justificationFormControl.value
    }
  }

  protected saveOnboardingAnswers(): Observable<any> {
    return this.http.updateOnboardingAnswers(
      this.formatOnboardingAnswersSection(),
      this.entity.id
    )
  }

  validateAndSave(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.processing$.next(true);
      this.onStepComplete()
        .pipe(
          switchMap(() => this.saveOnboardingAnswers()),
          tap(() => this.entityQueries.loadState()),
          tap(() => this.formGroup.markAsPristine()),
          finalize(() => this.processing$.next(false)),
          catchError((err) => {
            this.processing$.next(false);
            return err;
          })
        )
        .subscribe(
          () => this.afterStepSaveComplete()
        );
    }
  }

  onNextClick(): void {
    if (!this.canSubmit()) {
      this.stepperQueries.navigateStep('next');
    } else {
      this.validateAndSave();
    }
  }

  onPreviousClick(): void {
    if (!this.canSubmit()) {
      this.stepperQueries.navigateStep('prev');
    } else {
      this.validateAndSave();
    }
  }

  canSubmit(): boolean {
    if(this.isDisabled) {
      return false;
    } else if(this.formGroup?.pristine && this.justificationFormControl.pristine) {
      return false
    } else if (this.formGroup?.valid && ((!this.systemUser$.value && !!this.primaryContact))) {
      return true
    } else if (this.justificationFormControl.valid && !this.systemUser$.value) {
      return true
    }

    return false
  }

  nextButtonText = (): string => {
    if(this.processing$.value) return 'Processing...';
    else if (this.canSubmit()) return 'Complete Section';

    return 'Next';
  }

  previousButtonText = (): string => {
    if(this.processing$.value) return 'Processing...';
    else if (this.canSubmit()) return 'Complete Section';

    return 'Previous';
  }

  private showMessage(message = 'Answer updated'): void {
    this._snackBar.open(message, undefined, {
      duration: 1000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
      politeness: 'polite'
    });
  }
}
