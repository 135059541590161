<div class='wrapper'>
    <bcb-toolbar></bcb-toolbar>
    <div class='pages'>
        <bcb-page-wrapper></bcb-page-wrapper>
    </div>
    <div class='spacer'></div>
    <div class='footer'>
        <div class='container d-flex justify-content-between'>
            <div>
                <a href='https://www.bcbgroup.com/privacy-policy/'>Privacy Policy</a>
                <a href='https://www.bcbgroup.com/terms-of-use/'>Terms Of Use</a>
            </div>
            <span>Copyright {{year}} BCB Group</span>
        </div>
    </div>
</div>
