<h3>Corporate Shareholder Information</h3>
<mat-dialog-content class="d-flex flex-column">
    <ng-container *ngIf="disableTabs then createShareholder; else updateShareholder"></ng-container>
</mat-dialog-content>
<mat-dialog-actions class="d-flex align-items-center justify-content-between">
    <button mat-flat-button class="bcb-btn btn-small danger" (click)="cancel()">Close</button>
    <div>
        <button mat-stroked-button color="primary" class="bcb-btn btn-small primary"
                *ngIf="activeTabIndex > 0"
                [disabled]="disableTabs"
                (click)="navigate('prev')">Previous
        </button>
        <button mat-stroked-button color="primary" class="bcb-btn btn-small primary"
                *ngIf="activeTabIndex < totalTabs -1 && shareholder?.id"
                [disabled]="disableTabs"
                (click)="navigate('next')">Next
        </button>
        <button mat-flat-button color="primary"
                *ngIf="activeTabIndex === totalTabs -1 && !disableForms"
                [disabled]="disableTabs"
                (click)="submit()">Save
        </button>
    </div>
</mat-dialog-actions>

<ng-template #updateShareholder>
    <mat-tab-group class="tab--label-small" [selectedIndex]="activeTabIndex" (selectedIndexChange)="onActiveTabChange($event)" dynamicHeight>
        <mat-tab label="Legal Entity Details">
            <bcb-legal-entity-details-step [shareholder]="shareholder"
                                           [parent]="parent"
                                           [disabled]='disableForms'
                                           (entitySaved)="onUpdateShareholder()"
            ></bcb-legal-entity-details-step>
        </mat-tab>
        <mat-tab label="Legal Persons Information" [disabled]="disableTabs">
            <bcb-director-information-step [shareholder]="shareholder"
                                           [disabled]='disableForms'
                                           [linkedDirectors]="linkedDirectors"></bcb-director-information-step>
        </mat-tab>
        <mat-tab label="Shareholder Information" [disabled]="disableTabs">
            <bcb-shareholder-information-step [shareholder]="shareholder"
                                              [disabled]='disableForms'
                                              [linkedShareholders]="linkedShareholders"></bcb-shareholder-information-step>
        </mat-tab>
        <mat-tab label="Upload Documents" [disabled]="disableTabs">
            <bcb-upload-documents-step [shareholder]="shareholder"
                                       [disabled]='disableForms'></bcb-upload-documents-step>
        </mat-tab>
        <mat-tab label="Review and Submit" [disabled]="disableTabs">
            <bcb-review-submit-step [shareholder]="shareholder"
                                    [linkedDirectors]="linkedDirectors"
                                    [linkedShareholders]="linkedShareholders"></bcb-review-submit-step>
        </mat-tab>
    </mat-tab-group>
</ng-template>

<ng-template #createShareholder>
    <bcb-legal-entity-details-step [shareholder]="shareholder"
                                   [parent]="parent"
                                   [disabled]='disableForms'
                                   (entitySaved)="onCreateShareholder()"></bcb-legal-entity-details-step>
</ng-template>
