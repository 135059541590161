<div class="page">
  <div class="content">
    <div class="row">
      <h1 class="col page__title">Primary Contacts</h1>
    </div>
    <mat-divider class="mb-3"></mat-divider>
  </div>

  <div class="row" *ngIf="!formGroup; else form">
    <div class="d-flex flex-column align-items-center">
      <mat-spinner>{{runInit() | async}}</mat-spinner>
    </div>
  </div>

  <div class="spacer"></div>

  <div class="btn-bar" *ngIf="!systemUser$.value && primaryContact">
  	<button mat-flat-button class='bcb-btn primary' [class.btn-full-width]="canSubmit()" (click)='onNextClick()'
			[disabled]='processing$.value'>{{nextButtonText()}}</button>
	<button *ngIf="!canSubmit()" mat-stroked-button class='bcb-btn primary' (click)='onPreviousClick()'
            [disabled]="processing$.value">{{previousButtonText()}}</button>
  </div>
</div>

<ng-template #form>
  <div class="row" *ngFor="let group of contactGroups">
    <h3>{{group.type | titlecase}}</h3>
    <form [formGroup]="formGroup">
      <div class="row">
        <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                               [currentStep]="currentStep.key"
                               [entity]="entity"
                               [formGroup]="group.formGroup"
                               [questions]="group.questions"
                               [questionsConfig]="group.questionsConfig"
                               [visibleControls]="group.visibleControls"
        ></bcb-component-wrapper>

        <ng-container *ngIf="showJustification">
          <mat-divider style="margin: 25px 0"></mat-divider>
          <div class="col-12 lbl-stacked">
            <mat-label>Justification</mat-label>
            <mat-form-field appearance="outline">
                            <textarea matInput [formControl]="justificationFormControl"
                                      (input)="formGroup.markAsDirty()" rows="4"></textarea>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</ng-template>
