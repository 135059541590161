import { Component } from "@angular/core";
import { StepperQueries } from "../../../../../../core/store/stepper/stepper.queries";
import { OnboardingQueries } from "../../../../../../core/store/onboarding/onboarding.queries";
import { EntityQueries } from "../../../../../../core/store/entity/entity.queries";
import { HttpService } from "../../../../../../core/services/http/http.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfigQueries } from "../../../../../../core/store/config/config.queries";
import { BaseDueDiligenceComponent } from "../../base-due-diligence.component";

@Component({
  selector: "bcb-pep-sanctions-check",
  templateUrl: "./pep-sanctions-check.component.html",
  styleUrls: ["./pep-sanctions-check.component.scss"]
})
export class PepSanctionsCheckComponent extends BaseDueDiligenceComponent {
  componentLayoutClassList: string[] = ["col-12"];

  constructor(configQueries: ConfigQueries,
              entityQueries: EntityQueries,
              http: HttpService,
              onboardingQueries: OnboardingQueries,
              stepperQueries: StepperQueries,
              _snackBar: MatSnackBar) {
    super(
      configQueries,
      entityQueries,
      http,
      onboardingQueries,
      stepperQueries,
      _snackBar
    );
  }
}