<h3>You are required to upload documents for {{shareholder?.entity_name}}</h3>

<form [formGroup]='formGroup'>
    <div class="row">
        <bcb-component-wrapper [componentClassList]="componentClassList"
                               [currentStep]="currentStep"
                               [entity]="shareholder"
                               [formGroup]="formGroup"
                               [questions]="questionsConfig.questions.value"
                               [questionsConfig]="questionsConfig"
                               [visibleControls]="visibleControls"
        ></bcb-component-wrapper>
    </div>
</form>