import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { Entity } from '../../../../../../../core/models/entity.model';
import { Shareholder } from '../../../../../../../core/models/shareholder.interface';
import { DirectorsQueries } from '../../../../../../../core/store/directors/directors.queries';
import { ShareholdersQueries } from '../../../../../../../core/store/shareholders/shareholders.queries';
import { EntityUtils } from '../../../../../../../core/utils/entity.util';
import { HttpService } from '../../../../../../../core/services/http/http.service';
import { EntityQueries } from '../../../../../../../core/store/entity/entity.queries';
import { ConfigQuestion } from '../../../../../../../core/models/config-question.interface';
import { DirectorsInformationContentComponent } from '../../../../directors-information/directors-information-content/directors-information-content.component';
import { ConfigQueries } from '../../../../../../../core/store/config/config.queries';
import { first, map } from 'rxjs/operators';
import { BehaviorSubject } from "rxjs";

@Component({
	selector: 'bcb-director-information-step',
	templateUrl: './director-information-step.component.html',
	styleUrls: ['./director-information-step.component.scss'],
})
export class DirectorInformationStepComponent implements OnInit, OnChanges {
	@Input() addedDirectors: Array<string> = [];
	@Input() addedShareholders: Array<Shareholder> = [];
	@Input() linkedDirectors: Array<Entity> = [];
	@Input() shareholder!: Shareholder;
	@Input() linkedShareholders?: Array<Shareholder>;
	@Input() disabled?: boolean;

	canSubmit = false;
	addEditDirector: boolean = false;
	editingDirector?: Entity;
	legalEntityTypes: Map<string, Array<string>> = new Map<
		string,
		Array<string>
	>();
	directorsBeingAdded: number = 0;
	displayedColumns = ['shares', 'name', 'types', 'actions'];
	markedForDelete: Set<Entity> = new Set<Entity>();
	data!: {
		entity: Entity | Shareholder;
		questions: Array<ConfigQuestion>;
		selectedLegalPerson?: string;
		shareholders?: Array<Shareholder | Entity>;
	};
	currentStep: string = 'directors';
	$systemUser: BehaviorSubject<string | undefined>
	@ViewChild(DirectorsInformationContentComponent, { static: false })
	private formContent!: DirectorsInformationContentComponent;

	constructor(
		private readonly directorsQueries: DirectorsQueries,
		private readonly shareholdersQueries: ShareholdersQueries,
		private readonly entityQueries: EntityQueries,
		private readonly configQueries: ConfigQueries,
		private readonly http: HttpService,
		private readonly entityUtils: EntityUtils
	) {
		this.$systemUser = http.systemUser
	}

	ngOnInit(): void {
		this.data = {
			questions: [],
			entity: this.shareholder,
		};
		this.configQueries
			.getState()
			.pipe(
				first(),
				map((data) => data[this.currentStep])
			)
			.subscribe((questions) => (this.data.questions = questions));

		this.shareholdersQueries
			.getShareholdersByParent(this.shareholder.id)
			.subscribe((res) => (this.data.shareholders = res));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.linkedDirectors?.currentValue) {
			for (let item of this.linkedDirectors) {
				if (this.shareholder?.profile?.onboarding?.legal_persons)
					this.legalEntityTypes.set(
						item.id,
						this.shareholder?.profile?.onboarding?.legal_persons[
							item.id
						][this.entityUtils.questionKeys.legal_person_type]
					);
			}
		}
	}

	onAddNewClick(): void {
		this.editingDirector = undefined;
		this.data.selectedLegalPerson = undefined;
		this.addEditDirector = true;
	}

	onEditDirector(legalPerson: Entity): void {
		this.editingDirector = legalPerson;
		this.data.selectedLegalPerson = legalPerson.id;
		this.addEditDirector = true;
	}

	onRemoveDirector(entity: Entity): void {
		this.directorsQueries.removeDirector(entity.id);
		this.http
			.removeCorporateSubEntity(entity.id, entity.parent_id ?? '')
			.subscribe(() => this.entityQueries.reloadState());
		this.markedForDelete.delete(entity);
	}

	handleDeleteClick(entity: Entity, remove?: boolean): void {
		if (remove) {
			this.markedForDelete.delete(entity);
		} else {
			this.markedForDelete.add(entity);
		}
	}

	cancel(): void {
		this.editingDirector = undefined;
		this.addEditDirector = false;
	}

	triggerSubmit(): void {
		this.formContent.onSubmit();
	}

	onSubmitChanged(formValues: Record<string, any>): void {
		if (this.$systemUser || (this.canSubmit && !this.disabled)) {
			const responseEntity =
				this.entityUtils.mapQuestionsToEntity(formValues);

			if (!this.editingDirector) {
				this.http
					.addLegalEntity({
						entity: {
							...responseEntity,
							parent_id: this.shareholder?.id,
						},
						raw: formValues,
					})
					.subscribe(() => {
						// Reload directors/shareholders to reflect correct state
						this.entityQueries.reloadState();
					});
			} else {
				this.http
					.updateLegalEntity({
						entity: {
							...responseEntity,
							parent_id: this.shareholder?.id,
							id: formValues.entityId,
						},
						raw: formValues,
					})
					.subscribe(() => {
						// Reload directors/shareholders to reflect correct state
						this.entityQueries.reloadState();
					});
			}
			this.addEditDirector = false;
			this.cancel();
		}
	}
}
