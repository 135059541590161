import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfigQuestion } from '../../models/config-question.interface';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ERROR_MESSAGES } from '../../constants/enum.const';
import { Country } from '../../models/country.interface';
import { ArrayUtils } from '../../utils/array.util';
import { StaticData } from '../../constants/static.const';
import { MatSelectChange } from "@angular/material/select";

@Component({
	selector: 'bcb-generic-country-select',
	templateUrl: './generic-country-select.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: GenericCountrySelectComponent,
		},
	],
	styleUrls: ['./generic-country-select.component.scss'],
})
export class GenericCountrySelectComponent
	implements OnInit, ControlValueAccessor
{
	@Input() question!: ConfigQuestion;
	@Input() formGroup!: FormGroup;
	@Input() formControl!: FormControl;
	@Input() formControlName?: string;
	@Input() errorMessage?: ERROR_MESSAGES;
	@Output() change: EventEmitter<any> = new EventEmitter<any>();

	value: any;
	isDisabled: boolean = false;
	readonly allCountries: Array<Country> = ArrayUtils.sortObject(
		StaticData.countries,
		'name'
	);
	displayCountries: Array<Country> = this.allCountries;

	constructor() {}

	ngOnInit(): void {
		if (this.formControl) {
			this.formGroup = this.formControl.parent as FormGroup;
		} else if (this.formGroup && this.formControlName) {
			this.formControl = this.formGroup.get(
				this.formControlName
			) as FormControl;
		} else {
			throw new Error(
				'One of the following is required: [formControl] or [formControlName] + [FormGroup]'
			);
		}
	}

	changed: (value: any) => void = () => {};

	touched: () => void = () => {};

	writeValue(obj: any): void {
		this.value = obj;
	}

	registerOnChange(fn: any): void {
		this.changed = fn;
	}

	registerOnTouched(fn: any): void {
		this.touched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	compareSelectOptions(p1: any, p2: any): boolean {
		if (p1 && p2) {
			// name from Country
			// value as used by generic lists
			return p1.name === p2.name || p1.value === p2.value;
		}
		return false;
	}

	onMultiSelectOpenChange(open: any): void {
		if (!open) {
			this.displayCountries = this.allCountries;
		}
	}

	onFilterTextChange({ value }: any): void {
		this.displayCountries = this.allCountries.filter((country: Country) =>
			country.name.toLowerCase().includes(value)
		);
	}

	onChanged(event: MatSelectChange): void {
		this.change.emit(event.value)
	}
}
