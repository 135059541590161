import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
	selector: 'bcb-org-chart',
	templateUrl: './org-chart.component.html',
	styleUrls: ['./org-chart.component.scss'],
})
export class OrgChartComponent {
	@Input() data: any;
	@Output() editClicked: EventEmitter<string> = new EventEmitter<string>();
	@Output() removeClicked: EventEmitter<string> = new EventEmitter<string>();

	onEditClick(id: string): void {
		this.editClicked.emit(id);
	}

	onRemoveClick(id: string): void {
		this.removeClicked.emit(id);
	}
}
