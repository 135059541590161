<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Terms and Legal</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
    </div>

    <form action="" [formGroup]="formGroup">
        <div class="row">
            <div class="col-12 lbl-stacked">
                <p class="mb-4">
                    <mat-checkbox style="width: 50%"
                                  formControlName="understands_bcb_group_does_not_hold_cash_or_assets">
                        I understand that BCB Group does not hold cash or client cryptocurrency assets,<br/>and that I
                        need
                        my own wallet to hold my cryptocurrency assets, unless custodianship specifically authorized
                    </mat-checkbox>
                </p>

                <p>
                    <mat-checkbox formControlName="understands_bcb_group_does_not_hold_cash_or_assets">
                        I understand that cryptocurrencies are highly volatile assets, that the value and go up and
                        down
                        very quickly,<br/>and therefore my entire cryptocurrency holding is at risk
                    </mat-checkbox>
                </p>
            </div>
        </div>
    </form>

    <div class="spacer"></div>

    <div class="btn-bar">
        <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()" [disabled]="processing">{{processing ? 'Processing...' : 'Submit'}}</button>
        <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()" [disabled]="processing">{{processing ? 'Processing...' : 'Previous'}}</button>
    </div>
</div>
