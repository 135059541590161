import { StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UpdateConfig } from './config.actions';
import {ConfigQuestion} from "../../models/config-question.interface";

@Injectable()
export class ConfigQueries {
	constructor(private readonly store: Store) {}

	getState(): Observable<Record<string, Array<ConfigQuestion>>> {
		return this.store.select(
			new StateToken<Record<string, Array<ConfigQuestion>>>('config')
		);
	}

	updateState(
		payload: Record<string, Array<ConfigQuestion>>
	): void {
		this.store.dispatch(new UpdateConfig(payload));
	}
}
