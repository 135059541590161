import { Component, HostListener, OnInit } from '@angular/core';
import { EntityQueries } from '../../../core/store/entity/entity.queries';
import { EntityStateModel } from '../../../core/store/entity/entity.state';
import { StepperQueries } from '../../../core/store/stepper/stepper.queries';
import { StepModel, StepModelMetadata } from '../../stepper/models/step.model';
import { MANAGEMENT_ROUTES } from '../../../core/constants/enum.const';

@Component({
    selector: 'bcb-page-wrapper',
    templateUrl: './page-wrapper.component.html',
    styleUrls: ['./page-wrapper.component.scss']
})
export class PageWrapperComponent implements OnInit {
    authorised: boolean = false;
    showStepper: boolean = false;
    hideStepper: boolean = true;
    extendFab: boolean = false;
    showFab: boolean = false;
    stepMetadata: StepModelMetadata | undefined;
    shouldShowWarning: boolean = false;

    constructor(
        private readonly entityQueries: EntityQueries,
        private readonly stepperQueries: StepperQueries
    ) {
    }

    @HostListener('window:scroll', []) onWindowScroll() {
        if (window.scrollY > 100) {
            this.showFab = true;
        } else {
            this.showFab = false;
            this.extendFab = false;
        }
    }

    ngOnInit(): void {
        this.entityQueries.getState().subscribe((res: EntityStateModel) => {
            this.authorised = !!res.id;
            this.showStepper = this.authorised;

            this.hideStepper =
                res.profile?.onboarding?.active_step === 'completed';
        });

        this.stepperQueries
            .getStep()
            .subscribe((currentStepData: StepModel | undefined) => {
                this.stepMetadata = currentStepData?.metadata;
                if((<any> Object).values(MANAGEMENT_ROUTES).includes(String(currentStepData?.key))) {
                    this.shouldShowWarning = false
                } else {
                    this.shouldShowWarning = this.authorised
                }
            });
    }

    scrollTop(): void {
        window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
    }
}
