import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { Entity } from '../../../../../../../../core/models/entity.model';
import { takeWhile, tap } from 'rxjs/operators';
import { ConfigQuestionType } from '../../../../../../../../core/constants/enum.const';
import { ConfigUtil } from '../../../../../../../../core/utils/config.util';
import { FormGroup } from '@angular/forms';
import { Shareholder } from '../../../../../../../../core/models/shareholder.interface';
import { StaticData } from '../../../../../../../../core/constants/static.const';
import { EntityUtils } from '../../../../../../../../core/utils/entity.util';
import { ArrayUtils } from '../../../../../../../../core/utils/array.util';
import { Country } from '../../../../../../../../core/models/country.interface';
import { ConfigQuestion } from '../../../../../../../../core/models/config-question.interface';
import { BehaviorSubject } from "rxjs";

@Component({
	selector: 'bcb-legal-entity-details-content',
	templateUrl: './legal-entity-details-content.component.html',
	styleUrls: ['./legal-entity-details-content.component.scss'],
})
export class LegalEntityDetailsContentComponent implements OnInit, OnDestroy {
	@Input() data!: {
		entity: Entity;
		questions: Array<ConfigQuestion>;
		selectedLegalPerson?: string;
		shareholders?: Array<Shareholder | Entity>;
		systemUser?: string
	};
	@Output() submit: EventEmitter<Record<string, any>> = new EventEmitter<
		Record<string, any>
	>();
	@Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	remainingShares: number = 100;
	questionsConfig!: ConfigUtil;
	formGroup!: FormGroup;
	visibleControls!: Set<string>;
	configQuestionType = ConfigQuestionType;
	currentStep: string = 'shareholders';
	allCountries: Array<Country> = ArrayUtils.sortObject(
		StaticData.countries,
		'name'
	);
	currentShares = 0;
	unsubscribe = false;
	componentClassList = ['col-md-6'];

	constructor(private readonly entityUtils: EntityUtils) {}

	ngOnInit(): void {
		if (!this.data) {
			throw new Error(
				'[data] is required for bcb-directors-information-content'
			);
		}
		this.questionsConfig = new ConfigUtil(this.data.questions);
		this.formGroup = this.questionsConfig.formGroup;
		this.visibleControls = this.questionsConfig.visibleControls;

		const { selectedLegalPerson, entity } = this.data;

		if (selectedLegalPerson) {
			const activeShareholder =
				entity.profile?.onboarding?.parent_companies &&
				entity.profile?.onboarding?.parent_companies[
					selectedLegalPerson
				];
			if (activeShareholder) {
				this.questionsConfig.setDefaultValues(activeShareholder);
			}
			this.currentShares =
				this.formGroup?.get(this.entityUtils.questionKeys.shares)
					?.value ?? 0;
		}

		this.formGroup.valueChanges
			.pipe(
				takeWhile(() => !this.unsubscribe),
				tap(() => this.formValid.emit(this.formGroup.valid))
			)
			.subscribe();
	}

	onShareholderChange(): number {
		this.remainingShares =
			this.entityUtils.getRemainingSharesForShareholders(
				this.data.entity,
				this.data.shareholders ?? [],
				'id',
				this.currentShares
			);

		return this.remainingShares;
	}

	public onSubmit(): void {
		this.formGroup.markAllAsTouched();
		if (this.formGroup.valid || (this.data.systemUser && this.formGroup.dirty && this.formGroup.get(this.entityUtils.questionKeys.shares)?.value)) {
			this.submit.emit({
				...this.questionsConfig.getFormValuesMap(),
				entityId: this.data.selectedLegalPerson,
			});
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe = true;
		this.questionsConfig.unsubscribe.next(true);
	}
}
