import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
    AddUpdateShareholder,
    ClearState,
    LoadShareholders,
    RemoveShareholder,
    SetActiveShareholder,
    UpdateShareholders,
} from './shareholders.actions';
import {Shareholder} from '../../models/shareholder.interface';

const initialState: ShareholdersStateModel = {
    shareholders: [],
};

@State<ShareholdersStateModel>({
    name: 'shareholders',
    defaults: initialState,
})
@Injectable()
export class ShareholdersState {
    constructor() {
    }

    // -------------------------- Actions --------------------------
    @Action(LoadShareholders)
    LoadState(
        {setState}: StateContext<ShareholdersStateModel>,
        {payload}: LoadShareholders
    ): void {
        setState({shareholders: payload});
    }

    @Action(UpdateShareholders)
    UpdateShareholder(
        {getState, patchState}: StateContext<ShareholdersStateModel>,
        {payload}: UpdateShareholders
    ): void {
        const state = getState();
        patchState({...state, shareholders: payload});
    }

    @Action(SetActiveShareholder)
    setActiveShareholder(
        {getState, patchState}: StateContext<ShareholdersStateModel>,
        {payload}: SetActiveShareholder
    ): void {
        patchState({
            activeShareholder: payload ?? undefined,
        });
    }

    @Action(RemoveShareholder)
    RemoveShareholder(
        {getState, patchState}: StateContext<ShareholdersStateModel>,
        {payload}: RemoveShareholder
    ): void {
        const state = getState();
        const shareholders = state.shareholders.filter(
            (shareholder: Shareholder) => shareholder.id !== payload
        );

        patchState({...state, shareholders});
    }

    @Action(AddUpdateShareholder)
    AddUpdateShareholder(
        {getState, patchState}: StateContext<ShareholdersStateModel>,
        {payload}: AddUpdateShareholder
    ): void {
        const state = getState();
        const shareholders = [...state.shareholders];

        const index: number = shareholders.findIndex(
            (shareholder: Shareholder) => shareholder.id === payload.id
        );
        if (index > -1) {
            shareholders[index] = payload;
        } else {
            shareholders.push(payload);
        }

        patchState({...state, shareholders});
    }

    @Action(ClearState)
    clearState({setState}: StateContext<ShareholdersStateModel>): void {
        setState(initialState);
    }

    // -------------------------- Selectors --------------------------
    @Selector()
    getState(state: ShareholdersStateModel): ShareholdersStateModel {
        return state;
    }
}

export interface ShareholdersStateModel {
    activeShareholder?: string;
    shareholders: Array<Shareholder>;
}
