<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Review and submit</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
    </div>

    <p>Thank you for taking the time to complete our portal questions. By pressing submit, you are confirming that all the information has been reviewed and is correct to the best of your knowledge.</p>
    <p>Following review of your application, if we require any additional information we will contact you on the email address provided in section 1 with an email link to the portal.  The link will take you directly to your application and will allow you to update your information and supply any requested information as required by the BCB onboarding team, thank you.</p>
    <p>We look forward to having you onboarded with BCB.</p>

    <strong class="mt-3">Need Help?</strong>
    <p>If you require any assistance, please contact us via email at <a href="mailto:csonboarding@bcbgroup.io">csonboarding@bcbgroup.io</a></p>

    <div class="row" *ngIf="!formGroup; else form">
        <div class="d-flex flex-column align-items-center">
            <mat-spinner>{{runInit() | async}}</mat-spinner>
        </div>
    </div>

    <div class='spacer'></div>

    <div class='btn-bar' *ngIf='!systemUser$.value && primaryContact'>
        <button mat-flat-button class='bcb-btn primary' [class.btn-full-width]="canSubmit()" (click)='onNextClick()'
                [disabled]='processing$.value || !canSubmit()'>{{nextButtonText()}}</button>
        <button *ngIf="!canSubmit()" mat-stroked-button class='bcb-btn primary' (click)='onPreviousClick()'
                [disabled]='processing$.value'>{{previousButtonText()}}</button>
    </div>
</div>

<ng-template #form>
    <form [formGroup]='formGroup'>
        <div class="row">
            <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                                   [currentStep]="currentStep.key"
                                   [entity]="entity"
                                   [formGroup]="formGroup"
                                   [questions]="questions"
                                   [questionsConfig]="questionsConfig"
                                   [visibleControls]="visibleControls"
            ></bcb-component-wrapper>
        </div>
    </form>
</ng-template>