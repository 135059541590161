import { Entity } from '../../models/entity.model';

export class LoadDirectors {
	static readonly type = '[directors] load directors';

	constructor(public payload: Array<Entity>) {}
}

export class RemoveDirector {
	static readonly type = '[directors] remove director';

	constructor(public payload: string) {}
}

export class AddUpdateDirector {
	static readonly type = '[directors] add director';

	constructor(public payload: Entity) {}
}

export class ClearState {
	static readonly type = '[directors] clear directors state';

	constructor() {}
}
