export interface EntityContact {
  id?: string
  entityId: string
  email: string
  mobile?: string
  firstName: string
  lastName: string
  type: ContactType
  emailVerified?: boolean
  mobileVerified?: boolean
  emailLastVerifiedAt?: Date
  mobileLastVerifiedAt?: Date
}

export enum ContactType {
  PRIMARY = 'primary',
  ONBOARDING = 'onboarding'
}