import { Component, OnInit } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { Observable } from "rxjs";

@Component({
	selector: 'bcb-source-of-funds',
	templateUrl: './source-of-funds.component.html',
	styleUrls: ['./source-of-funds.component.scss'],
})
export class SourceOfFundsComponent extends BasePageComponent {
	customSections: Array<string> = ['source_of_funds', 'source_of_wealth'];
	componentLayoutClassList = ['col-12']
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onStepComplete(): Observable<any> {
		return this.http
			.updateSourceOfFunds(
				this.questionsConfig.getFormValuesMap(),
				this.entity.id
			);
	}

}