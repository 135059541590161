<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Verification Documents</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
    </div>

    <p>If you would like to trade with us immediately, please make sure to upload your source of funds on the next
        step.</p>

    <h3>Verification documents</h3>
    <form action="" [formGroup]="formGroup">
        <div class="col-12 lbl-stacked" *ngFor="let doc of requiredDocuments">
            <div class="d-flex align-items-end justify-content-between">
                <mat-label class="col">{{doc.label}}</mat-label>
            </div>
            <mat-progress-bar
                    *ngIf="progress[doc.type]"
                    color="primary"
                    mode="determinate"
                    [value]="progress[doc.type]">
            </mat-progress-bar>
            <mat-form-field appearance="outline"
                            (click)="fileUpload.click()">
                <div class="file-input-wrapper d-flex align-items-center justify-content-between">
                    <input matInput
                           [formControlName]="doc.type"
                           placeholder="Choose file"
                           readonly>
                    <input #fileUpload
                           type="file"
                           class="uploadFile"
                           [disabled]='disabled'
                           multiple="multiple"
                           accept="image/*,.pdf, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                           (change)="fileChange($event.target, doc, doc.type)"/>
                    <mat-icon class="file-icon"
                              (click)="deleteFile(formGroup.get(doc.type)?.value)">
                        {{formGroup.get(doc.type)?.value ? 'swap_horiz' : 'upload'}}
                    </mat-icon>
                </div>
                <mat-error
                        *ngIf="formGroup.get(doc.type)?.invalid">{{getErrorMessage(doc.type)}}</mat-error>
            </mat-form-field>
        </div>
    </form>

    <div class="spacer"></div>

    <div class="btn-bar">
        <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()">Next</button>
        <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()">Previous</button>
    </div>
</div>
