import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearOnboarding, UpdateOnboarding } from './onboarding.actions';
import { StepperQueries } from '../stepper/stepper.queries';
import { EntityQueries } from '../entity/entity.queries';
import { Onboarding } from '../../models/onboarding.interface';

// TODO: remove mock and retrieve data from a service
const initialState: OnboardingStateModel = {};

// onboardingMock.onboarding as OnboardingStateModel;

@State<OnboardingStateModel>({
	name: 'onboarding',
	defaults: { ...initialState },
})
@Injectable()
export class OnboardingState implements NgxsOnInit {
	constructor(
		private readonly stepperQueries: StepperQueries,
		private readonly entityQueries: EntityQueries
	) {}

	// ------------------------- Lifecycle--------------------------

	ngxsOnInit(ctx?: StateContext<any>): any {
		this.entityQueries.getState().subscribe((data) => {
			if (data?.profile?.onboarding)
				ctx?.dispatch(
					new UpdateOnboarding({
						...data?.profile?.onboarding,
					})
				);
		});
	}

	// ngxsOnChanges(change: NgxsSimpleChange): void {
	//     if (change.currentValue) {
	//         this.stepperQueries.generateConfig(change.currentValue);
	//     }
	// }

	// -------------------------- Actions --------------------------

	@Action(UpdateOnboarding)
	updateOnboarding(
		{ patchState }: StateContext<OnboardingStateModel>,
		{ payload }: UpdateOnboarding
	): void {
		patchState(payload);
	}

	@Action(ClearOnboarding)
	clearOnboarding({ setState }: StateContext<OnboardingStateModel>): void {
		setState(initialState);
	}

	// -------------------------- Selectors --------------------------
	@Selector()
	getState(state: OnboardingStateModel): OnboardingStateModel {
		return state;
	}
}

export interface OnboardingStateModel extends Onboarding {}
