import { Component, OnDestroy, OnInit } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { MatDialog } from '@angular/material/dialog';
import { DirectorInformationModalComponent } from './director-information-modal/director-information-modal.component';
import { DirectorsQueries } from '../../../../core/store/directors/directors.queries';
import { Entity } from '../../../../core/models/entity.model';
import { ShareholdersQueries } from '../../../../core/store/shareholders/shareholders.queries';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { first, map, switchMap, takeWhile } from 'rxjs/operators';
import { StepModel } from '../../../stepper/models/step.model';
import { REVIEW_STATUS } from '../../../../core/constants/enum.const';
import { FormControl } from '@angular/forms';
import { EntityUtils } from '../../../../core/utils/entity.util';
import { BehaviorSubject } from 'rxjs';
import { Shareholder } from '../../../../core/models/shareholder.interface';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { ConfigQuestion } from '../../../../core/models/config-question.interface';

@Component({
	selector: 'bcb-directors-information',
	templateUrl: './directors-information.component.html',
	styleUrls: ['./directors-information.component.scss'],
})
export class DirectorsInformationComponent implements OnInit, OnDestroy {
	dataSource: BehaviorSubject<Array<Entity>> = new BehaviorSubject<
		Array<Entity>
	>([]);
	isDisabled: boolean = false;
	displayedColumns: Array<string> = ['shares', 'name', 'actions'];
	entity?: Entity;
	processing: boolean = false;
	action?: 'add' | 'update';
	justificationFormControl: FormControl = new FormControl();
	showJustification: boolean = false;
	markedForDelete: Set<Entity> = new Set<Entity>();
	systemUser$: BehaviorSubject<string | undefined>
	primaryContact: string | undefined

	questions: Array<ConfigQuestion> = [];
	currentStep: string = 'directors';
	private entityUtils = new EntityUtils();
	private unsubscribe: BehaviorSubject<boolean> =
		new BehaviorSubject<boolean>(false);

	private shareholders: Array<Shareholder | Entity> = [];

	constructor(
		private readonly stepperQueries: StepperQueries,
		private readonly directorsQueries: DirectorsQueries,
		private readonly shareholdersQueries: ShareholdersQueries,
		private readonly entityQueries: EntityQueries,
		private readonly configQueries: ConfigQueries,
		private readonly http: HttpService,
		private readonly dialog: MatDialog
	) {
		this.systemUser$ = http.systemUser
		this.primaryContact = http.getPrimaryContact()
	}

	ngOnInit(): void {
		this.configQueries
			.getState()
			.pipe(
				takeWhile(() => !this.questions?.length),
				map((data) => data[this.currentStep])
			)
			.subscribe((questions) => (this.questions = questions));
		this.entityQueries
			.getState()
			.pipe(takeWhile(() => !this.unsubscribe.value))
			.subscribe((data: Entity) => {
				this.entity = data;
			});

		this.directorsQueries
			.getDirectorsByParent(this.entity?.id)
			.pipe(takeWhile(() => !this.unsubscribe.value))
			.subscribe((data: Array<Entity>) => {
				this.dataSource.next(data);
			});

		this.stepperQueries
			.getStep()
			.pipe(first())
			.subscribe((currentStepData: StepModel | undefined) => {
				this.isDisabled = currentStepData?.metadata?.disable ?? false;

				this.showJustification =
					this.entity?.profile?.onboarding?.latestReview?.review?.reviews?.find(
						(item) => item.section_id === currentStepData?.key
					)?.status === 'REJECTED';

				this.justificationFormControl.setValue(
					this.entity?.profile?.onboarding?.answers?.sections?.find(
						(item) => item.id === currentStepData?.key
					)?.answers?.justification
				);
			});
		this.shareholdersQueries
			.getShareholdersByParent(this.entity?.id)
			.pipe(takeWhile(() => !this.unsubscribe.value))
			.subscribe((data) => (this.shareholders = data));
	}

	onOpenDialog(data?: Entity): void {
		const dialogRef = this.dialog.open(DirectorInformationModalComponent, {
			maxWidth: '50%',
			disableClose: true,
			role: 'dialog',
			data: {
				questions: this.questions,
				selectedLegalPerson: data?.id,
				entity: this.entity,
				shareholders: this.shareholders,
				systemUser: this.systemUser$.value
			},
		});

		this.action = data ? 'update' : 'add';

		dialogRef.afterClosed().subscribe((response: Record<string, any>) => {
			if (response) {
				const responseEntity =
					this.entityUtils.mapQuestionsToEntity(response);

				// Temporarily add/update shareholder to display item in list
				this.directorsQueries.addUpdateDirector({
					...responseEntity,
					parent_id: this.entity?.id,
					id: response.entityId,
				});

				if (responseEntity?.profile?.shares) {
					this.shareholdersQueries.addUpdateShareholder({
						...responseEntity,
						parent_id: this.entity?.id,
						id: response.entityId,
						complete: true,
					});
				} else {
					this.shareholdersQueries.removeShareholder(
						response.entityId
					);
				}

				if (this.action === 'add') {
					this.http
						.addLegalEntity({
							entity: {
								...responseEntity,
								parent_id: this.entity?.id,
							},
							raw: response,
						})
						.subscribe(() => {
							// Reload directors/shareholders to reflect correct state
							this.entityQueries.reloadState();
						});
				} else {
					this.http
						.updateLegalEntity({
							entity: {
								...responseEntity,
								parent_id: this.entity?.id,
								id: response.entityId,
							},
							raw: response,
						})
						.subscribe(() => {
							// Reload directors/shareholders to reflect correct state
							this.entityQueries.reloadState();
						});
				}
			}

			this.action = undefined;
		});
	}

	onRemoveDirector(entity: Entity): void {
		this.directorsQueries.removeDirector(entity.id);
		this.http
			.removeCorporateSubEntity(entity.id, entity.parent_id ?? '')
			.subscribe(() => this.entityQueries.reloadState());
		this.markedForDelete.delete(entity);
	}

	handleDeleteClick(entity: Entity, remove?: boolean): void {
		if (remove) {
			this.markedForDelete.delete(entity);
		} else {
			this.markedForDelete.add(entity);
		}
	}

	onNextClick(): void {
		this.processing = true;
		if (this.dataSource.value.length) {
			this.directorsQueries
				.getState()
				.pipe(
					first(),
					switchMap((state) =>
						this.http.updateOnboardingAnswers(
							{
								id: 'directors',
								title: 'Legal Person Information',
								status: REVIEW_STATUS.ENTITY_UPDATED,
								answers: {
									directors: [
										...state.map((item) => {
											return {
												...item,
											};
										}),
									],
									justification:
										this.justificationFormControl.value,
								},
							},
							this.entity?.id ?? ''
						)
					),
					switchMap(() =>
						this.http.completeDirector(this.entity?.id ?? '')
					)
				)
				.subscribe(
					() => {
						this.entityQueries.loadState();
						this.processing = false;
					},
					(error) => {
						this.processing = false;
					}
				);
		}
	}

	onPreviousClick(): void {
		this.stepperQueries.navigateStep('prev');
	}

	ngOnDestroy(): void {
		this.unsubscribe.next(true);
	}

	isMarkedForDelete(index: number, item: Entity): boolean {
		return this.markedForDelete?.has(item) ?? false;
	}

	private mapFields(data: Entity): any {
		return {
			id: data.id.length > 0 ? data.id : undefined,
			parent_id: this.entity?.id,
			individual: {
				first_name: data.first_name ?? undefined,
				last_name: data.last_name ?? undefined,
				email: data.email ?? undefined,
				mobile: data.mobile ?? undefined,
				title: data.profile?.title ?? undefined,
				director: data.profile?.director ?? undefined,
				shareholder: data.profile?.shareholder ?? undefined,
				shares: data.profile?.shares ?? undefined,
				tax_number: data.profile?.tax_number ?? undefined,
			},
			address: {
				street: data.address?.street ?? undefined,
				sub_street: data.address?.sub_street ?? undefined,
				city: data.address?.city ?? undefined,
				postcode: data.address?.postcode ?? undefined,
				country: data.address?.country ?? undefined,
				state: data.address?.state ?? undefined,
			},
		};
	}
}
