import {Injectable} from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    Router,
    RouterStateSnapshot,
    UrlTree
} from '@angular/router';
import {Observable} from 'rxjs';
import {HttpService} from "../services/http/http.service";

@Injectable({
    providedIn: 'root'
})
export class NavigationGuard implements CanActivate, CanActivateChild {
    constructor(private readonly http: HttpService,
                private readonly router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const token = this.http.getToken();
        this.http.getSystemUser();
        this.http.getEntityContact();

        if (token) {
            const entityType = this.http.getOnboardingAuthType();
            if (entityType !== 'corporate') {
                this.router.navigate(['/']);
                return false;
            } else {
                return true;
            }
        } else {
            this.router.navigate(['/otp']);
            return false;
        }
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.router.navigated) {
            this.router.navigate(['/']);
            return false;
        }

        return true
    }

}
