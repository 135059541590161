import { StepperConfigModel } from '../../../modules/stepper/models/stepper-config.model';
import { StepModel, StepModelMetadata } from '../../../modules/stepper/models/step.model';
import { OnboardingStateModel } from '../onboarding/onboarding.state';

export class GenerateConfig {
    static readonly type = '[stepper] generate config';

    constructor(public payload: OnboardingStateModel, public withNavigation = false) {
    }
}

export class FetchStepperConfig {
    static readonly type = '[stepper] fetch config';

    constructor() {
    }
}

export class UpdateConfig {
    static readonly type = '[stepper] update config';

    constructor(public payload: StepperConfigModel) {
    }
}

export class UpdateStepMetadata {
    static readonly type = '[stepper] update step metadata';

    constructor(public payload: { metadata: StepModelMetadata, stepKey: string, parentStepKey?: string }) {
    }
}

export class UpdateStep {
    static readonly type = '[stepper] update single step';

    constructor(public payload: { step: StepModel; parentStepKey?: string }) {
    }
}

export class NavigateToStep {
    static readonly type = '[stepper] navigate to specific step';

    constructor(public payload: { stepKey: string; parentStepKey?: string }) {
    }
}

export class Navigate {
    static readonly type = '[stepper] navigate to nex/prev step';

    constructor(public payload: { direction: 'next' | 'prev' }) {
    }
}
