import { Component, Input, OnInit, Self } from '@angular/core';
import { ConfigQuestion } from '../../models/config-question.interface';
import {
	ControlValueAccessor,
	FormArray,
	FormControl,
	FormGroup,
	NgControl,
} from '@angular/forms';
import { ERROR_MESSAGES } from '../../constants/enum.const';

@Component({
	selector: 'bcb-generic-input',
	templateUrl: './generic-input.component.html',
	styleUrls: ['./generic-input.component.scss'],
})
export class GenericInputComponent implements ControlValueAccessor, OnInit {
	@Input() question!: ConfigQuestion;
	@Input() errorMessage?: ERROR_MESSAGES;
	prefixIcon?: string;
	suffixIcon?: string;
	hint?: { horizontalPosition: 'start' | 'end'; value: string };

	control!: FormControl;
	parent!: FormGroup | FormArray | null;
	disabled!: boolean;

	constructor(@Self() private readonly controlDirective: NgControl) {
		controlDirective.valueAccessor = this;
	}

	onChange: (value: any) => void = () => {};

	onTouched: () => void = () => {};

	ngOnInit(): void {
		this.control = this.controlDirective.control as FormControl;
		this.parent = this.control.parent;

		this.prefixIcon = this.question.attributes?.prefixIcon;
		this.suffixIcon = this.question.attributes?.suffixIcon;
		this.hint = this.question.attributes?.hint;
	}

	writeValue(value: any): void {
		value &&
			this.controlDirective.control?.setValue(value, {
				emitEvent: false,
			});
	}

	registerOnChange(onChange: (value: any) => void): void {
		this.onChange = onChange;
	}

	registerOnTouched(onTouched: () => void): void {
		this.onTouched = onTouched;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
