import { Component } from '@angular/core';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { ConfigQueries } from "../../../../core/store/config/config.queries";
import { EntityQueries } from "../../../../core/store/entity/entity.queries";
import { HttpService } from "../../../../core/services/http/http.service";
import { OnboardingQueries } from "../../../../core/store/onboarding/onboarding.queries";
import { StepperQueries } from "../../../../core/store/stepper/stepper.queries";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OnboardingSection } from "../../../../core/models/onboarding.interface";
import { REVIEW_STATUS } from "../../../../core/constants/enum.const";
import { Observable } from "rxjs";

@Component({
	template: ``
})
export class BaseDueDiligenceComponent extends BasePageComponent {
	componentLayoutClassList: string[] = ['col-12'];
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onStepComplete(): Observable<any> {
		return this.http
			.dueDiligence({
				entity_id: this.entity?.id,
				step: this.currentStep.key,
				title: this.currentStep.title,
				values: this.questionsConfig.getFormValuesMap(),
			})
	}

	formatOnboardingAnswersSection(): OnboardingSection {
		let _parentKey = this.parentStep?.key
		if(_parentKey === 'due_diligence_form') {
			_parentKey = 'due_diligence'
		}

		return {
					id: _parentKey ?? this.currentStep.key,
					title: this.parentStep?.title ?? this.currentStep.title,
					sections: [
						{
							id: this.currentStep.key,
							title: this.currentStep.title,
							status: REVIEW_STATUS.ENTITY_UPDATED,
							answers: this.formatOnboardingAnswers(),
						},
					],
				}
	}
	formatOnboardingAnswers(): { justification: string, [key: string]: any } {
		return {
			...this.questionsConfig.getFormValuesMap(),
			justification:
			this.justificationFormControl.value
		}
	}

}
