<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Welcome to BCB Group Onboarding</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
        <div class="row">
            <h3>An OTP verification code has been sent to you.</h3>
            <p>{{message}}</p>
            <br>
            <p>OTP Code</p>
            <div class="row">
                <div class="col-lg-9">
                    <ng-otp-input (onInputChange)="onOtpChange($event)"
                                  (keyup.enter)="onSubmit()"
                                  [config]="{length:5, allowNumbersOnly: true, disableAutoFocus: false}"></ng-otp-input>
                </div>
                <div class="col-lg-3">
                    <p>
                        Didn't receive the code?<br>
                        <a style="cursor:pointer; text-decoration: underline" (click)="sendOTP()">Send code again</a>
                    </p>
                </div>
            </div>
        </div>

    </div>

    <div class="spacer"></div>

    <div class="btn-bar">
        <button mat-flat-button class="bcb-btn primary"
                type="submit"
                (click)="onSubmit()"
                [disabled]="verificationForm.invalid"
                *ngIf="!loading && !error">Submit
        </button>
        <button mat-stroked-button class="bcb-btn"
                [disabled]="error"
                [title]="error"
                *ngIf="error">Error
        </button>
        <button mat-stroked-button class="bcb-btn" *ngIf="loading" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>Loading...
        </button>
    </div>
</div>
