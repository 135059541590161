import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { environment } from '../../../environments/environment';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { StepperState } from './stepper/stepper.state';
import { OnboardingState } from './onboarding/onboarding.state';
import { StepperQueries } from './stepper/stepper.queries';
import { EntityState } from './entity/entity.state';
import { OnboardingQueries } from './onboarding/onboarding.queries';
import { EntityQueries } from './entity/entity.queries';
import { DirectorsQueries } from './directors/directors.queries';
import { DirectorsState } from './directors/directors.state';
import { ShareholdersState } from './shareholders/shareholders.state';
import { ShareholdersQueries } from './shareholders/shareholders.queries';
import {ConfigState} from "./config/config.state";
import {ConfigQueries} from "./config/config.queries";

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		NgxsModule.forRoot(
			[
				OnboardingState,
				ConfigState,
				StepperState,
				EntityState,
				DirectorsState,
				ShareholdersState,
			],
			{
				developmentMode: !environment.production,
			}
		),
		NgxsReduxDevtoolsPluginModule.forRoot({name: 'Onboarding', disabled: environment.production}),
	],
	providers: [
		StepperQueries,
		OnboardingQueries,
		ConfigQueries,
		EntityQueries,
		DirectorsQueries,
		ShareholdersQueries,
	],
})
export class StoreModule {}
