import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Shareholder } from '../../../../../../../core/models/shareholder.interface';
import { ShareholdersQueries } from '../../../../../../../core/store/shareholders/shareholders.queries';
import { DirectorsQueries } from '../../../../../../../core/store/directors/directors.queries';
import { first } from 'rxjs/operators';
import { EntityUtils } from '../../../../../../../core/utils/entity.util';
import { HttpService } from '../../../../../../../core/services/http/http.service';
import { EntityQueries } from '../../../../../../../core/store/entity/entity.queries';
import { ENTITY_TYPE } from '../../../../../../../core/constants/enum.const';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'bcb-shareholder-information-step',
	templateUrl: './shareholder-information-step.component.html',
	styleUrls: ['./shareholder-information-step.component.scss'],
})
export class ShareholderInformationStepComponent implements OnChanges {
	@Input() shareholder?: Shareholder;
	@Input() linkedShareholders: Array<Shareholder> = [];
	@Input() totalShares: number = 100;
	@Input() disabled: boolean = false;
	displayedColumns = ['shares', 'name', 'actions'];
	addEditShareholder: boolean = false;
	editingShareholder: BehaviorSubject<Shareholder | undefined> =
		new BehaviorSubject<Shareholder | undefined>(undefined);
	entityType = ENTITY_TYPE;

	constructor(
		private readonly shareholderUtils: EntityUtils,
		private readonly shareholdersQueries: ShareholdersQueries,
		private readonly directorsQueries: DirectorsQueries,
		private readonly entityQueries: EntityQueries,
		private readonly http: HttpService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['disabled']?.currentValue === true) {
		}
	}

	onEditShareholder(shareholder: Shareholder): void {
		this.addEditShareholder = true;
		this.editingShareholder.next(shareholder);
	}

	onRemoveShareholder(id: string): void {
		this.shareholdersQueries.removeShareholder(id);
		this.directorsQueries
			.getDirector(id)
			.pipe(first())
			.subscribe(() => {
				if (this.shareholder) {
					this.http
						.removeCorporateSubEntity(id, this.shareholder?.id)
						.subscribe(() => this.entityQueries.loadSubEntities());
				}
			});
	}

	onAddNewClick(): void {
		this.addEditShareholder = true;
	}

	onSave(): void {
		this.reset();
		this.entityQueries.reloadState();
	}

	reset(): void {
		this.addEditShareholder = false;
		this.editingShareholder.next(undefined);
	}
}
