import { Component, OnInit } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Document, Entity } from '../../../../core/models/entity.model';
import { environment } from '../../../../../environments/environment';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { first } from 'rxjs/operators';
import { OnboardingStateModel } from '../../../../core/store/onboarding/onboarding.state';
import { StepModel } from '../../../stepper/models/step.model';

@Component({
    selector: 'bcb-verification-documents',
    templateUrl: './verification-documents.component.html',
    styleUrls: ['./verification-documents.component.scss']
})
export class VerificationDocumentsComponent implements OnInit {

    formGroup: FormGroup;
    entity?: Entity;

    progress: { [key: string]: number } = {};
    disabled: boolean = false;
    requiredDocuments: Array<{ type: string, label: string }> = [
        { type: 'national_identity_card', label: 'ID Document' },
        { type: 'proof_of_address', label: 'Proof of Address' }
    ];

    constructor(private readonly stepperQueries: StepperQueries,
                private readonly entityQueries: EntityQueries,
                private readonly onboardingQueries: OnboardingQueries,
                private readonly http: HttpService) {
        this.formGroup = new FormGroup({});
    }

    ngOnInit(): void {
        this.entityQueries.getState().pipe(first()).subscribe((entity) => this.entity = entity as Entity);
        this.onboardingQueries.getState().pipe(first()).subscribe((onboarding: OnboardingStateModel) => {
            this.requiredDocuments.forEach((doc) => {
                const allDocs: Array<any> = onboarding.uploaded_documents?.filter((item: any) => item.type === doc.type) ?? [];
                this.formGroup.addControl(doc.type,
                    new FormControl(allDocs[allDocs?.length - 1].filename ?? undefined, Validators.required));
            });
        });

        this.stepperQueries
            .getStep()
            .pipe(first())
            .subscribe((currentStepData: StepModel | undefined) => {
                if(currentStepData?.metadata?.disable ?? false) {
                    this.formGroup?.disable()
                    this.disabled = true
                }
            });
    }

    getErrorMessage(field: string): string | undefined {
        if (this.formGroup.get(field)?.touched) {
            if (
                this.formGroup.get(field)?.hasError('required')
            ) {
                return 'This field is required';
            } else {
                return 'Please provide a valid input';
            }
        }

        return undefined;
    }

    deleteFile(linkedControlName: string): void {
        this.formGroup.get(linkedControlName)?.reset();
    }

    fileChange({ files }: any, document: Document, linkedControlName: string): void {
        const reader = new FileReader();
        if (files && files.length > 0) {
            const file = files[0];
            this.formGroup.get(linkedControlName)?.setValue(file.name);
            reader.readAsDataURL(file);

            console.debug({ fileSize: file.size, limit: environment.maxFileUploadSizeBytes });

            reader.onload = () => {
                const response = this.constructDocument(file, reader.result, linkedControlName);
                this.upload(response, linkedControlName);
            };
        }
    }

    onNextClick(): void {
        this.entityQueries.loadState();
        this.stepperQueries.navigateStep('next');
    }

    onPreviousClick(): void {
        this.stepperQueries.navigateStep('prev');
    }

    private upload(data: CreateDocument, linkedControlName: string): void {
        this.http.uploadDocuments({
            documents: [data],
            entity_id: this.entity?.id,
            step: 'verification_documents'
        }).subscribe((res) => {
            if (res.type === HttpEventType.UploadProgress) {
                this.progress[linkedControlName] = Math.round(100 * res.loaded / res.total);
            } else if (res instanceof HttpResponse) {
                delete this.progress[linkedControlName];
            }
        });
    }

    private constructDocument(file: File, readerResult: string | ArrayBuffer | null, documentType: string): CreateDocument {
        return {
            filename: file.name,
            content_type: file.type,
            document: String(readerResult),
            type: documentType
        };
    }

}

interface CreateDocument {
    filename: string;
    content_type: string;
    document: string;
    type: string;
}
