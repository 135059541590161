import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import { Shareholder } from '../../../../../../../core/models/shareholder.interface';
import {
	Document,
	Entity,
} from '../../../../../../../core/models/entity.model';
import { ConfigQuestion } from '../../../../../../../core/models/config-question.interface';
import {ConfigQueries} from "../../../../../../../core/store/config/config.queries";
import { first, tap } from 'rxjs/operators';

@Component({
	selector: 'bcb-review-submit-step',
	templateUrl: './review-submit-step.component.html',
	styleUrls: ['./review-submit-step.component.scss'],
})
export class ReviewSubmitStepComponent implements OnInit, OnChanges {
	@Input() shareholder?: Shareholder;
	@Input() linkedDirectors: Array<Entity> = [];
	@Input() linkedShareholders: Array<Shareholder> = [];
	uploadDocumentQuestions: Array<ConfigQuestion> = [];
	documentsMap: Map<string, Array<Document>> = new Map();
	companyDocumentsKey: string = 'shareholders_documents'

	constructor(private readonly configQueries: ConfigQueries) {}

	ngOnInit(): void {
		this.configQueries
			.getState()
			.pipe(
				first(),
				tap((config) => {this.uploadDocumentQuestions = config[this.companyDocumentsKey]})
			)
			.subscribe();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['shareholder']?.currentValue) {
			if (this.shareholder?.profile?.onboarding?.documents) {
				// get all uploaded documents that aren't archived
				this.documentsMap = new Map(
					Object.entries(
						this.shareholder?.profile.onboarding?.documents
					).map(([key, value]) => [key, value.filter(item => !item.meta_data?.archived)])
				);
			}
		}
	}
}
