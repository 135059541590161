import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearConfig, UpdateConfig } from './config.actions';
import { EntityQueries } from '../entity/entity.queries';
import { ConfigQuestion } from '../../models/config-question.interface';
import { HttpService } from '../../services/http/http.service';
import { first, switchMap } from 'rxjs/operators';

const initialState: Record<string, Array<ConfigQuestion>> = {};

@State<Record<string, Array<ConfigQuestion>>>({
	name: 'config',
	defaults: { ...initialState },
})
@Injectable()
export class ConfigState implements NgxsOnInit {
	constructor(
		private readonly entityQueries: EntityQueries,
		private readonly http: HttpService
	) {}

	// ------------------------- Lifecycle--------------------------

	ngxsOnInit(ctx?: StateContext<any>): any {
		this.entityQueries
			.getState()
			.pipe(
				first((data) => data?.id?.length > 0),
				switchMap(() => this.http.getOnboardingConfig())
			)
			.subscribe((res) => ctx?.dispatch(new UpdateConfig(res)));
	}

	// -------------------------- Actions --------------------------

	@Action(UpdateConfig)
	updateOnboarding(
		{ patchState }: StateContext<Record<string, Array<ConfigQuestion>>>,
		{ payload }: UpdateConfig
	): void {
		patchState(payload);
	}

	@Action(ClearConfig)
	clearOnboarding({
		setState,
	}: StateContext<Record<string, Array<ConfigQuestion>>>): void {
		setState(initialState);
	}

	// -------------------------- Selectors --------------------------
	@Selector()
	getState(
		state: Record<string, Array<ConfigQuestion>>
	): Record<string, Array<ConfigQuestion>> {
		return state;
	}
}
