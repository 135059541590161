import {ConfigQuestion} from "../../models/config-question.interface";

export class UpdateConfig {
	static readonly type = '[config] set config';

	constructor(public payload: Record<string, Array<ConfigQuestion>>) {}
}

export class ClearConfig {
	static readonly type = '[config] clear config state';

	constructor() {}
}
