<bcb-generic-label [question]="question"></bcb-generic-label>
<mat-form-field appearance='outline'>
    <mat-select [formControl]='formControl' [compareWith]="compareSelectOptions" [multiple]="question.attributes?.multiple"
                (valueChange)="onChanged($event)" (blur)="touched()">
        <mat-option [value]="option" *ngFor="let option of question.options">
            {{option.label}}
        </mat-option>
    </mat-select>
    <mat-error
            *ngIf="formControl?.invalid">{{errorMessage}}</mat-error>
</mat-form-field>