import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { ConfigQuestion } from '../../models/config-question.interface';
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ERROR_MESSAGES } from '../../constants/enum.const';
import { BehaviorSubject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
	selector: 'bcb-generic-currency',
	templateUrl: './generic-currency.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: GenericCurrencyComponent,
		},
	],
	styleUrls: ['./generic-currency.component.scss'],
})
export class GenericCurrencyComponent
	implements ControlValueAccessor, OnInit, OnDestroy
{
	@Input() question!: ConfigQuestion;
	@Input() formGroup!: FormGroup;
	@Input() formGroupName!: string;
	@Input() errorMessage: ERROR_MESSAGES | undefined;
	@Output() change: EventEmitter<any> = new EventEmitter<any>();

	value: any;
	isDisabled: boolean = false;
	unsubscribe: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor() {}

	changed: (value: any) => void = () => {};

	touched: () => void = () => {};

	writeValue(obj: any): void {
		this.value = obj;
	}

	onChange(): void {
		const val = this.formGroup?.get(this.formGroupName)?.value;
		this.changed(val);
		this.change.emit(val);
	}

	registerOnChange(fn: any): void {
		this.changed = fn;
	}

	registerOnTouched(fn: any): void {
		this.touched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	ngOnDestroy(): void {
		this.unsubscribe.next(true);
	}

	ngOnInit(): void {
		this.formGroup
			.get(this.formGroupName)
			?.valueChanges.pipe(takeWhile(() => !this.unsubscribe.value))
			.subscribe((data) => {
				this.formGroup.get(this.formGroupName)?.patchValue(
					{
						amount: this.formatAmount(data.amount),
					},
					{ emitEvent: false }
				);
			});
	}

	private formatAmount(amount: string): string {
		if (amount?.length) {
			const decimalIndex = amount.indexOf('.');
			let result = amount.replace(/[^0-9]/g, '');
			if (decimalIndex > 0) {
				return (
					result.substring(0, decimalIndex) +
					'.' +
					result.substring(decimalIndex, result.length)
				);
			}
			return result;
		}
		return amount;
	}
}
