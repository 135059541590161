<bcb-generic-label [question]="question"></bcb-generic-label>
<mat-form-field appearance='outline'>
    <mat-icon *ngIf="prefixIcon" matPrefix>{{prefixIcon}}</mat-icon>
    <input matInput
           [formControl]="control"
           (change)="onChange($any($event.target).value)"
           (input)="onChange($any($event.target).value)"
           (blur)="onTouched()">
    <mat-icon *ngIf="suffixIcon" matSuffix>{{suffixIcon}}</mat-icon>
    <mat-hint *ngIf="hint" [align]='hint?.horizontalPosition'>{{hint}}</mat-hint>
    <mat-error
            *ngIf="control?.invalid">{{errorMessage}}</mat-error>
</mat-form-field>