import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from "rxjs/operators";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor(private router: Router,
                private readonly _snackBar: MatSnackBar) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(request).pipe(catchError((response: any) => {
            if (response instanceof HttpErrorResponse && response.status === 401) {
                sessionStorage.removeItem('onboarding_token');
                this.router.navigate(['/error/401'], {
                    state: {
                        title: 'Unauthorised',
                        message: response.error?.message ?? 'If you require assistance please contact us at onboarding@bcbgroup.io.'
                    }
                });
            }

            this._snackBar.open(
                `${response?.error?.message ?? 'Something went wrong'}`,
                'Dismiss',
                {
                    duration: 7500,
                    verticalPosition: 'top',
                    horizontalPosition: 'right',
                    panelClass: 'bg-danger',
                }
            );
            return throwError(response);
        }));
    }
}
