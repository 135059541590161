<div class='page'>
    <div class='content'>
        <div class='row'>
            <h1 class='col page__title'>Welcome to BCB Group Onboarding</h1>
            <mat-divider></mat-divider>
        </div>
        <p>Thank you for your interest in BCB Group and for choosing us to be your business partner. As part of our onboarding
            process, we will ask for detailed KYC and conduct enhanced due diligence on you and your business. Here at BCB, we
            consider regulatory compliance to be an important prerequisite in any business relationship. We hope that our portal
            will enable you to provide us with all the information required to launch a successful business relationship with
            us.</p>
        <p>Upon receipt of your application, your files will undergo internal review by our onboarding team.</p>
        <p>Please feel free to reach out to our team with any questions during the process at:<br /><a
            href='mailto:csonboarding@bcbgroup.io'>csonboarding@bcbgroup.io</a></p>
        <p><strong>You will need to provide the following:</strong></p>
        <ol class='bullet-primary'>
            <li>Board Resolution</li>
            <li>Corporate structure diagram</li>
            <li>Certificate of Incorporation, Articles of Association, Director and Shareholders Register for the entity
                being onboarded and any corporate shareholder with >25% ownership
            </li>
            <li title='*For proof of identity we require certified copy passport scans. For proof of address, we can accept a certified copy high-resolution, utility bill, local tax document or other government-issued document confirming the residential address, provided that the document is no more than 90 days old. Please note we cannot accept bank statements or mobile phone bills. The following documents are available to assist you in completing the above forms.'>
                KYC of directors, individuals with significant control (>25% ultimate ownership), and authorised
                signatories*
            </li>
            <li>Company AML documentation</li>
            <li>Most recently audited accounts (or, if audited accounts are unavailable, most statutory accounts or
                management accounts)
            </li>
<!-- ===== Remove tax forms (https://app.clickup.com/t/2176429/TECH-10416) ===== -->
<!--            <li>The following documents downloaded, completed, and sent back to BCB:-->
<!--            </li>-->
        </ol>

<!-- ===== Remove tax forms (https://app.clickup.com/t/2176429/TECH-10416) ===== -->
<!--        <p class='mb-0'>In addition to completing all the onboarding steps, the following documents will need to be downloaded,-->
<!--            completed, and sent back to BCB.</p>-->
<!--        <ul class='mt-0'>-->
<!--            <li class='mt-2'><a href='assets/pdf/AIA-Form.pdf' download='AIA'>AIA</a></li>-->
<!--            <li title='**Please note as the W8-BEN-E form is for the USA the date needs to be in US format MM-DD-YYYY'>-->
<!--                <a href='assets/pdf/fw8bene.pdf' download='W8-BEN-E'>W8-BEN-E</a>**-->
<!--            </li>-->
<!--            <li>If the company is <b>not</b> operational or is a startup: <a-->
<!--                href='assets/pdf/Form_A_ENG_V202308.pdf'-->
<!--                download='Form A'>Form A</a></li>-->
<!--            <li>If the company is operational and is not a startup: <a href='assets/pdf/Form_K_ENG_V202308.pdf' download='Form K'>Form-->
<!--                K</a></li>-->
<!--            <li>If the onboarding entity (or ownership structure) includes a Foundation: <a href='assets/pdf/Form_S_ENG_V1.0.pdf' download='Form S'>Form-->
<!--                S</a></li>-->
<!--            <li>If the onboarding entity (or ownership structure) includes a Trust: <a href='assets/pdf/Form_T_ENG_V1.0.pdf' download='Form T'>Form-->
<!--                T</a></li>-->
<!--        </ul>-->

<!--        <p class='mb-0'><small><i>The following documents are available to assist you in completing the above forms.</i></small>-->
<!--        </p>-->
<!--        <ul class='mt-0'>-->
<!--            <li class='mt-0'><small><a href='assets/pdf/Instructions%20for%20Form%20W-8BEN-E.pdf'-->
<!--                                       download='Instructions for Form W8-BEN-E'>Instructions-->
<!--                for Form W8-BEN-E</a></small></li>-->
<!--            <li class='mt-2'><small><a href='assets/pdf/Form_K_ENG_V202308.pdf'-->
<!--                                       download='Form K Guide Template'>Form K Guide-->
<!--                Template</a></small></li>-->
<!--        </ul>-->

        <p class='mb-0'>For proof of identity we require certified copy passport scans.<br>
            For proof of address, we require any of the following:</p>
        <ul class='mt-0'>
            <li class='mt-2'>Bank Statement (e.g. current account or savings account)</li>
            <li>Credit Card Statement</li>
            <li>Rental Agreement (stating the start date and end date of the tenancy)</li>
            <li>Letter Issued by a Government Agency (issued within 3 months)</li>
            <li>Mortgage Statement</li>
            <li>Utility Bill (e.g. gas, electric, water, internet)</li>
        </ul>
    </div>

<!-- ===== Remove tax forms (https://app.clickup.com/t/2176429/TECH-10416) ===== -->
<!--    <p>**Please note as the W8-BEN-E form is for the USA the date needs to be in US format MM-DD-YYYY</p>-->

    <div class='spacer'></div>

    <div class='btn-bar'>
        <button mat-flat-button class='bcb-btn primary' (click)='onNextClick()'>Next</button>
    </div>
</div>
