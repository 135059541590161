<bcb-generic-label [question]="question"></bcb-generic-label>
<div [formGroup]="formGroup">
  <div class="mat-form-field-wrapper" [formGroupName]="question.key">
    <div *ngFor="let option of question.options">
      <mat-checkbox
        (change)="onChange()"
        [formControlName]="option.key"
        [checked]="value">{{ option.label }}</mat-checkbox>
    </div>
    <mat-error
      *ngIf="formGroup.get(question.key)?.invalid">{{errorMessage}}</mat-error>
  </div>
</div>