<bcb-generic-label [question]="question"></bcb-generic-label>
<mat-form-field class='country-select' appearance='outline'>
    <mat-select class='country-select'
                [formControl]='formControl'
                [compareWith]="compareSelectOptions"
                (openedChange)='onMultiSelectOpenChange($event)'
                (selectionChange)="onChanged($event)"
                (blur)="touched()"
                [multiple]="question.attributes?.multiple || false">
        <mat-select-trigger>
            <ng-container *ngIf="question.attributes?.multiple">
                {{formGroup.get(question.key)?.value?.length > 2 ? (formGroup.get(question.key)?.value?.length + ' countries selected') : formGroup.get(question.key)?.value | OAKeyValue: question?.attributes?.useSelectKey ?? 'name'}}
            </ng-container>
            <ng-container *ngIf="!question.attributes?.multiple">
                {{question?.attributes?.useSelectKey ? formGroup.get(question.key)?.value && formGroup.get(question.key)?.value[question?.attributes?.useSelectKey] : formGroup.get(question.key)?.value?.name}}
            </ng-container>
        </mat-select-trigger>
<!--        <div class='select-controls'>-->
<!--            <mat-form-field appearance='legacy'>-->
<!--                <mat-icon matPrefix>search</mat-icon>-->
<!--                <input class='select-input' matInput (keyup)='onFilterTextChange($event.target)'>-->
<!--            </mat-form-field>-->
<!--        </div>-->
        <mat-option [value]='country' *ngFor='let country of displayCountries'
                    class='country-select-option'>
            <img class='select-image'
                 [src]='country.flag_url'
                 [alt]='country.name'>
            {{question?.attributes?.useSelectKey ? country[question?.attributes?.useSelectKey] : country.name}}
        </mat-option>
    </mat-select>
    <mat-error
            *ngIf="formControl?.invalid">{{errorMessage}}</mat-error>
</mat-form-field>