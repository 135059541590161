import { Component, OnInit } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { first } from 'rxjs/operators';
import { Entity } from '../../../../core/models/entity.model';

@Component({
	selector: 'bcb-welcome',
	templateUrl: './welcome.component.html',
	styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
	entity?: Entity;
	reloadEntity: boolean = false;

	constructor(
		private readonly stepperQueries: StepperQueries,
		private readonly entityQueries: EntityQueries,
		private readonly http: HttpService
	) {}

	ngOnInit(): void {
		this.entityQueries
			.getState()
			.pipe(first())
			.subscribe((entity) => (this.entity = entity as Entity));
		if (!this.entity?.profile?.onboarding?.due_diligence_form?.steps) {
			this.http.completeWelcome(this.entity?.id ?? '').subscribe();
			this.reloadEntity = true;
		}
	}

	onNextClick(): void {
		if (this.reloadEntity) {
			this.entityQueries.loadState();
		} else {
			this.stepperQueries.navigateStep('next');
		}
	}
}
