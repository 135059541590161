import { Injectable } from "@angular/core";
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {FormGroup} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {Observable, Subject} from "rxjs";
import {StepperQueries} from "../store/stepper/stepper.queries";
import {ConfirmationModalComponent} from "../../modules/pages/_general/confirmation-modal/confirmation-modal.component";
import { HttpService } from "../services/http/http.service";
import { EntityQueries } from "../store/entity/entity.queries";

@Injectable({
    providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<any> {
    constructor(private readonly dialog: MatDialog,
                private readonly stepperQueries: StepperQueries,
                private entityQueries: EntityQueries,
                private readonly http: HttpService) {
    }

    canDeactivate(component: { [key: string]: FormGroup },
                  currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot,
                  nextState?: RouterStateSnapshot): boolean | Observable<boolean> {

        if(this.http.systemUser.value) {
            this.entityQueries.reloadState(false)
            return true
        }

        const forms = this.getFormGroups(component);

        if (forms.some((item: FormGroup) => item.dirty)) {
            const subject = new Subject<boolean>();

            const dialogRef = this.dialog.open(ConfirmationModalComponent, {
                disableClose: true,
                data: {
                    title: 'Changes have been detected',
                    bodyContent: [
                        {
                            content: 'If you proceed these changes will be lost.'
                        }
                    ]
                }
            })

            dialogRef.afterClosed().subscribe((res: boolean) => {
                if (!res) {
                    this.stepperQueries.rollback();
                }
                subject.next(res)
            });

            return subject.asObservable();
        }

        return true;
    }

    private getFormGroups(obj: any): Array<FormGroup> {
        const keys: Array<string> = Object.keys(obj);
        const matchedKeys: Array<string> = keys.filter((key: string) => obj[key] instanceof FormGroup);

        return matchedKeys.map((key: string) => obj[key]);
    }

}
