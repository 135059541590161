import { OnboardingStateModel } from './onboarding.state';

export class LoadOnboarding {
	static readonly type = '[onboarding] load entities';

	constructor() {}
}

export class UpdateOnboarding {
	static readonly type = '[onboarding] set onboarding';

	constructor(public payload: OnboardingStateModel) {}
}

export class ClearOnboarding {
	static readonly type = '[onboarding] clear onboarding state';

	constructor() {}
}
