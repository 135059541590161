import { Component, Input, OnDestroy, OnInit, Self } from '@angular/core';
import {
	ControlValueAccessor,
	FormArray,
	FormControl,
	FormGroup,
	NgControl,
} from '@angular/forms';
import { ConfigQuestion } from '../../models/config-question.interface';
import { ERROR_MESSAGES } from '../../constants/enum.const';
import { Country } from '../../models/country.interface';
import { StaticData } from '../../constants/static.const';
import { ArrayUtils } from '../../utils/array.util';
import { Subject } from 'rxjs';
import { PhoneNumber } from 'libphonenumber-js';

@Component({
	selector: 'bcb-generic-mobile',
	templateUrl: './generic-mobile.component.html',
	styleUrls: ['./generic-mobile.component.scss'],
})
export class GenericMobileComponent
	implements ControlValueAccessor, OnInit, OnDestroy
{
	@Input() question!: ConfigQuestion;
	@Input() errorMessage?: ERROR_MESSAGES;
	// @Output() change: EventEmitter<string> = new EventEmitter<string>();
	control!: FormControl;
	parent!: FormGroup | FormArray | null;
	disabled!: boolean;
	unsubscribe$: Subject<void> = new Subject();

	allCountries: Array<Country> = ArrayUtils.sortObject(
		StaticData.countries,
		'name'
	);

	phoneNumber?: PhoneNumber;
	selectedCountry?: Country;
	readonly defaultCountryCallCode: number = 44;

	constructor(@Self() private readonly controlDirective: NgControl) {
		controlDirective.valueAccessor = this;
	}

	ngOnDestroy(): void {
		this.unsubscribe$.next();
	}

	ngOnInit(): void {
		this.control = this.controlDirective.control as FormControl;
		this.parent = this.control.parent;
	}

	onChange: (value: any) => void = () => {};

	onTouched: () => void = () => {};

	writeValue(value: any): void {
		if (!value) {
			return;
		}
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}

	setDisabledState(disabled: boolean): void {
		this.disabled = disabled;
	}
}
