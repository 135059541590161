<div class="container">
    <div class="row w-100">
        <div class="stepper" *ngIf="showStepper" [class.hidden]="hideStepper">
            <bcb-stepper></bcb-stepper>
        </div>
        <div class="col page-outlet">
            <mat-card class='note mat-elevation-z2' [class.note-danger]='stepMetadata?.status' *ngIf='stepMetadata?.comment as comment'>{{ comment }}</mat-card>
            <mat-card *ngIf="shouldShowWarning" class='alert mat-elevation-z0 alert-warning'>Failing to provide all relevant information may delay your onboarding.</mat-card>
            <router-outlet></router-outlet>

            <div class='fixed bottom-right' *ngIf='showFab'>
                <button mat-fab color='primary' class='btn-outline-primary' [class.extended-fab]='extendFab' (click)='scrollTop()' (mouseenter)='extendFab = true' (mouseleave)='extendFab = false'>
                    <mat-icon>arrow_upward</mat-icon>
                    <span class='ml-3' *ngIf='extendFab'>back to top</span>
                </button>
            </div>
        </div>
    </div>
</div>
