import { Component, OnInit } from '@angular/core';
import { StepModel } from './models/step.model';
import { StepperConfigModel } from './models/stepper-config.model';
import { StepperQueries } from '../../core/store/stepper/stepper.queries';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EntityQueries } from '../../core/store/entity/entity.queries';
import { Entity } from '../../core/models/entity.model';
import { APP_ROUTES } from '../../core/constants/enum.const';

@Component({
	selector: 'bcb-stepper',
	templateUrl: './stepper.component.html',
	styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
	config: StepperConfigModel = { currentStep: '', steps: [] };
	expandedSubSection: string = '';

	entity?: Entity;

	constructor(
		private readonly stepperQueries: StepperQueries,
		private readonly entityQueries: EntityQueries,
		private readonly router: Router
	) {}

	ngOnInit(): void {
		this.stepperQueries.getState().subscribe((res: StepperConfigModel) => {
			this.config = {
				...res,
				steps: res.steps
					.filter((step: StepModel) => step.show)
					.map((step: StepModel) => {
						if (step.subSteps) {
							return {
								...step,
								subSteps: [
									...step.subSteps.filter(
										(subStep) => subStep.show
									),
								],
							};
						}
						return step;
					}),
			};

			this.entityQueries
				.getState()
				.pipe(first())
				.subscribe((entity) => (this.entity = entity as Entity));

			if (this.entity) {
				const path = res.currentStep;

				if (path === APP_ROUTES.completed) {
					window.location.href = '/completed';
				}

				if (path) {
					this.router.navigate(
						[`/onboarding/${this.entity.type}/${path}`],
						{
							skipLocationChange: true,
						}
					);
				}
			}
		});
	}

	onStepClick(step: StepModel, parentStepKey?: string): void {
		if (step.subSteps) {
			this.expandedSubSection = step.key;
			return;
		} else if (!parentStepKey) {
			this.expandedSubSection = '';
		}

		this.stepperQueries.navigateToStep(step.key, parentStepKey);
	}
}
