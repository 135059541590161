import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Country } from '../../../../../../../core/models/country.interface';
import { ArrayUtils } from '../../../../../../../core/utils/array.util';
import { StaticData } from '../../../../../../../core/constants/static.const';
import { Entity } from '../../../../../../../core/models/entity.model';
import { EntityUtils } from '../../../../../../../core/utils/entity.util';
import { ShareholdersQueries } from '../../../../../../../core/store/shareholders/shareholders.queries';
import { Shareholder } from '../../../../../../../core/models/shareholder.interface';
import { HttpService } from '../../../../../../../core/services/http/http.service';
import { ENTITY_TYPE } from 'src/app/core/constants/enum.const';
import { ConfigQuestion } from 'src/app/core/models/config-question.interface';
import { ConfigUtil } from 'src/app/core/utils/config.util';
import { first, map, takeWhile } from 'rxjs/operators';
import { LegalEntityDetailsContentComponent } from './legal-entity-details-content/legal-entity-details-content.component';
import { EntityQueries } from '../../../../../../../core/store/entity/entity.queries';
import { ConfigQueries } from '../../../../../../../core/store/config/config.queries';
import { BehaviorSubject } from "rxjs";

@Component({
	selector: 'bcb-legal-entity-details-step',
	templateUrl: './legal-entity-details-step.component.html',
	styleUrls: ['./legal-entity-details-step.component.scss'],
})
export class LegalEntityDetailsStepComponent
	implements OnInit, OnChanges, OnDestroy
{
	@Input() parent?: Entity;
	@Input() isNestedContent: boolean = false;
	@Input() shareholder?: Shareholder;
	@Input() disabled: boolean = false;
	@Output() entitySaved: EventEmitter<void> = new EventEmitter<void>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();
	canSubmit: boolean = false;
	$systemUser: BehaviorSubject<string | undefined>
	allCountries: Array<Country> = ArrayUtils.sortObject(
		StaticData.countries,
		'name'
	);
	data!: {
		entity: Entity;
		questions: Array<ConfigQuestion>;
		selectedLegalPerson?: string;
		shareholders?: Array<Shareholder | Entity>;
		systemUser?: string
	};
	remainingShares: number = 100;
	loading: boolean = false;
	questionsConfig: ConfigUtil;
	formGroup: FormGroup
	visibleControls: Set<string>
	currentStep: string = 'shareholders';
	unsubscribe: boolean = false;
	questions: Array<ConfigQuestion> = [];
	@ViewChild(LegalEntityDetailsContentComponent, { static: false })
	private formContent!: LegalEntityDetailsContentComponent;
	private onboardingEntity!: Entity;

	constructor(
		private readonly entityUtils: EntityUtils,
		private readonly shareholdersQueries: ShareholdersQueries,
		private readonly entityQueries: EntityQueries,
		private readonly configQueries: ConfigQueries,
		private readonly http: HttpService
	) {
		this.questionsConfig = new ConfigUtil(this.questions);
		this.formGroup = this.questionsConfig.formGroup;
		this.visibleControls = this.questionsConfig.visibleControls;
		this.$systemUser = http.systemUser
	}

	ngOnInit(): void {
		this.configQueries
			.getState()
			.pipe(
				takeWhile(() => !this.questionsConfig.unsubscribe.value),
				map((config) => config[this.currentStep])
			)
			.subscribe(
				(questions: Array<ConfigQuestion>) => {
					this.questionsConfig = new ConfigUtil(questions);
					this.questions = this.questionsConfig.questions.getValue()
					this.formGroup = this.questionsConfig.formGroup;
					this.visibleControls = this.questionsConfig.visibleControls;

					this.data.questions = questions
				}
			);
		if (this.shareholder) {
			this.entityUtils
				.getRemainingSharesForParent(
					this.shareholder,
					this.shareholder.parent_id ? 'parent_id' : 'id'
				)
				.then((res: number) => {
					this.remainingShares = res;
				});
		}
		this.entityQueries
			.getState()
			.pipe(first())
			.subscribe((entity) => (this.onboardingEntity = entity));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['disabled']?.currentValue === true) {
			this.formGroup?.disable();
		}
		if (changes['parent']?.firstChange) {
			let parentShareholders: Array<Shareholder> = [];

			this.shareholdersQueries
				.getShareholdersByParent(this.parent?.id)
				.pipe(takeWhile(() => !this.unsubscribe))
				.subscribe(
					(shareholders) => (parentShareholders = shareholders)
				);

			if (this.parent) {
				this.data = {
					entity: this.parent,
					questions: this.questions,
					selectedLegalPerson: this.shareholder?.id,
					shareholders: parentShareholders,
				};
			}
		}
		if (changes['shareholder']?.currentValue) {
			this.data.selectedLegalPerson = this.shareholder?.id;
		}
		this.data.systemUser = this.$systemUser.value
	}

	triggerSubmit(): void {
		this.formContent.onSubmit();
	}

	onSubmit(formValue: Record<string, any>): void {
		if (this.canSubmit || (this.$systemUser.value)) {
			this.loading = true;
			const _entity: Entity | Shareholder = {
				...this.entityUtils.mapQuestionsToEntity(
					formValue,
					ENTITY_TYPE.sub_corporate
				),
				parent_id: this.parent?.id,
			};

			if (this.shareholder?.id) {
				_entity.id = this.shareholder.id;
			}

			const payload = {
				entity: _entity,
				raw: formValue,
				onboardingEntityId: this.onboardingEntity.id,
			};

			if (this.shareholder?.id) {
				this.http.updateLegalEntity(payload).subscribe(
					() => {
						this.loading = false;
						this.entityQueries.reloadState();

						this.entitySaved.emit();
					},
					(e) => (this.loading = false)
				);
			} else {
				this.http.addLegalEntity(payload).subscribe(
					(entity: Entity) => {
						this.loading = false;
						this.entityQueries.reloadState();

						this.entitySaved.emit();
					},
					(e) => (this.loading = false)
				);
			}
		}
	}

	onCancelClick(): void {
		this.cancel.emit();
	}

	ngOnDestroy(): void {
		this.unsubscribe = true;
		this.questionsConfig.unsubscribe.next(this.unsubscribe);
	}
}
