<div [formGroup]="formGroup" *ngIf="question?.componentList">
    <bcb-generic-label [question]="question"></bcb-generic-label>
    <ng-container *ngFor="let componentQuestion of getConfigQuestions(question?.componentList)">
        <ng-container [ngSwitch]="componentQuestion.type">
            <div [formGroupName]="question.key">
                <bcb-generic-percentage class="col-md-12 lbl-stacked"
                                        *ngSwitchCase="configQuestionType.PERCENTAGE"
                                        [question]="componentQuestion"
                                        [formControlName]="componentQuestion.key"
                                        [errorMessage]="questionsConfig.getErrorMessage(componentQuestion.key)"
                >
                </bcb-generic-percentage>
            </div>
        </ng-container>
    </ng-container>
</div>