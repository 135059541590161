import { Component } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { Observable } from "rxjs";
import { HttpService } from '../../../../core/services/http/http.service';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { OnboardingQueries } from "../../../../core/store/onboarding/onboarding.queries";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
	selector: 'bcb-entity-registered-address',
	templateUrl: './entity-registered-address.component.html',
	styleUrls: ['./entity-registered-address.component.scss'],
})
export class EntityRegisteredAddressComponent extends BasePageComponent {
	componentLayoutClassList: string[] = ['col-12'];
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onStepComplete(): Observable<any> {
		return this.http
			.updateAddress({
				...this.entityUtils.mapQuestionsToEntity(
					this.formGroup.value
				)?.address,
				entity_id: this.entity?.id,
			})
	}

}