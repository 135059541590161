import { Component } from '@angular/core';

@Component({
    selector: 'bcb-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    year: string = new Date().getFullYear().toString();
}
