import {
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { ConfigUtil } from '../../../../../core/utils/config.util';
import { FormGroup } from '@angular/forms';
import { ConfigQuestionType } from '../../../../../core/constants/enum.const';
import { Country } from '../../../../../core/models/country.interface';
import { ArrayUtils } from '../../../../../core/utils/array.util';
import { StaticData } from '../../../../../core/constants/static.const';
import { Entity } from '../../../../../core/models/entity.model';
import { ConfigQuestion } from '../../../../../core/models/config-question.interface';
import { Shareholder } from '../../../../../core/models/shareholder.interface';
import { EntityUtils } from '../../../../../core/utils/entity.util';
import { takeWhile, tap } from 'rxjs/operators';

@Component({
	selector: 'bcb-directors-information-content',
	templateUrl: './directors-information-content.component.html',
	styleUrls: ['./directors-information-content.component.scss'],
})
export class DirectorsInformationContentComponent implements OnInit, OnDestroy {
	@Input() data!: {
		entity: Entity;
		questions: Array<ConfigQuestion>;
		selectedLegalPerson?: string;
		shareholders?: Array<Shareholder | Entity>;
		systemUser?: string
	};
	@Output() submit: EventEmitter<Record<string, any>> = new EventEmitter<
		Record<string, any>
	>();
	@Output() formValid: EventEmitter<boolean> = new EventEmitter<boolean>();
	remainingShares: number = 100;
	questionsConfig!: ConfigUtil;
	formGroup!: FormGroup;
	visibleControls!: Set<string>;
	configQuestionType = ConfigQuestionType;
	currentStep: string = 'legal_persons';
	allCountries: Array<Country> = ArrayUtils.sortObject(
		StaticData.countries,
		'name'
	);
	currentShares = 0;
	unsubscribe = false;
	componentClassList: Array<string> = ['col-md-6']

	constructor(private readonly entityUtils: EntityUtils) {}

	ngOnInit(): void {
		if (!this.data) {
			throw new Error(
				'[data] is required for bcb-directors-information-content'
			);
		}
		this.questionsConfig = new ConfigUtil(this.data.questions);
		this.formGroup = this.questionsConfig.formGroup;
		this.visibleControls = this.questionsConfig.visibleControls;

		if (this.data.selectedLegalPerson) {
			const _data =
				this.data.entity.profile?.onboarding?.legal_persons &&
				this.data.entity.profile?.onboarding?.legal_persons[
					this.data.selectedLegalPerson
				];
			if (_data) {
				this.questionsConfig.setDefaultValues(_data);
			}
			this.currentShares =
				this.formGroup?.get(this.entityUtils.questionKeys.shares)
					?.value ?? 0;
		}

		this.formGroup.valueChanges
			.pipe(
				takeWhile(() => !this.unsubscribe),
				tap(() => this.formValid.emit(this.formGroup.valid))
			)
			.subscribe();
	}

	onShareholderChange(): number {
		this.remainingShares =
			this.entityUtils.getRemainingSharesForShareholders(
				this.data.entity,
				this.data.shareholders ?? [],
				'id',
				this.currentShares
			);

		return this.remainingShares;
	}

	public onSubmit(): void {
		this.formGroup.markAllAsTouched();
		if (this.formGroup.valid || (this.data.systemUser && this.formGroup.dirty)) {
			this.submit.emit({
				...this.questionsConfig.getFormValuesMap(),
				entityId: this.data.selectedLegalPerson,
			});
		}
	}

	ngOnDestroy(): void {
		this.unsubscribe = true;
		this.questionsConfig.unsubscribe.next(true);
	}
}
