import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConfigQuestion } from '../../models/config-question.interface';
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ERROR_MESSAGES } from '../../constants/enum.const';

@Component({
	selector: 'bcb-generic-checklist',
	templateUrl: './generic-checklist.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: GenericChecklistComponent,
		},
	],
	styleUrls: ['./generic-checklist.component.scss'],
})
export class GenericChecklistComponent implements ControlValueAccessor {
	@Input() question!: ConfigQuestion;
	@Input() formGroup!: FormGroup;
	@Input() formGroupName!: string;
	@Input() errorMessage: ERROR_MESSAGES | undefined;
	@Output() change: EventEmitter<any> = new EventEmitter<any>();

	value: any;
	isDisabled: boolean = false;

	constructor() {}

	changed: (value: any) => void = () => {};

	touched: () => void = () => {};

	writeValue(obj: any): void {
		this.value = obj;
	}

	onChange(): void {
		const val = this.formGroup?.get(this.question.key)?.value
		this.changed(val);
		this.change.emit(val);
	}

	registerOnChange(fn: any): void {
		this.changed = fn;
	}

	registerOnTouched(fn: any): void {
		this.touched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
}
