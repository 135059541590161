import { NgModule } from '@angular/core';
import { ObjectArrayKeyValuePipe } from './object-array-key-value/object-array-key-value.pipe';
import { MobileNumberPipe } from './mobile-number/mobile-number.pipe';

@NgModule({
	declarations: [ObjectArrayKeyValuePipe, MobileNumberPipe],
	imports: [],
	providers: [ObjectArrayKeyValuePipe, MobileNumberPipe],
	exports: [ObjectArrayKeyValuePipe, MobileNumberPipe],
})
export class PipesModule {}
