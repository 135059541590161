<bcb-generic-label [question]="question"></bcb-generic-label>
<mat-form-field appearance="outline">
	<input
		bcbMobileInput
		matInput
		type="tel"
		placeholder="+{{
			selectedCountry?.call_code ?? defaultCountryCallCode
		}}1234567890"
		[formControl]="control"
		(selectedCountryChange)="selectedCountry = $event"
		(mobileNumberObjectChange)="phoneNumber = $event"
	/>
	<img
		*ngIf="selectedCountry"
		matSuffix
		class="select-image"
		[src]="selectedCountry?.flag_url"
		[title]="selectedCountry?.name"
	/>
	<mat-error *ngIf="control?.invalid">{{ errorMessage }}</mat-error>
</mat-form-field>
