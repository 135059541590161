import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, of } from 'rxjs';
import { BasePageComponent } from '../../../../../../core/components/component-base-page/base-page.component';
import { ConfigQueries } from '../../../../../../core/store/config/config.queries';
import { EntityQueries } from '../../../../../../core/store/entity/entity.queries';
import { HttpService } from '../../../../../../core/services/http/http.service';
import { OnboardingQueries } from '../../../../../../core/store/onboarding/onboarding.queries';
import { StepperQueries } from '../../../../../../core/store/stepper/stepper.queries';
import { ConfigQuestion } from '../../../../../../core/models/config-question.interface';
import { ContactType, EntityContact } from '../../../../../../core/models/entity-contact.model';
import { error } from 'logrocket';

@Component({
  selector: 'bcb-account-details-modal',
  templateUrl: './additional-contact-modal.component.html',
  styleUrls: ['./additional-contact-modal.component.scss']
})
export class AdditionalContactModalComponent extends BasePageComponent {
  private contactType: string = ContactType.ONBOARDING;
  private readonly questionKeys: Record<string, string> = {
    firstName: '873a9594-b43d-4ed9-94d2-1b215ae6786e',
    lastName: '4c186d0b-4fba-43c1-940c-c2f8bd2f7e98',
    email: '3f21730b-5760-4576-b57d-f51ec2e17332',
    mobile: '97c2906b-e922-43dc-87c8-a55a79c8ceee'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public initialData: EntityContact,
    private readonly dialogRef: MatDialogRef<AdditionalContactModalComponent>,
    configQueries: ConfigQueries,
    entityQueries: EntityQueries,
    http: HttpService,
    onboardingQueries: OnboardingQueries,
    stepperQueries: StepperQueries,
    _snackBar: MatSnackBar
  ) {
    super(
      configQueries,
      entityQueries,
      http,
      onboardingQueries,
      stepperQueries,
      _snackBar
    );
  }

  getStepAnswers = (): Record<string, any> => {
    return this.toAnswer(this.initialData);
  };

  submit(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid || this.systemUser$.value) {
      this.dialogRef.close(this.toEntityContact(this.formGroup.value));
    }
  }

  onAnswerChange(question: ConfigQuestion, value: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  private toAnswer(data: EntityContact): Record<string, any> {
    if(!data) return {};
    return Object.keys(data).reduce(
      (acc: Record<string, any>, key: string) => {
        if (this.questionKeys[key]) {
          // @ts-ignore
          acc[this.questionKeys[key]] = data[key];
        }
        return acc;
      },
      {}
    );
  }

  private toEntityContact(data: Record<string, any>): EntityContact {
    let entityContact: EntityContact = this.initialData || {
      type: this.contactType,
      entityId: this.entity.id,
    };
    for (let value in data) {
      const key: string | undefined = Object.keys(this.questionKeys).find(
        (_key) => {
          return this.questionKeys[_key] === value;
        }
      );
      if (key) {
        entityContact = { ...entityContact, [key]: data[value] };
      }
    }
    return entityContact;
  }
}
