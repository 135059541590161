<div class="page">
  <div class="content">
    <div class="row">
      <h1 class="col page__title">Yield product due diligence</h1>
      <mat-divider class="mb-3"></mat-divider>
    </div>

    <div class="row" *ngIf="!formGroup; else form">
      <div class="d-flex flex-column align-items-center">
        <mat-spinner>{{runInit() | async}}</mat-spinner>
      </div>
    </div>

    <div class="spacer"></div>

    <div class="btn-bar" *ngIf="!systemUser$.value && primaryContact">
      <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()"
              [disabled]="processing$.value">{{nextButtonText()}}</button>
      <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()"
              [disabled]="processing$.value">{{previousButtonText()}}</button>
    </div>
  </div>
</div>


<ng-template #form>
  <form [formGroup]="formGroup">
    <div class="row">
      <section>
        <div class="row">
          <h1 class="col page__sub-title">Source of Funds/Wealth </h1>
          <mat-divider class="mb-3"></mat-divider>
        </div>

        <form [formGroup]="formGroup">
          <div class="row">
            <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                                   [currentStep]="currentStep.key"
                                   [entity]="entity"
                                   [formGroup]="formGroup"
                                   [questions]="configQuestions['yield_funds']"
                                   [questionsConfig]="questionsConfig"
                                   [visibleControls]="visibleControls"
            ></bcb-component-wrapper>
          </div>
        </form>
      </section>

      <section>
        <div class="row">
          <h1 class="col page__sub-title">Expected Activity</h1>
          <mat-divider class="mb-3"></mat-divider>
        </div>

        <form [formGroup]="formGroup">
          <div class="row">
            <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                                   [currentStep]="currentStep.key"
                                   [entity]="entity"
                                   [formGroup]="formGroup"
                                   [questions]="configQuestions?.['yield_activity'] ?? []"
                                   [questionsConfig]="questionsConfig"
                                   [visibleControls]="visibleControls"
            ></bcb-component-wrapper>
          </div>
        </form>
      </section>

      <section>
        <div class="row">
          <h1 class="col page__sub-title">Bank account details</h1>
          <mat-divider class="mb-3"></mat-divider>
        </div>

        <form [formGroup]="formGroup">
          <div class="row">
            <bcb-component-wrapper [componentClassList]="componentLayoutClassList"
                                   [currentStep]="currentStep.key"
                                   [entity]="entity"
                                   [formGroup]="formGroup"
                                   [questions]="configQuestions?.['yield_bank'] ?? []"
                                   [questionsConfig]="questionsConfig"
                                   [visibleControls]="visibleControls"
            ></bcb-component-wrapper>
          </div>
        </form>
        <form [formGroup]="formGroup">
          <ng-container *ngIf="showJustification">
            <mat-divider style="margin: 25px 0"></mat-divider>
            <div class="col-12 lbl-stacked">
              <mat-label>Justification</mat-label>
              <mat-form-field appearance="outline">
                            <textarea matInput [formControl]="justificationFormControl"
                                      rows="4"></textarea>
              </mat-form-field>
            </div>
          </ng-container>
        </form>
      </section>
    </div>


    <div class="spacer"></div>

    <div class="btn-bar" *ngIf="!systemUser$.value && primaryContact">
      <button mat-flat-button class="bcb-btn primary" (click)="onNextClick()"
              [disabled]="processing$.value">{{nextButtonText()}}</button>
      <button mat-stroked-button class="bcb-btn primary" (click)="onPreviousClick()"
              [disabled]="processing$.value">{{previousButtonText()}}</button>
    </div>
  </form>
</ng-template>