export class ArrayUtils {
	static sortObject<T = { [key: string]: any }>(
		arr: Array<T>,
		key: string,
		order: 'asc' | 'desc' = 'asc'
	): Array<T> {
		return arr.sort((a: T, b: T) => {
			// suppressing the errors for loosely inferred typed variables
			// @ts-ignore
			if (a[key] < b[key]) {
				return order === 'asc' ? -1 : 1;
			}
			// @ts-ignore
			if (a[key] > b[key]) {
				return order === 'asc' ? 1 : -1;
			}
			return 0;
		});
	}
}
