<mat-toolbar class="color-dark d-flex"
             [class.system-user]="systemUser | async"
             [class.standard-user]="(contactType | async) && (contactType | async) !== 'primary'">
    <div class="container d-flex align-items-center">
        <div class="logo">
            <img src="assets/svg/bcb-group-horizontal-white.svg" height="37.91" width="201.77">
            <span *ngIf="verified" class="user">{{entityName}} Onboarding</span>
            <span *ngIf="systemUser | async" class="user systemUser">System User</span>
        </div>
        <span class="spacer"></span>
        <button *ngIf="verified" mat-icon-button
                [matMenuTriggerFor]="menu"
                [class.bg-inherit]="(systemUser | async) && ((contactType | async) && (contactType | async) !== 'primary')"
                aria-label="Additional user actions menu">
            <mat-icon>menu</mat-icon>
        </button>
    </div>
</mat-toolbar>

<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="completeLater()">
        <mat-icon>save</mat-icon>
        <span>Save and complete later</span>
    </button>
  <button mat-menu-item (click)="help()">
    <mat-icon>help</mat-icon>
    <span>Help</span>
  </button>
  <button *ngIf="(contactType | async) === 'primary' || (systemUser | async)" mat-menu-item [matMenuTriggerFor]="managementMenu">
    <mat-icon>manage_accounts</mat-icon>
    <span>Management</span>
  </button>
  <mat-divider></mat-divider>
  <button mat-menu-item (click)="logout()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>

<mat-menu #managementMenu>
    <button mat-menu-item (click)="navigate('management/additional_contacts')">
        <mat-icon>groups</mat-icon>
        <span>Additional Contacts</span>
    </button>
</mat-menu>
