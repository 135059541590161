    <h3 mat-dialog-title>Legal Person Information</h3>
<mat-dialog-content>
    <bcb-directors-information-content [data]="data" (submit)="onSubmitChanged($event)" (formValid)="canSubmit = $event"></bcb-directors-information-content>
</mat-dialog-content>
<mat-dialog-actions align='end'>
    <button mat-flat-button [mat-dialog-close]='undefined' class='bcb-btn btn-small danger'>Cancel</button>
    <button mat-flat-button color='primary'
            (click)='triggerSubmit()'
            [disabled]='!data.systemUser && !canSubmit'>Save
    </button>
</mat-dialog-actions>
