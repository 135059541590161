<bcb-generic-label [question]="question"></bcb-generic-label>
<mat-form-field appearance='outline'>
            <textarea matInput
                      [formControl]="formControl"
                      [rows]='question.attributes?.rows || 4'
                      [value]="value"
                      (change)="onChange($event)"
                      (input)="onChange($event)"
                      (blur)="touched()"></textarea>
    <mat-error *ngIf="formControl?.invalid">{{errorMessage}}</mat-error>
</mat-form-field>