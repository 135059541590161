import { Component, OnInit } from '@angular/core';
import { ArrayUtils } from '../../../../core/utils/array.util';
import { StaticData } from '../../../../core/constants/static.const';
import { Country } from '../../../../core/models/country.interface';
import { Subscription } from 'rxjs';
import { Entity } from '../../../../core/models/entity.model';
import { OnboardingStateModel } from '../../../../core/store/onboarding/onboarding.state';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { MOBILE_NUMBER_ERRORS, REVIEW_STATUS } from '../../../../core/constants/enum.const';
import { Descriptor } from '../../../../core/models/descriptor.interface';
import { first, switchMap } from 'rxjs/operators';
import { StepModel } from '../../../stepper/models/step.model';

@Component({
    selector: 'bcb-personal-details',
    templateUrl: './personal-details.component.html',
    styleUrls: ['./personal-details.component.scss']
})
export class IndividualPersonalDetailsComponent implements OnInit {
    readonly allCountries = ArrayUtils.sortObject(StaticData.countries, 'name');
    readonly mobileCountries = this.allCountries.filter(
        (country: Country) => country.call_code
    );
    mobileCountryCode?: Country = this.mobileCountries.find(
        (country: Country) => country.code === 'GB'
    );
    genders: Array<Descriptor> = StaticData.genders;
    objMobileInput: any;
    subscriptions: Subscription = new Subscription();
    entity?: Entity;
    onboarding?: OnboardingStateModel;
    processing: boolean = false;

    formGroup: FormGroup;

    constructor(
        private readonly stepperQueries: StepperQueries,
        private readonly entityQueries: EntityQueries,
        private readonly onboardingQueries: OnboardingQueries,
        private readonly http: HttpService
    ) {
        this.formGroup = new FormGroup({
            gender: new FormControl(undefined, Validators.required),
            firstName: new FormControl(undefined, Validators.required),
            lastName: new FormControl(undefined, Validators.required),
            mobile: new FormControl(undefined, [
                Validators.required,
                Validators.pattern(/^[+]?[\d]+$/)
            ]),
            email: new FormControl(undefined, Validators.required),
            dob: new FormControl(undefined, Validators.required)
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.entityQueries.getState().subscribe((res) => {
                this.entity = res;
                this.formGroup.get('gender')?.setValue(res.profile?.gender);
                this.formGroup
                    .get('firstName')
                    ?.setValue(res.first_name);
                this.formGroup
                    .get('lastName')
                    ?.setValue(res.last_name);
                this.formGroup.get('email')?.setValue(res.email);
                this.formGroup.get('dob')?.setValue(res.profile?.dob);
                this.formGroup.get('mobile')?.setValue(res.mobile);
            })
        );

        this.stepperQueries
            .getStep()
            .pipe(first())
            .subscribe((currentStepData: StepModel | undefined) => {
                if (currentStepData?.metadata?.disable ?? false) {
                    this.formGroup?.disable();
                }
            });
    }

    onMobileNumberChange(): void {
        const mobileNumber: string = this.objMobileInput.getNumber();

        this.findPrefix(mobileNumber.split('+')[1]);
        this.formGroup
            .get('mobile')
            ?.setValue(
                mobileNumber.substr(
                    String(this.mobileCountryCode?.call_code)?.length + 1
                )
            );

        if (!this.objMobileInput.isValidNumber()) {
            this.formGroup.get('mobile')?.setErrors(['CUSTOM']);
        } else {
            this.formGroup.get('mobile')?.setErrors([]);
        }
    }

    // Return error string for each field - bound in the HTML
    getErrorMessage(field: string): string {
        switch (field) {
            case 'gender':
            case 'firstName':
            case 'lastName':
            case 'email':
            case 'dob':
                if (this.formGroup.get(field)?.touched) {
                    if (
                        this.formGroup
                            .get(field)
                            ?.hasError('required')
                    ) {
                        return 'This field is required';
                    } else {
                        return 'Please provide a valid input';
                    }
                }
                return '';
            case 'mobile':
                if (this.formGroup.get('mobile')?.touched) {
                    if (
                        this.formGroup
                            .get('mobile')
                            ?.hasError('required')
                    ) {
                        return 'This field is required';
                    } else {
                        const code: number =
                            this.objMobileInput.getValidationError();

                        if (code > 0) {
                            return MOBILE_NUMBER_ERRORS[code].replace(/_/g, ' ');
                        }

                        this.formGroup.get('mobile')?.setErrors(null);
                    }
                }
                return '';
            default:
                return 'Unknown error';
        }
    }

    onNextClick(): void {
        if (this.formGroup.pristine) {
            this.stepperQueries.navigateStep('next');
        } else {
            this.validateAndSave();
        }
    }

    private validateAndSave(): void {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            this.processing = true;
            this.http.individualUpdateProfile({
                ...this.mapFields(),
                entity_id: this.entity?.id
            })
                .pipe(
                    switchMap(() =>
                        this.http.updateOnboardingAnswers(
                            {
                                id: 'entity',
                                title: 'Personal Details',
                                status: REVIEW_STATUS.ENTITY_UPDATED,
                                answers: { ...this.mapFields() }
                            },
                            this.entity?.id ?? ''
                        )
                    )
                )
                .subscribe((res) => {
                    this.processing = true;
                    this.entityQueries.loadState();
                    this.formGroup.markAsPristine();
                }, (e) => this.processing = false);
        }
    }

    private mapFields(): any {
        return {
            gender: this.formGroup.get('gender')?.value,
            first_name: this.formGroup.get('firstName')?.value,
            last_name: this.formGroup.get('lastName')?.value,
            email: this.formGroup.get('email')?.value,
            mobile: this.formGroup.get('mobile')?.value,
            dob: this.formGroup.get('dob')?.value
        };
    }

    private findPrefix(prefix: string) {
        const foundPrefixes: Array<Country> = this.mobileCountries.filter(
            (country: Country) =>
                country.call_code &&
                prefix.startsWith(String(country.call_code))
        );
        if (foundPrefixes?.length > 0) {
            this.mobileCountryCode = foundPrefixes.reduce(
                (first: Country, second: Country) =>
                    String(first.call_code)?.length >
                    String(second.call_code)?.length
                        ? first
                        : second
            );
        }
    }
}
