import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Entity } from '../../../../core/models/entity.model';
import { Country } from '../../../../core/models/country.interface';
import { ArrayUtils } from '../../../../core/utils/array.util';
import { StaticData } from '../../../../core/constants/static.const';
import { USState } from '../../../../core/models/us-state.interface';
import { Subscription } from 'rxjs';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { first, switchMap } from 'rxjs/operators';
import { REVIEW_STATUS } from '../../../../core/constants/enum.const';
import { StepModel } from '../../../stepper/models/step.model';

@Component({
    selector: 'bcb-entity-registered-address',
    templateUrl: './entity-registered-address.component.html',
    styleUrls: ['./entity-registered-address.component.scss']
})
export class IndividualEntityRegisteredAddressComponent
    implements OnInit, OnDestroy {
    formGroup: FormGroup;
    entity?: Entity;
    processing: boolean = false;

    allCountries: Array<Country> = ArrayUtils.sortObject(
        StaticData.countries,
        'name'
    );
    allUSStates: Array<USState> = ArrayUtils.sortObject(
        StaticData.usSates,
        'name'
    );

    subscriptions: Subscription = new Subscription();

    constructor(
        private readonly stepperQueries: StepperQueries,
        private readonly entityQueries: EntityQueries,
        private readonly http: HttpService
    ) {
        this.formGroup = new FormGroup({
            addressLine1: new FormControl(undefined, Validators.required),
            addressLine2: new FormControl(undefined),
            city: new FormControl(undefined, Validators.required),
            postCode: new FormControl(undefined, Validators.required),
            country: new FormControl(undefined, Validators.required)
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.entityQueries.getState().subscribe((data) => {
                this.entity = data;

                this.formGroup
                    .get('addressLine1')
                    ?.setValue(data.address?.street);
                this.formGroup
                    .get('addressLine2')
                    ?.setValue(data.address?.sub_street);
                this.formGroup.get('city')?.setValue(data.address?.city);
                this.formGroup
                    .get('postCode')
                    ?.setValue(data.address?.postcode);
                this.formGroup.get('country')?.setValue(data.address?.country);

                this.onCountrySelect({ value: data.address?.country });
            })
        );

        this.stepperQueries
            .getStep()
            .pipe(first())
            .subscribe((currentStepData: StepModel | undefined) => {
                if (currentStepData?.metadata?.disable ?? false) {
                    this.formGroup?.disable();
                }
            });
    }

    onCountrySelect(event: any): void {
        if (event.value === 'US') {
            this.formGroup.addControl(
                'state',
                new FormControl(
                    this.entity?.address?.state ?? undefined,
                    Validators.required
                )
            );
        } else {
            this.formGroup.removeControl('state');
        }
    }

    getErrorMessage(field: string): string | undefined {
        if (this.formGroup.get(field)?.touched) {
            if (this.formGroup.get(field)?.hasError('required')) {
                return 'This filed is required';
            }
            return 'Please provide a valid input';
        }
        return undefined;
    }

    onNextClick(): void {
        if (this.formGroup.pristine) {
            this.stepperQueries.navigateStep('next');
        } else {
            this.validateAndSave();
        }
    }

    onPreviousClick(): void {
        if (this.formGroup.pristine) {
            this.stepperQueries.navigateStep('prev');
        } else {
            this.validateAndSave();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private validateAndSave(): void {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            this.processing = true;
            this.http
                .updateAddress({
                    ...this.mapFields(),
                    entity_id: this.entity?.id
                })
                .pipe(
                    switchMap(() =>
                        this.http.updateOnboardingAnswers(
                            {
                                id: 'address',
                                title: 'Entity Registered Address',
                                status: REVIEW_STATUS.ENTITY_UPDATED,
                                answers: { ...this.mapFields() }
                            },
                            this.entity?.id ?? ''
                        )
                    )
                )
                .subscribe(
                    (res) => {
                        this.processing = true;
                        this.entityQueries.loadState();
                        this.formGroup.markAsPristine();
                    },
                    (e) => (this.processing = false)
                );
        }
    }

    private mapFields(): any {
        return {
            street: this.formGroup.get('addressLine1')?.value,
            sub_street: this.formGroup.get('addressLine2')?.value,
            city: this.formGroup.get('city')?.value,
            postcode: this.formGroup.get('postCode')?.value,
            country: this.formGroup.get('country')?.value,
            state: this.formGroup.get('state')?.value ?? undefined
        };
    }
}
