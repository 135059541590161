import { StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityStateModel } from './entity.state';
import {
	ClearEntity,
	LoadEntity,
	LoadSubEntities,
	ReloadEntity,
	UpdateEntity,
} from './entity.actions';

@Injectable()
export class EntityQueries {
	constructor(private readonly store: Store) {}

	loadState(): void {
		this.store.dispatch(new LoadEntity());
	}

	reloadState(withNavigation = false): void {
		this.store.dispatch(new ReloadEntity(withNavigation));
	}

	loadSubEntities(): void {
		this.store.dispatch(new LoadSubEntities());
	}

	getState(): Observable<EntityStateModel> {
		return this.store.select(new StateToken<EntityStateModel>('entity'));
	}

	updateState(state: EntityStateModel): void {
		this.store.dispatch(new UpdateEntity(state));
	}

	clearState(): void {
		this.store.dispatch(new ClearEntity());
	}
}
