import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { Observable, Subscription } from "rxjs";
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { Entity } from '../../../../core/models/entity.model';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { first, map, switchMap, takeWhile } from 'rxjs/operators';
import { StepModel } from '../../../stepper/models/step.model';
import {
	ConfigQuestionType,
	REVIEW_STATUS,
} from '../../../../core/constants/enum.const';
import { ConfigUtil } from '../../../../core/utils/config.util';
import { ConfigQuestion } from '../../../../core/models/config-question.interface';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
	selector: 'bcb-scam-claim',
	templateUrl: './scam-claim.component.html',
	styleUrls: ['./scam-claim.component.scss'],
})
export class ScamClaimComponent  extends BasePageComponent {
	componentLayoutClassList: string[] = ['col-12'];
	constructor( configQueries: ConfigQueries,
							 entityQueries: EntityQueries,
							 http: HttpService,
							 onboardingQueries: OnboardingQueries,
							 stepperQueries: StepperQueries,
							 _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onStepComplete(): Observable<any> {
		return this.http
			.uploadScamClaim({
				questions: {
					...this.questionsConfig.getFormValuesMap()
				},
				entity_id: this.entity.id
			});
	}

	onPreviousClick(): void {
		this.stepperQueries.navigateStep('prev');
	}

}