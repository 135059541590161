import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'bcb-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {

    status?: string | number;
    title?: string;
    message?: string;

    constructor(private readonly router: Router) {
        const extras = this.router.getCurrentNavigation()?.extras.state

        this.status = extras?.status;
        this.title = extras?.title;
        this.message = extras?.message;
    }

    ngOnInit(): void {
        if (!this.status) {
            this.status = this.router.url.split('/')[2];
        }

        switch (this.status) {
            case '404':
                this.title = 'Page not found';
                break
            case '401':
                this.title = this.title ?? 'Unauthorised';
                this.message = this.message ?? 'You are not authorised to perform the onboarding onboard with BCB Group. Please contact our support team at csonboarding@bcbgroup.io'
        }
    }

}
