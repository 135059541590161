import { Component, Input } from '@angular/core';
import { ConfigQuestion } from '../../models/config-question.interface';
import { ConfigQuestionType, ERROR_MESSAGES } from '../../constants/enum.const';
import { ConfigUtil } from '../../utils/config.util';
import {
	ControlValueAccessor,
	FormGroup,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
	selector: 'bcb-generic-component-group',
	templateUrl: './generic-component-group.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: GenericComponentGroupComponent,
		},
	],
	styleUrls: ['./generic-component-group.component.scss'],
})
export class GenericComponentGroupComponent implements ControlValueAccessor {
	@Input() question!: ConfigQuestion;
	@Input() questionsConfig!: ConfigUtil;
	@Input() formGroup!: FormGroup;
	@Input() formGroupName!: string;
	@Input() errorMessage: ERROR_MESSAGES | undefined;
	questions: Array<ConfigQuestion> = [];
	configQuestionType = ConfigQuestionType;
	value: any;
	isDisabled: boolean = false;

	constructor() {}

	changed: (value: any) => void = () => {};

	touched: () => void = () => {};

	writeValue(obj: any): void {
		// TODO: fix value that is stored
		this.value = obj;
	}

	onChange(): void {
		this.changed(this.formGroup?.get(this.formGroupName)?.value);
	}

	registerOnChange(fn: any): void {
		this.changed = fn;
	}

	registerOnTouched(fn: any): void {
		this.touched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}

	getConfigQuestions(
		componentsList?: Record<string, ConfigQuestion>
	): Array<ConfigQuestion> {
		if (componentsList) {
			return Object.keys(componentsList).reduce(
				(previousValue: Array<ConfigQuestion>, key: string) => {
					return [...previousValue, componentsList[key]];
				},
				[]
			);
		}
		return [];
	}
}
