import { Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Entity } from '../../../../../core/models/entity.model';
import { ConfigQuestion } from '../../../../../core/models/config-question.interface';
import { Shareholder } from '../../../../../core/models/shareholder.interface';
import { DirectorsInformationContentComponent } from '../directors-information-content/directors-information-content.component';

@Component({
	selector: 'bcb-director-information-modal',
	templateUrl: './director-information-modal.component.html',
	styleUrls: ['./director-information-modal.component.scss'],
})
export class DirectorInformationModalComponent {
	canSubmit: boolean = false;
	@ViewChild(DirectorsInformationContentComponent, { static: true })
	private formContent!: DirectorsInformationContentComponent;
	constructor(
		@Inject(MAT_DIALOG_DATA)
		public data: {
			entity: Entity;
			questions: Array<ConfigQuestion>;
			selectedLegalPerson?: string;
			shareholders?: Array<Shareholder | Entity>;
			systemUser?: string
		},
		private readonly dialogRef: MatDialogRef<DirectorInformationModalComponent>
	) {}

	onSubmitChanged(formValues: Record<string, any>): void {
		if (this.canSubmit || this.data.systemUser) {
			this.dialogRef.close(formValues);
		}
	}

	triggerSubmit(): void {
		this.formContent.onSubmit();
	}
}
