<div class='page'>
    <div class='content'>
        <div class='row'>
            <h1 class='col page__title'>Shareholder Information</h1>
        </div>
        <mat-divider class='mb-3'></mat-divider>
        <ng-container *ngIf='showLanding; else contentBlock'>
            <p>Please list all individual and corporate shareholders owning 25% or more of the company.</p>
            <p><strong>For corporate shareholders you will need to provide certified copies of the following
                documents:</strong></p>

            <ol class='bullet-primary'>
                <li>Certificate of Incorporation</li>
                <li>Articles of Association</li>
                <li>Shareholders Register</li>
                <li>Directors Register</li>
                <li>Certificate of Good Standing and/or Certificate of Incumbency (if applicable)</li>
                <li>Financial Statements (most recent audited accounts or, if audited accounts are not available or not
                    required by applicable local law, most recent statutory accounts or management accounts
                </li>
            </ol>

            <p>Please note, you can either upload the documents as you go, or do them all right at the end.</p>
        </ng-container>
    </div>

    <div class='btn-bar' *ngIf='!showLanding && !isDisabled'>
        <button mat-flat-button class='bcb-btn btn-small primary' (click)="addShareholder()">Add
            Parent Company
        </button>
    </div>
    <form *ngIf='showJustification'>
        <mat-divider style='margin: 25px 0'></mat-divider>
        <div class='col-12 lbl-stacked'>
            <mat-label>Justification</mat-label>
            <mat-form-field appearance='outline'>
                <textarea matInput [formControl]='justificationFormControl' rows='4'></textarea>
            </mat-form-field>
        </div>
    </form>

    <div class='spacer'></div>

    <div class='btn-bar'>
        <button mat-flat-button class='bcb-btn primary' *ngIf="showLanding || !systemUser$.value" (click)='onNextClick()'
                [disabled]='processing'>{{processing ? 'Processing...' : 'Next'}}</button>
        <button mat-stroked-button class='bcb-btn primary' *ngIf="!systemUser$.value" (click)='onPreviousClick()'
                [disabled]='processing'>{{processing ? 'Processing...' : 'Previous'}}</button>
    </div>
</div>

<ng-template #contentBlock>
    <ng-container
        *ngIf='chartData && chartData.children && chartData.children.length > 0; then orgChart else emptyState'></ng-container>
</ng-template>

<ng-template #orgChart>
    <p>Please list all significant shareholders (owning 25% or more of the company). In the last step you will be
        asked to provide certified copies of passports and proof of address for each shareholder.</p>
    <p><strong>Shareholders Information Structure Diagram</strong></p>
    <p *ngIf='supportsZoom && showZoom'>Chart zoom
        <mat-slider
            max='100'
            min='1'
            step='1'
            thumbLabel='true'
            [(ngModel)]='zoomLevel'
            (valueChange)='onZoomChange($event)'>
        </mat-slider>
        {{zoomLevel}}%
    </p>
    <bcb-org-chart [data]='chartData'></bcb-org-chart>
</ng-template>

<ng-template #emptyState>
    <p><strong>*Please list all individual and corporate shareholders owning 25% or more of the company.</strong></p>
    <mat-divider style='margin-top: 25px; margin-bottom: 12px'></mat-divider>
    <p>You have not added any shareholders</p>
    <mat-divider></mat-divider>
</ng-template>

