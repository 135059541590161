import { ModuleWithProviders, NgModule } from '@angular/core';
import { Ng2TelInput } from './ng2-tel-input.directive';

@NgModule({
	declarations: [Ng2TelInput],
	exports: [Ng2TelInput],
})
export class Ng2TelInputModule {
	static forRoot(): ModuleWithProviders<unknown> {
		return {
			ngModule: Ng2TelInputModule,
			providers: [],
		};
	}
}
