import { Action, Selector, State, StateContext } from "@ngxs/store";
import { Injectable } from "@angular/core";
import { ClearEntity, LoadEntity, LoadSubEntities, ReloadEntity, UpdateEntity } from "./entity.actions";
import { HttpService } from "../../services/http/http.service";
import { first, tap } from "rxjs/operators";
import { EntityQueries } from "./entity.queries";
import { OnboardingQueries } from "../onboarding/onboarding.queries";
import { Entity } from "../../models/entity.model";
import { ShareholdersQueries } from "../shareholders/shareholders.queries";
import { DirectorsQueries } from "../directors/directors.queries";
import { Shareholder } from "../../models/shareholder.interface";
import { ENTITY_TYPE } from "../../constants/enum.const";
import { StepperQueries } from "../stepper/stepper.queries";
import { StepperConfigModel } from "../../../modules/stepper/models/stepper-config.model";

@State<EntityStateModel | {}>({
  name: "entity",
  defaults: {}
})
@Injectable()
export class EntityState {
  constructor(
    private readonly http: HttpService,
    private readonly entityQueries: EntityQueries,
    private readonly onboardingQueries: OnboardingQueries,
    private readonly shareholdersQueries: ShareholdersQueries,
    private readonly directorsQueries: DirectorsQueries,
    private readonly stepperQueries: StepperQueries
  ) {
  }

  // -------------------------- Actions --------------------------

  @Action(LoadEntity)
  loadEntity(): void {
    this.http
      .getEntityDetails()
      .pipe(first((data) => !!data),
        tap((entity) => {
          this.onboardingQueries.updateState(entity.profile.onboarding);
          this.entityQueries.updateState(entity);
          this.entityQueries.loadSubEntities();
          this.stepperQueries.generateConfig(entity.profile.onboarding);
        }))
      .subscribe((entity) => {
          if(!this.http.entityContact.value) {
            this.http.getPrimaryContactForEntity(entity.id, entity.email)
              .pipe(
                tap(data => {this.http.setEntityContact(data)})
              )
              .subscribe();
          }
        }
      );

  }

  @Action(ReloadEntity)
  reloadEntity(ctx: StateContext<EntityStateModel>, { payload }: ReloadEntity): void {
    this.http
      .getEntityDetails()
      .pipe(first((data) => !!data))
      .subscribe((entity) => {
        this.onboardingQueries.updateState(entity.profile.onboarding);
        this.entityQueries.updateState(entity);
        this.entityQueries.loadSubEntities();

        this.stepperQueries.generateConfig(
          entity.profile.onboarding,
          payload
        );
      });
  }

  @Action(LoadSubEntities)
  loadSubEntities(ctx: StateContext<EntityStateModel>): void {
    const root: Shareholder = { ...ctx.getState(), complete: true };
    let directors: Array<Entity> = [];
    let shareholders: Array<Shareholder> = [];
    this.http
      .getAllSubEntities(ctx.getState().id)
      .pipe(
        first((data) => !!data),
        tap((data: Array<Entity>) => {
          directors = data.filter(
            (item) => item.type === ENTITY_TYPE.sub_individual
          );
          shareholders = data
            .filter((item) => item.profile?.shareholder)
            .map((item) => {
              return {
                ...item,
                complete:
                  item.profile?.onboarding?.active_step ===
                  "completed" ||
                  item.type === "sub-entity-individual"
              };
            });
        })
      )
      .subscribe((entities) => {
        this.shareholdersQueries.updateShareholder([
          ...shareholders,
          root
        ]);
        this.directorsQueries.loadState(directors);
      });
  }

  @Action(UpdateEntity)
  UpdateEntity(
    { patchState }: StateContext<EntityStateModel>,
    { payload }: UpdateEntity
  ): void {
    patchState(payload);
    this.shareholdersQueries.addUpdateShareholder({
      ...payload,
      complete: true
    });
  }

  @Action(ClearEntity)
  clearEntity({ setState }: StateContext<EntityStateModel | {}>): void {
    setState({});
  }

  // -------------------------- Selectors --------------------------
  @Selector()
  getState(state: EntityStateModel): EntityStateModel {
    return state;
  }
}

// TODO: Implement correct onboarding model as per services
export interface EntityStateModel extends Entity {
}
