import { ModuleWithProviders, NgModule } from '@angular/core';
import { MobileInputDirective } from './mobile-input.directive';
import { PipesModule } from '../../pipes/pipes.module';

@NgModule({
	declarations: [MobileInputDirective],
	imports: [PipesModule],
	exports: [MobileInputDirective],
})
export class MobileInputDirectiveModule {
	static forRoot(): ModuleWithProviders<unknown> {
		return {
			ngModule: MobileInputDirectiveModule,
			providers: [],
		};
	}
}
