import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { HttpService } from '../../../../core/services/http/http.service';
import { Subject } from 'rxjs';
import { APP_ROUTES } from '../../../../core/constants/enum.const';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'bcb-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit, OnDestroy {
  entity_id: string = '';
  token: string = '';
  systemUser?: string;

  private readonly unsubscribe: Subject<void> = new Subject<void>();

  constructor(
    private readonly http: HttpService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly entityQueries: EntityQueries
  ) {
  }

  ngOnInit(): void {
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe),
        tap((params: Params) => {
          this.entity_id = params.entity_id;
        }),
        tap(
          () =>
            (this.systemUser =
              this.route.snapshot.queryParamMap.get(
                'systemUser'
              ) ?? undefined)
        )
      )
      .subscribe(() => {
        if (this.http.getToken()) {
          this.http.getTokenPayload();
          this.entityQueries.loadState();
        }
        if (this.entity_id) {
          this.router.navigate(
            [`/${APP_ROUTES.login}/${this.entity_id}/`],
            {
              queryParams: { systemUser: this.systemUser },
              skipLocationChange: true
            }
          );
        } else {
          this.router.navigate([`/${APP_ROUTES.home}`], {
            skipLocationChange: true
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
  }
}
