<h2 matDialogTitle *ngIf="title">{{title}}</h2>
<mat-dialog-content>
    <h3>{{bodyTitle}}</h3>
    <ng-container *ngFor="let contentRow of bodyContent">
        <p *ngIf="!contentRow.type || contentRow.type === 'paragraph'">{{contentRow.content}}</p>
        <p><strong *ngIf="contentRow.type === 'bold'">{{contentRow.content}}</strong></p>
        <h3 *ngIf="contentRow.type === 'heading'">{{contentRow.content}}</h3>
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button [mat-dialog-close]="false" class="bcb-btn btn-small danger">{{rejectButton}}</button>
    <button mat-flat-button color="primary" [mat-dialog-close]="true">{{confirmButton}}
    </button>
</mat-dialog-actions>
