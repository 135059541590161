<ng-container *ngIf="loading | async; else requestVerification">
  <div class="d-flex align-items-center justify-content-center flex-column">
    <img class="w-50 mb-3" src="../../../../../assets/svg/bcb-group-stacked-blue.svg" alt="Bcb group logo">
    <div class="loading">
      <mat-spinner></mat-spinner>
      <h2>Loading...</h2>
    </div>
  </div>
</ng-container>

<ng-template #requestVerification>
  <ng-container *ngIf="(loginStage | async) !== 'login'; else otpLogin">
    <div class="page">
      <div class="content">
        <div class="row">
          <h1 class="col page__title">Welcome to BCB Group Onboarding</h1>
          <mat-divider class="mb-3"></mat-divider>
        </div>
        <div class="row">
          <div class="row">
            <ng-container *ngIf="(loginStage | async) === 'init'; else stepperWrapper">
                <h3>Please enter your registered email address to login</h3>
              <div class="col-6">
                <ng-container *ngTemplateOutlet="form"></ng-container>
              </div>
              <div class="col-2">
                <button mat-flat-button class="mt-4 bcb-btn primary btn-thin" (click)="onEmailSubmit()">Submit</button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #otpLogin>
  <bcb-otp [token]="token"></bcb-otp>
</ng-template>

<ng-template #form>
  <form [formGroup]="formGroup">
    <div class="row">
      <mat-label>{{ (requestedVerification | async) | titlecase }}</mat-label>
      <div class="col lbl-stacked">
        <mat-form-field appearance="outline">
          <input matInput
                 formControlName="destination"
                 (keydown.enter)="onEmailSubmit()">
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="loginStage.value.includes('otp')">
      <mat-label>{{ (requestedVerification | async) | titlecase }} OTP:</mat-label>
      <div class="col d-flex justify-content-between">
        <ng-otp-input (onInputChange)="onOtpChange($event)"
                      [config]="{ length: otpLength, allowNumbersOnly: true, disableAutoFocus: false }"></ng-otp-input>
        <span *ngIf="!(sendOtp | async)" class="d-flex flex-column align-items-end">
          <button mat-stroked-button class="bcb-btn primary btn-thin" (click)="requestOtp()" [disabled]="(otpTimeout | async) > 0 || resendCount > maxResend">Resend OTP</button>
          <small *ngIf="resendCount <= maxResend">{{( otpTimeout | async ) | date:'mm:ss'}}</small>
          <small *ngIf="resendCount > maxResend" class="color-danger">Maximum resend attempts reached</small>
        </span>
        <button mat-flat-button *ngIf="(sendOtp | async)" class="bcb-btn primary btn-thin" (click)="validateOtp()">Submit</button>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #stepperWrapper>
  <h3>Thank you for your interest in BCB group</h3>
  <h4>An OTP has been sent to you to validate your contact information</h4>
  <mat-stepper *ngIf="(entityContact | async)?.type === 'primary'; else form"
               [selectedIndex]="(entityContact | async)?.emailVerified ? 1 : 0"
               linear>
    <mat-step editable="false" [completed]="(entityContact | async)?.emailVerified">
      <ng-template matStepLabel>Email</ng-template>
      <div class="col-6">
        <ng-container *ngTemplateOutlet="form"></ng-container>
      </div>
    </mat-step>
    <mat-step editable="false" [completed]="(entityContact | async)?.mobileVerified">
      <ng-template matStepLabel>Mobile</ng-template>
      <div class="col-6">
        <ng-container *ngTemplateOutlet="form"></ng-container>
      </div>
    </mat-step>
  </mat-stepper>
</ng-template>