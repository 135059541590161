<!-- base node -->
<li>
    <div class="node-container" [class.secondary-node]="!node?.type?.includes('corporate')" *ngIf="!isRoot; else rootNode">
        <div class="node">
            <div class="section">
                <div class="icon">
                    <mat-icon
                            class="icon-sm">{{node?.type?.includes('corporate') ? 'business' : 'person_outline'}}</mat-icon>
                </div>
                <div class="details">
                    <div class="percentage">{{node?.profile?.shares + '%'}}</div>
                    <div class="name"
                         [title]="node?.entity_name || node?.first + ' ' + node?.last_name">{{node?.entity_name || node?.first_name + ' ' + node?.last_name}}</div>
                </div>
                <div class="spacer"></div>
            </div>
            <div class="section justify-between align-end">
                <div class="icon">
                    <!-- error icon -->
                    <img *ngIf="!getNodeStatus(node)" class="icon-status icon-error"
                         matTooltip="Please provide the required information for these corporate shareholders."
                         matTooltipClass="tooltip-danger" matTooltipPosition="above"
                         src="assets/svg/error.svg" alt="Incomplete shareholder">
                    <!-- complete icon -->
                    <img *ngIf="getNodeStatus(node)" class="icon-status icon-complete"
                         src="assets/svg/success.svg" alt="Shareholder complete">
                </div>
                <div class="spacer"></div>
                <div class="buttons" *ngIf='!node.disabled'>
                    <ng-container *ngIf="node?.type?.includes('corporate')">
                        <button mat-icon-button (click)="onNodeEdit(node)">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-icon-button (click)="onNodeDelete(node)" *ngIf="node.children?.length < 1">
                            <mat-icon>delete_outline</mat-icon>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <!-- children nodes -->
    <ng-container *ngIf="node.children?.length > 0">
        <ul>
            <li *ngFor="let child of node.children" orgChartNode [node]="child"></li>
        </ul>
    </ng-container>
</li>


<ng-template #rootNode>
    <div class="node-container root">
        <div class="node">
            <div class="section">
                <div class="icon">
                    <mat-icon
                            class="icon-sm">{{node?.type.includes('corporate') ? 'business' : 'person_outline'}}</mat-icon>
                </div>
                <div class="details">
                    <div class="name"
                         [title]="node?.entity_name || node?.first + ' ' + node?.last_name">{{node?.entity_name || node?.first_name + ' ' + node?.last_name}}</div>
                </div>
                <div class="extra">
                    <div>(onboarding entity)</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
