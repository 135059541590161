export enum APP_ROUTES {
	home = 'home',
	login = 'login',
	completeLater = 'complete_later',
	otp = 'otp',
	error = 'error',
	onboarding = 'onboarding',
	completed = 'completed',
	management = 'management',
}

export enum MANAGEMENT_ROUTES {
	additional_contacts = 'additional_contacts',
}

export enum CORPORATE_ROUTES {
	base = 'corporate',
	welcome = 'welcome',
	profile = 'profile',
	entity = 'entity',
	address = 'address',
	contacts = 'contacts',
	directors = 'directors',
	shareholders = 'shareholders',
	products = 'products',
	yield = 'yield',
	source_of_funds = 'source_of_funds',
	due_diligence_form = 'due_diligence_form',
	scamClaim = 'scam_claim',
	documents = 'documents',
	tax_forms = 'tax_forms',
	terms = 'terms',

	// --- CHILD ROUTES ---
	// DUE DILIGENCE
	regulatoryInformation = 'regulatoryInformation',
	antiMoneyLaunderingPoliciesAndProcedures = 'antiMoneyLaunderingPoliciesAndProcedures',
	antiMoneyLaunderingLite = 'antiMoneyLaunderingPoliciesAndProceduresLite',
	boardAndSeniorManagement = 'boardAndSeniorManagement',
	antiMoneyLaunderingOfficer = 'antiMoneyLaunderingOfficer',
	ComplianceSystemsAndProcedures = 'ComplianceSystemsAndProcedures',
	businessModelAndCustomerBase = 'businessModelAndCustomerBase',
	kycProcedures = 'kycProcedures',
	pepSanctionsChecks = 'pepSanctionsChecks',
	transactionMonitoring = 'transactionMonitoring',
	transactionMonitoringFiat = 'transactionMonitoringFiat',
	transactionMonitoringCrypto = 'transactionMonitoringCrypto',
	cryptoExchanges = 'cryptoExchanges',
	blockchainCrypto = 'blockchainCrypto',
	lastly = 'lastly',
}

export enum INDIVIDUAL_ROUTES {
	base = 'individual',
	welcome = 'welcome',
	profile = 'profile',
	address = 'address',
	sourceOfFunds = 'source_of_funds',
	verificationDocuments = 'verification_documents',
	terms = 'terms',
}

// Used to translate error response from mobil input component
export enum MOBILE_NUMBER_ERRORS {
	IS_POSSIBLE = 0,
	INVALID_COUNTRY_CODE = 1,
	TOO_SHORT = 2,
	TOO_LONG = 3,
	IS_POSSIBLE_LOCAL_ONLY = 4,
	INVALID_LENGTH = 5,
}

// TODO: Remove string values with ENTITY_TYPE reference
export enum ENTITY_TYPE {
	corporate = 'corporate',
	individual = 'individual',
	sub_corporate = 'sub-entity-corporate',
	sub_individual = 'sub-entity-individual',
}

export enum REVIEW_STATUS {
	// ---------- SECTION STATUSES ----------
	APPROVED = 'APPROVED',
	REJECTED = 'REJECTED',
	SKIPPED = 'SKIPPED',
	ENTITY_UPDATED = 'ENTITY_UPDATED',

	// ---------- FLOW STATUSES ----------
	PENDING = 'PENDING',
	REVIEW_STARTED = 'REVIEW_STARTED',
	DOCUMENT_COMPLETE = 'DOCUMENT_COMPLETE',
	COMPLETE = 'COMPLETE',
	COMPLETE_WITH_ERRORS = 'COMPLETE_WITH_ERRORS',
}

export enum ConfigQuestionType {
	TEXT = 'text',
	LABEL = 'label',
	INPUT = 'input',
	MOBILE = 'mobile',
	SELECT = 'select',
	COUNTRY_SELECT = 'countrySelect',
	CHECKLIST = 'checklist',
	CHECKBOX = 'checkbox',
	RADIO = 'radio',
	DATE = 'date',
	NUMBER = 'number',
	FILE = 'file',
	CURRENCY = 'currency',
	COMPONENT_GROUP = 'componentGroup',
	PERCENTAGE = 'percentage',
	ENTITY_PERCENTAGE = 'entityPercentage',
}

export enum ERROR_MESSAGES {
	REQUIRED = 'This filed is required',
	REQUIRED_TRUE = 'Please ensure that this is selected',
	EMAIL = 'Please provide a valid email address',
	MIN_LENGTH = 'Your answer is too short, please provide further details',
	MAX_LENGTH = 'Your answer is too long',
	MIN = 'The value provided is smaller the allowed minimum',
	MAX = 'The value provide is larger than the allowed maximum',
	UPLOAD_FAILED = 'The file has unfortunately not been uploaded, please check the following and try again : Max of 50 MB; PDF, Doc or Image file type',
	DELETE_FAILED = 'There was an error when deleting the file, please try again',
	PATTERN = 'The value provided is invalid',
	MATCHES_COMPONENT_VALUE = 'The value entered does not match',
	DEFAULT = 'Please provide a valid input',
}

export enum CONTACT_TYPES {
	FINANCE = 'finance',
	COMPLIANCE = 'compliance',
	GENERAL = 'general',
	LEGAL = 'legal',
	TECHNOLOGY = 'technology',
}
