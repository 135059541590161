import { Component } from '@angular/core';
import { StepperQueries } from '../../../../core/store/stepper/stepper.queries';
import { HttpService } from '../../../../core/services/http/http.service';
import { EntityQueries } from '../../../../core/store/entity/entity.queries';
import { OnboardingQueries } from '../../../../core/store/onboarding/onboarding.queries';
import { ConfigQueries } from '../../../../core/store/config/config.queries';
import { BasePageComponent } from "../../../../core/components/component-base-page/base-page.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { ConfigQuestion } from "../../../../core/models/config-question.interface";
import { REVIEW_STATUS } from "../../../../core/constants/enum.const";

@Component({
	selector: 'bcb-yield',
	templateUrl: './yield.component.html',
	styleUrls: ['./yield.component.scss'],
})
export class YieldComponent extends BasePageComponent {
	componentLayoutClassList: string[] = ['col-12'];
	customSections: Array<string> = ['yield_funds', 'yield_activity', 'yield_bank'];

	constructor( configQueries: ConfigQueries,
		entityQueries: EntityQueries,
		http: HttpService,
		onboardingQueries: OnboardingQueries,
		stepperQueries: StepperQueries,
		_snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	onStepComplete(): Observable<any> {
		return this.http.updateYieldProduct(
			this.questionsConfig.getFormValuesMap(),
			this.entity.id
		);
	}

	formatOnboardingAnswers(): { justification: string; [p: string]: any } {
		return {
			sections: [
				{
					id: "sourceOfFunds",
					title: "Source of Funds/Wealth",
					status: REVIEW_STATUS.ENTITY_UPDATED,
					answers: {
						...this.mapFields(
							this.configQuestions['yield_funds']
						)
					}
				},
				{
					id: "expectedActivity",
					title: "Expected Activity",
					status: REVIEW_STATUS.ENTITY_UPDATED,
					answers: {
						...this.mapFields(
							this.configQuestions['yield_activity']
						)
					}
				},
				{
					id: "bankAccountDetails",
					title: "Bank Account Details",
					status: REVIEW_STATUS.ENTITY_UPDATED,
					answers: {
						...this.mapFields(
							this.configQuestions['yield_bank']
						)
					}
				}
			],
			justification: this.justificationFormControl.value
		}
	}

	private mapFields(questions: Array<ConfigQuestion>): Record<string, any> {
		const answers = this.questionsConfig.getFormValuesMap();
		return questions.reduce((previousValue, currentValue) => {
			return {
				...previousValue,
				[currentValue.key]: answers[currentValue.key],
			};
		}, {});
	}

}
