<div class="page">
    <div class="content">
        <div class="row">
            <h1 class="col page__title">Application Submitted</h1>
            <mat-divider class="mb-3"></mat-divider>
        </div>
    </div>

    <div class="d-flex align-items-center mb-4">
        <img class="me-4" src="../../../../../assets/svg/success.svg" alt="" width="100">
        <p>Thank you for applying with BCB Group..</p>
    </div>

    <p>We've received your application, our team will start the review process and be in contact within due course.</p>
    <p>If you require any further assistance, please contact us via email at
        <a href="mailto:csonboarding@bcbgroup.io">csonboarding@bcbgroup.io</a></p>

</div>
