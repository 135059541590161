import { Component } from "@angular/core";
import {
	BasePageComponent
} from "../../../../core/components/component-base-page/base-page.component";
import { APP_ROUTES } from "../../../../core/constants/enum.const";
import { EntityQueries } from "../../../../core/store/entity/entity.queries";
import { ConfigQueries } from "../../../../core/store/config/config.queries";
import { HttpService } from "../../../../core/services/http/http.service";
import { OnboardingQueries } from "../../../../core/store/onboarding/onboarding.queries";
import { StepperQueries } from "../../../../core/store/stepper/stepper.queries";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";

@Component({
	selector: 'bcb-personal-details',
	templateUrl: './personal-details.component.html',
	styleUrls: ['./personal-details.component.scss']
})
export class PersonalDetailsComponent extends BasePageComponent {
	constructor( configQueries: ConfigQueries,
						  entityQueries: EntityQueries,
						  http: HttpService,
						  onboardingQueries: OnboardingQueries,
						  stepperQueries: StepperQueries,
						  _snackBar: MatSnackBar) {
		super(
			configQueries,
			entityQueries,
			http,
			onboardingQueries,
			stepperQueries,
			_snackBar
		);
	}

	initialStepAnswers(): Record<string, any> {
		return this.entityUtils.mapEntityToQuestions(this.entity);
	}

	private constructPayload(questionsKeys: Record<string, string>): EntityPayload {
		return {
			entity_id: this.entity.id,
			entity_name: this.formGroup.get(questionsKeys["entity_name"])
				?.value,
			authorisation: this.formGroup.get(questionsKeys["authorisation"])
				?.value,
			position: this.formGroup.get(questionsKeys["position"])?.value,
			email: this.formGroup.get(questionsKeys["email"])?.value,
			invoice_email: this.formGroup.get(questionsKeys["invoice_email"])
				?.value,
			first_name: this.formGroup.get(questionsKeys["first_name"])?.value,
			last_name: this.formGroup.get(questionsKeys["last_name"])?.value,
			mobile: this.formGroup.get(questionsKeys["mobile"])?.value,
			dob: this.formGroup.get(questionsKeys["dob"])?.value,
			nationality: this.formGroup.get(questionsKeys["nationality"])
				?.value
		};
	}

	onStepComplete(): Observable<any> {
		return this.http
			.updateCorporateProfile({
				entity: this.constructPayload(
					this.entityUtils.questionKeys
				),
				raw: this.questionsConfig.getFormValuesMap()
			})
	}

	afterStepSaveComplete(): void {
		if (
			Object.values(this.formGroup.value)?.some(
				(entry) => entry === "not authorized"
			)
		) {
			this.http.logout().then(() =>
				window.location.href = `/${APP_ROUTES.error}/401`
			);
		}
	}

}

interface EntityPayload {
	entity_id: string;
	entity_name?: string;
	authorisation?: string;
	position: string;
	email: string;
	invoice_email: string;
	first_name: string;
	last_name: string;
	mobile: string;
	dob: any;
	nationality: string;
}